import styled from 'styled-components';

export const StyledWrapper = styled.div`
  width: 100%;

  .SingleDatePicker {
    width: 100%;
  }

  .DateRangePicker {
    width: 100%;
  }

  .SingleDatePickerInput {
    width: 100%;
  }

  .DateRangePickerInput {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: ${props => props.height};
    width: 100%;
    /* min-width: 180px; */
    border-radius: 4px;
    border: 1px solid #cccccc;
  }

  .DateInput {
    width: 40%;
    /* margin-left: 10px; */
  }

  .DateInput_input {
    color: ${props => props.theme.colors.black55};
    font-size: ${props => props.theme.fontSize._10px}rem;
    padding: unset;
    text-align: center;
  }

  .DateInput_input__focused {
    font-size: ${props => props.theme.fontSize._10px}rem;
    padding: unset;
    text-align: center;
    border-bottom: 2px solid #3D7981;
  }

  .DateRangePickerInput_arrow_svg {
    width: 15px;
  }

  .SingleDatePickerInput__withBorder {
    border: none;
  }

  .CalendarDay__hovered_span {
    background: rgba(128, 194, 255, 0.5);
    border-color: rgba(62, 162, 255, 0.5);
    color: white;
  }

  .CalendarDay__selected_span {
    background: rgba(62, 162, 255, 0.5);
    border-color: rgba(62, 162, 255, 0.5);
    color: white;
  }

  .CalendarDay__selected {
    background: ##3D7981;
    border-color: ##3D7981;
    color: white;
  }

  .DateRangePickerInput_calendarIcon {
    padding: unset;
    margin: 0 5px 4px 2px;
  }

  .DateRangePickerInput_calendarIcon_svg {
    width: 13px;
    height: 13px;
  }
`;
