import Check from 'assets/icons/check-background-green.svg';
import Close from 'assets/icons/close-background-red.svg';
import redirectBlank from 'assets/icons/redirect-blank.svg';
import Modal from 'components/Modals/Modal';
import Toast from 'components/Toast';
import React, { useState, useRef, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import { mask } from 'remask';
import { getContentUrl } from 'services/api';
import { postLogo, postBanner, getVisualIdentity, postVisualIdentity, existCompany } from 'services/contact-service';
import smartSalesSessionStorage, { storageKeys } from "storage/smart-sales-session-storage";
import Cookies from 'js-cookie';
import theme from '../../styles/theme';
import Input from './Inputs/index';
import Checkbox from './Checkbox/index';
import {
  StyledMainContainer,
  StyledTitle,
  StyledRowContainer,
  StyledRowContainerFlex,
  StyledSubmitButton,
  StyledChanges,
  StyledFlex,
  StyledLogoImage,
  StyledBannerImage,
  StyledLabel,
  StyledFlexTitle,
  StyledLog
} from './styles';

function Settings() {
  const history = useHistory();
  const [storePortal, setStorePortal] = useState('');
  const [url, setUrl] = useState('');
  const [productsQuant, setProductsQuant] = useState('0');
  const [zipCode, setZipCode] = useState('');
  const [freight, setFreight] = useState('0');
  const [additional, setAdditional] = useState('0');
  const [mainColor, setMainColor] = useState('#FFF');
  const [secondaryColor, setSecondaryColor] = useState('#000');
  const [bagColor, setBagColor] = useState('#000000');
  const [buttonColor, setButtonColor] = useState('#0a568a');
  const [secondaryButtonColor, setSecondaryButtonColor] = useState('#4A588B');
  const [range, setRange] = useState('0');
  const [logo, setLogo] = useState({ name: '', data: '' });
  const [banner, setBanner] = useState({ name: '', data: '' });
  const [toastInfo, setToastInfo] = useState();
  const [whatsApp, setwhatsApp] = useState('');
  const [footerMessage, setFooterMessage] = useState('');
  const { portalUrl } = smartSalesSessionStorage.getItem(storageKeys.STORE);
  const store = smartSalesSessionStorage.getItem(storageKeys.STORE);
  const sellerId = smartSalesSessionStorage.getItem(storageKeys.SELLER);
  const [changes, setChanges] = useState([]);
  const [modalImage, setModalImage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [imageType, setImageType] = useState('logo');
  const [loading, setLoading] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [loadingBanner, setLoadingBanner] = useState(false);
  const [urlStatus, setUrlStatus] = useState('available');
  const [qrCode, setQrCode] = useState("");
  const [qrCodeVitrine, setQrCodeVitrine] = useState("");
  const [displaysProductWithoutImage, setDisplaysProductWithoutImage] = useState(false);

  const cookieHash = Cookies.get('token');

  const uploadLogo = async () => {
    setLoadingLogo(true);
    try {
      const data = new FormData();
      data.append('file', logo);
      await postLogo(data);
      setLoadingLogo(false);

    } catch (error) {
      handleError(error);
      setLoadingLogo(false);
    }
  }

  const uploadBanner = async () => {
    setLoadingBanner(true);
    try {
      const data = new FormData();
      data.append('file', banner);
      await postBanner(data);
      setLoadingBanner(false);

    } catch (error) {
      handleError(error);
      setLoadingBanner(false);
    }
  }

  useEffect(() => {
    if (changes.findIndex((elem) => elem === 'logo') !== -1) {
      uploadLogo();
    }
  }, [logo]);

  useEffect(() => {
    if (changes.findIndex((elem) => elem === 'banner') !== -1) {
      uploadBanner();
    }
  }, [banner]);

  const getZipCode = () => {
    if (zipCode === '') {
      return null;
    }
    return zipCode
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const body = {
        storePortal: url,
        productsQuant: parseInt(productsQuant, 10),
        zipCode: getZipCode(),
        freight: parseFloat(freight),
        additional: parseFloat(additional),
        mainColor,
        secondaryColor,
        range: parseInt(range, 10),
        whatsApp,
        footerMessage,
        bagColor,
        buttonColor,
        secondaryButtonColor,
        displaysProductWithoutImage
      }

      const response = await postVisualIdentity(body);
      if (changes.findIndex((elem) => elem === 'storePortal') !== -1) {
        smartSalesSessionStorage.setItem(storageKeys.STORE, { ...store, portalUrl: url });
        window.location.reload(false);
      }

      handleSuccess(response);
    } catch (error) {
      handleError(error);
    }
  }

  const handleSuccess = (response) => {
    setToastInfo({ isError: false, message: response?.data.message });
    setLoading(false);
    setChanges([]);
  }

  const handleError = (response) => {
    setToastInfo({ isError: true, message: response?.message });
    setLoading(false);
  }

  const savedValue = useRef({
    storePortal,
    productsQuant,
    zipCode,
    freight,
    additional,
    mainColor: mainColor?.toLowerCase(),
    secondaryColor: secondaryColor?.toLowerCase(),
    bagColor: bagColor?.toLowerCase(),
    buttonColor: buttonColor?.toLowerCase(),
    logo,
    banner,
    range,
    displaysProductWithoutImage
  });

  const setStates = (data) => {
    setProductsQuant(data.productsQuant);
    setZipCode(data.zipCode);
    setFreight(data.freight);
    setAdditional(data.additional);
    setMainColor(data.mainColor);
    setSecondaryColor(data.secondaryColor);
    setRange(data.range);
    setwhatsApp(data.whatsApp);
    setFooterMessage(data.footerMessage);
    setBagColor(data.bagColor);
    setButtonColor(data.buttonColor);
    setSecondaryButtonColor(data.secondaryButtonColor);
    setDisplaysProductWithoutImage(data.displaysProductWithoutImage);
  }

  const getData = async () => {
    const logoResp = await getContentUrl(portalUrl, 'logo');
    const bannerResp = await getContentUrl(portalUrl, 'banner');

    setLogo({
      name: logoResp,
      data: logoResp
    });

    setBanner({
      name: bannerResp,
      data: bannerResp
    });

    const visualIdentity = await getVisualIdentity(portalUrl);
    setStates(visualIdentity.data);
  }

  const getQrCode = () => {
    const url = window.location.origin.concat(`/customers?token=${cookieHash}`);
    setQrCode(`http://api.qrserver.com/v1/create-qr-code/?data=${url}`);

    const urlVitrine = window.location.origin.concat(`/${portalUrl}/vitrine?sellerId=${sellerId?.id}`);
    setQrCodeVitrine(`http://api.qrserver.com/v1/create-qr-code/?data=${urlVitrine}`);
  }

  useEffect(() => {
    setStorePortal(portalUrl);
    getData();
    getQrCode()
  }, []);

  // It's necessary because val can be a string or event 
  const handleColor = (val, setState) => {
    if (val.target) {
      setState(val.target.value)
    } else {
      setState(val);
    }
  };

  const handleChanges = (value, field, setValue) => {
    setValue();

    let currentValue = value.name ? value.name : value;

    if (value.target) {
      currentValue = value.target.value.toLowerCase();
    }

    const index = changes.findIndex((elem) => elem === field);

    if (savedValue.current[field] !== currentValue && index === -1) {
      setChanges([...changes, field])
    } else if (savedValue.current[field] == currentValue && index !== -1) {
      const newArray = changes;
      newArray.splice(index, 1)
      setChanges([...newArray]);
    }
  }

  const handleViewImage = (image, type) => {
    setModalImage(image)
    setOpenModal(true);
    setImageType(type);
  }

  useEffect(() => {
    if (storePortal !== null) {
      const timeOut = setTimeout(() => setUrl(storePortal), 1000);
      return () => clearTimeout(timeOut);
    }
    return true;
  }, [storePortal]);

  const existsCompany = async (newUrl) => {
    // make the request if there has been a change
    if (changes.findIndex((elem) => elem === 'storePortal') !== -1) {
      try {
        const response = await existCompany(newUrl);
        if (response.status === 200 && portalUrl !== newUrl) {
          setUrlStatus('unavailable');
        } else {
          setUrlStatus('available');
        }
      } catch (error) {
        if (error.response.status === 404) {
          setUrlStatus('available');
        }
      }
    }
  }

  const handleUrlIcon = () => {
    if (urlStatus === 'available' && storePortal.length > 3) {
      return (
        <img src={Check} alt="icone" />
      );
    } if (urlStatus === 'loading') {
      return (
        <Loader type="Oval" color={theme.colors.lightGreen} height={13} width={13} timeout={0} />
      );
    }
    return <img src={Close} alt="icone" />;
  }

  useEffect(() => {
    existsCompany(url);
  }, [url]);

  const handleStorePortal = (e) => {
    setStorePortal(e.target.value);
    setUrlStatus('loading');
  }

  return (
    <>
      <StyledMainContainer onSubmit={(e) => handleSubmit(e)}>
        <>
          <StyledFlexTitle>
            <StyledTitle>
              Configurações de identidade visual
            </StyledTitle>
            <StyledLog onClick={() => history.push('/configuracoes/logs')}>
              <p>Visualizar logs</p>
              <img src={redirectBlank} alt="ícone de redirecionamento" />
            </StyledLog>
          </StyledFlexTitle>
          <StyledRowContainer>
            <Input
              type="text" maxLength='50'
              id="url-vitrine"
              label="URL da vitrine"
              secondaryLabel={`${window.location.origin}/`}
              value={storePortal}
              onChange={(e) => handleChanges(e.target.value, 'storePortal', () => handleStorePortal(e))}
              icon={handleUrlIcon()}
            />
            <Input
              id="main-color"
              maxLength='7'
              label="Cor principal da marca"
              value={mainColor}
              onChange={(value) => handleChanges(value, 'mainColor', () => handleColor(value, setMainColor))}
              isColorPicker
            />
            <Input
              id="secondary-color"
              maxLength='7'
              label="Cor secundária da marca"
              value={secondaryColor}
              onChange={(value) => handleChanges(value, 'secondaryColor', () => handleColor(value, setSecondaryColor))}
              isColorPicker
            />
          </StyledRowContainer>
        </>
        <StyledRowContainer>
          <Input
            id="main-color"
            maxLength='7'
            label="Cor da sacola"
            value={bagColor}
            onChange={(value) => handleChanges(value, 'bagColor', () => handleColor(value, setBagColor))}
            isColorPicker
          />
          <Input
            id="button-Color"
            maxLength='7'
            label="Cor botões de ações"
            value={buttonColor}
            onChange={(value) => handleChanges(value, 'buttonColor', () => handleColor(value, setButtonColor))}
            isColorPicker
          />
          <Input
            id="secondaryButton-Color"
            maxLength='7'
            label="Cor botões secundários"
            value={secondaryButtonColor}
            onChange={(value) => handleChanges(value, 'secondaryButtonColor', () => handleColor(value, setSecondaryButtonColor))}
            isColorPicker
          />
        </StyledRowContainer>
        <StyledRowContainer>
          <Input
            id="footer-message"
            maxLength='255'
            label="Mensagem do Rodapé"
            type="text"
            value={footerMessage}
            onChange={(e) => handleChanges(e.target.value, 'footerMessage', () => setFooterMessage(e.target.value))}
          />
          <Input
            id="logo"
            label="Logotipo Padrão da Marca"
            value={logo.name}
            onChange={(value) => handleChanges(value, 'logo', () => setLogo(value))}
            message="Tamanho recomendado: 400x400px. Até 3MB."
            previewClick={() => handleViewImage(logo.data, 'logo')}
            type="file"
            maxSize={3145728}
            loading={loadingLogo}
          />
          <Input
            id="banner"
            label="Banner Padrão da Marca"
            value={banner.name}
            onChange={(value) => handleChanges(value, 'banner', () => setBanner(value))}
            message="Tamanho recomendado: 1650x400px. Até 5MB."
            previewClick={() => handleViewImage(banner.data, 'banner')}
            type="file"
            maxSize={5242880}
            loading={loadingBanner}
          />
        </StyledRowContainer>
        <>
          <StyledTitle>
            Configurações Extras
          </StyledTitle>
          <StyledRowContainer>
          </StyledRowContainer>
          <StyledRowContainer>
            <Input id="zipCode" label="CEP de origem" type="text" value={zipCode} onChange={(e) => handleChanges(e.target.value, 'zipCode', () => setZipCode(mask(e.target.value, ['99999-999'])))} />
            <Input id="whatsApp" label="Número WhatsApp" type="text" value={whatsApp} onChange={(e) => handleChanges(e.target.value, 'whatsApp', () => setwhatsApp(mask(e.target.value, ['(99) 9 9999-9999'])))} />
            <Input id="products-quantity" label="Produtos por página" type="number" value={productsQuant} onChange={(e) => handleChanges(e.target.value, 'productsQuant', () => setProductsQuant(mask(e.target.value, ['99'])))} />
          </StyledRowContainer>
          <StyledRowContainerFlex>
            <Checkbox name="product-without-image" checked={displaysProductWithoutImage} onChange={(value) => handleChanges(value, 'displaysProductWithoutImage', () => setDisplaysProductWithoutImage(!displaysProductWithoutImage))} label="Ocultar produtos sem imagem na vitrine?" />
          </StyledRowContainerFlex>
        </>
        <>
          <StyledTitle>
          </StyledTitle>
          <StyledRowContainer>
          </StyledRowContainer>
          <StyledRowContainer style={{ justifyContent: 'space-around' }} >
            <p>
              <StyledTitle>
                Acesso Vendedor
              </StyledTitle>
              <img src={qrCode} alt="Acesso Vendedor" />
            </p>
            <p>
              <StyledTitle>
                Acesso Consumidor
              </StyledTitle>
              <img src={qrCodeVitrine} alt="Acesso Consumidor" />
            </p>
          </StyledRowContainer>
        </>
        <StyledFlex>
          <StyledChanges>
            {`${changes.length} Alterações não salvas`}
          </StyledChanges>
          <StyledSubmitButton type="submit" disabled={changes.length === 0}>
            {loading ? (
              <Loader
                type="Oval"
                color={theme.colors.white}
                height={20}
                width={20}
                timeout={0}
              />
            ) : (
              'SALVAR ALTERAÇÕES'
            )}
          </StyledSubmitButton>
        </StyledFlex>
      </StyledMainContainer>
      {openModal && (
        <Modal isVisible showCloseButton overflow="hidden" width="max-content" height="max-content" padding="40px" onCloseCallback={() => setOpenModal(false)}>
          {imageType === 'logo' ? (
            <StyledLogoImage src={modalImage} alt="Pré visualização da imagem" />
          ) : (
            <StyledBannerImage src={modalImage} alt="Pré visualização da imagem" />
          )}
        </Modal>
      )}
      {toastInfo?.message && (
        <Toast
          type={toastInfo.isError ? 'error' : 'success'}
          message={toastInfo.message}
          position="bottom-center"
          onClose={() => setToastInfo(null)}
        />
      )}
    </>
  );
}

Settings.propTypes = {
};

Settings.defaultProps = {
};

export default Settings;