import redirectBlank from 'assets/icons/redirect-blank.svg';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { ChromePicker } from 'react-color';
import Loader from 'react-loader-spinner';

// styles
import {
  StyledLabel,
  StyledMainContainer,
  StyledInput,
  StyledColorButton,
  StyledColorPicker,
  StyledInvisibleContainer,
  StyledUploadButton,
  StyledAlert,
  StyledLabelContainer,
  StyledFlex,
  StyledIconContainer
} from './styles';

function Input({ label, value, onChange, secondaryLabel, id, isColorPicker, type, message, maxSize, previewClick, loading, icon, maxLength }) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const hiddenFileInput = useRef(null);
  const [showImagePreview, setShowImagePreview] = useState(true);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    // Check if the file size is less than 3mb
    if (event?.target?.files[0]?.size > maxSize) {
      setError(true);
    }
    onChange(fileUploaded);
    setShowImagePreview(false);
  };

  return (
    <StyledLabel htmlFor={id}>
      <StyledLabelContainer>
        <p>{label}</p>
        {type === 'file' && showImagePreview && value && (
          <StyledFlex>
            <button type="button" onClick={previewClick}>VISUALIZAR IMAGEM</button>
            <img src={redirectBlank} alt="ícone de redirecionamento" />
          </StyledFlex>
        )}
      </StyledLabelContainer>
      <StyledMainContainer>
        {secondaryLabel && <p>{secondaryLabel}</p>}
        <StyledInput type={type !== "file" ? type : ''} maxLength={maxLength} id={id} disabled={type === "file"} value={value} onChange={(e) => onChange(e)} />
        {isColorPicker && <StyledColorButton type='button' onClick={() => setOpen(!open)} background={value} />}
        {open ? (
          <StyledColorPicker>
            <StyledInvisibleContainer onClick={() => setOpen(!open)} />
            <ChromePicker disableAlpha color={value} maxLength={maxLength} onChange={(val) => onChange(val.hex)} />
          </StyledColorPicker>
        ) : null}
        {type === 'file' && (
          <>
            <StyledUploadButton type="button" onClick={handleClick}>
              {loading ? (
                <Loader type="Oval" color="white" height={20} width={20} timeout={0} />
              ) : 'ALTERAR'}
            </StyledUploadButton>
            <input id={id} ref={hiddenFileInput} type="file" accept="image/*" hidden onChange={(e) => handleChange(e)} />
          </>
        )}
        {icon && (
          <StyledIconContainer>
            {icon}
          </StyledIconContainer>
        )}
      </StyledMainContainer>
      {type === 'file' && (
        !error ? (
          <StyledAlert>{message}</StyledAlert>
        ) : (
          <StyledAlert>Tamanho máximo excedido.</StyledAlert>
        )
      )}
    </StyledLabel>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func,
  secondaryLabel: PropTypes.string,
  id: PropTypes.string,
  isColorPicker: PropTypes.bool,
  type: PropTypes.string,
  message: PropTypes.string,
  maxSize: PropTypes.number,
  maxLength: PropTypes.number,
  previewClick: PropTypes.func,
  loading: PropTypes.bool,
  icon: PropTypes.any
}

Input.defaultProps = {
  label: '',
  value: '',
  onChange: () => { },
  secondaryLabel: '',
  id: '',
  isColorPicker: false,
  type: 'text',
  message: '',
  maxSize: 0,
  previewClick: () => { },
  loading: false,
  icon: null,
  maxLength: 100
}

export default Input;