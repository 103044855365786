import ResizableBox from "components/ResizableBox";
import React, { useMemo } from "react";
import { Chart } from "react-charts";

export default function Bar({ data, defaultColors }) {

  const primaryAxis = useMemo(() => ({
    getValue: (datum) => datum.label,
  }), []);

  const secondaryAxes = React.useMemo(() => [{
      getValue: (datum) => datum.value,
    },
  ], []);

  return (
    <>
      <ResizableBox>
        <Chart
          options={{
            defaultColors,
            data,
            primaryAxis,
            secondaryAxes,
          }}
        />
      </ResizableBox>
    </>
  );
}
