import styled, { css } from 'styled-components';
import theme from 'styles/theme';

export const StyledPagination = styled.ul`
  display: flex;
  list-style: none;
  justify-content: center;
  margin: 15px 0;
`;

export const StyledListItem = styled.a`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  font-size: ${props => props.theme.fontSize._10px}rem;
  height: 35px;
  width: 35px;

  ${props =>
    props.disable &&
    css`
      color: ${theme.colors.black20};
    `}

  ${props =>
    props.active &&
    css`
      border-radius: 4px;
      border: 1px solid #3D7981;
      color: #3D7981;
    `}
`;
