/* eslint-disable react/prop-types */
import Default from 'assets/images/default-product.png';
import ModalSendContact from 'components/Modals/Avise-me';
import Toast from 'components/Toast';
import Text from 'components/Typografy/Text';
import _ from 'lodash';
import React, { useEffect, useState, memo, useMemo } from 'react';
import { MdAddShoppingCart } from 'react-icons/md';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { Types as CartTypes } from 'store/ducks/Checkout';
import api from '../../../services/api';

import { colorValues } from '../../../styles/colors';
import theme from '../../../styles/theme';
import {
  StyledDiscountOff,
  StyledProduct,
  StyledPicture,
  StyledCarousel,
  StyledProductColor,
  StyledColors,
  StyledListSize,
  StyledProductSize,
  StyledTitle,
  StyledPrice,
  StyledPriceCondition,
  StyledButton,
} from './styles';

function Card({ product: { productDto, variations } }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [filters, setFilters] = useState([]);
  const [variation, setVariation] = useState();
  const [sizes, setSizes] = useState([]);
  const [toastInfo, setToastInfo] = useState();
  const { params } = useRouteMatch();
  // eslint-disable-next-line
  const [onlyOnStock, setOnlyOnStock] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [sku, setSku] = useState();
  const [units, setUnits] = useState(0);
  const [showModalState, setShowModalState] = useState(false);
  const addedProducts = useSelector(state => state.Checkout);
  const settings = useSelector(state => state.Setting.data);

  const location = useLocation();
  const sellerId = new URLSearchParams(location.search).get('sellerId');

  const amount = useSelector(state =>
    state.Checkout.reduce((sumAmount, item) => {
      sumAmount[item.id] = item.amount;
      return sumAmount;
    }, {}),
  );

  useEffect(() => {
    const filteredVariations = variations.filter(v => {
      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        const condition = v.specifications.some(spec => {
          return spec.typeId === filter.typeId && spec.value === filter.value;
        });

        if (!condition) return false;
      }

      return true;
    });
    if (variations) {
      setSku(
        filteredVariations[0]?.stockKeepingUnit
          .replace(/-/, '')
          .replace(/-/, ''),
      );
      setVariation(filteredVariations[0]);
    }
  }, [filters, variations]);

  async function requestStock() {
    setLoadingButton(true);
    const response = await api.get(`/product/${sku}/stocks`);

    if (!response.data.payload) {
      setLoadingButton(false);
      setUnits(0);
      return false;
    }
    setUnits(response.data.payload.units);
    setLoadingButton(false);
    return true;
  }

  useMemo(() => {
    if (variation) {
      requestStock();
    }
  }, [variation]);

  useEffect(() => {
    if (variation) {
      setSizes(
        productDto.specifications.filter(itemSpec => itemSpec.typeId === 1),
      );
    }
  }, [variation, productDto]);

  const addedIndex = () => {
    return addedProducts.findIndex(item => item.id === variation?.productId)
  }

  const handleAddToCart = (itemCart) => {
    const indexCarrinho = addedIndex();

    // Se ha o produto adicionado, verifique se ele n excedeu o limite do stock
    if (addedProducts[indexCarrinho]?.amount) {
      if ((units - addedProducts[indexCarrinho]?.amount) > 0) {
        dispatch({
          type: CartTypes.ADD_CART,
          item: {
            id: itemCart.productId,
            ncm: itemCart.ncm,
            reference: itemCart.reference,
            stockKeepingUnit: variation.stockKeepingUnit,
            name: itemCart.name,
            price: variation.basePrice,
            image: variation.images[0],
            size: variation.specifications
              .filter(itemSpec => itemSpec.typeId === 1)
              .map(itemSpec => itemSpec.description),
            color: variation.specifications
              .filter(itemSpec => itemSpec.typeId === 2)
              .map(itemSpec => itemSpec.value),
          },
        });

        setToastInfo({ isError: false, message: `Produto adicionado ao carrinho!` });
      }
    } else {
      dispatch({
        type: CartTypes.ADD_CART,
        item: {
          id: itemCart.productId,
          ncm: itemCart.ncm,
          reference: itemCart.reference,
          stockKeepingUnit: variation.stockKeepingUnit,
          name: itemCart.name,
          price: variation.basePrice,
          image: variation.images[0],
          size: variation.specifications
            .filter(itemSpec => itemSpec.typeId === 1)
            .map(itemSpec => itemSpec.description),
          color: variation.specifications
            .filter(itemSpec => itemSpec.typeId === 2)
            .map(itemSpec => itemSpec.value),
        },
      });

      setToastInfo({ isError: false, message: `Produto adicionado ao carrinho!` });
    }
  };

  const handleFilter = (typeId, value) => {
    setFilters(oldFilters => [
      ...oldFilters.filter(filter => filter.typeId !== typeId),
      { typeId, value },
    ]);
  };

  function resetShowModalStateHandler() {
    setShowModalState(false);
  }

  function handleDetails(productId) {
    history.push(`/${params.store}/detalhes/${productId}?sellerId=${sellerId}`);
  }

  const handleEnableButton = () => {
    if (units > 0 && variation?.productId && variation?.basePrice) {
      return true;
    }
    return false;
  }

  const handleDisabledButton = () => {
    const indexCarrinho = addedIndex();

    if (!variation?.productId || !variation?.basePrice || !units > 0 || loadingButton || !(addedProducts[indexCarrinho]?.amount === undefined || addedProducts[indexCarrinho]?.amount < units)) {
      return true;
    }
    return false;
  }

  const handleButtonContent = () => {
    const indexCarrinho = addedIndex();

    if (loadingButton) {
      return (
        <Loader type="Oval" color="#FFF" height={20} width={20} />
      );
    }
    if (addedProducts[indexCarrinho]?.amount >= units) {
      return 'MÁXIMO DE PRODUTOS ATINGIDO'
    }
    if (handleEnableButton()) {
      return 'ADICIONAR AO CARRINHO';
    }
    return 'PRODUTO INDISPONÍVEL'
  }

  const ordened = _.orderBy(sizes, ['value'], ['asc']);

  return (
    <>
      <StyledProduct>
        {variation && (
          <StyledPicture>
            {variation?.listPrice !== variation?.basePrice &&
              variation?.listPrice > variation?.basePrice ? (
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                }}
              >
                <StyledDiscountOff>
                  <h1>
                    {`${parseInt(
                      100 - (100 * variation.basePrice) / variation.listPrice,
                    )}% off`}
                  </h1>
                </StyledDiscountOff>
              </div>
            ) : (
              <> </>
            )}
            <StyledCarousel infiniteLoop transitionTime={750} showThumbs={false} showArrows showIndicators={false}>
              {variation?.images?.length > 0 ?
                variation.images.map((itemImg, indexImg) => (
                  <div key={indexImg} id="NewButton" onClick={() => handleDetails(productDto.id)} type="button">
                    <img src={itemImg?.urlImage || Default} alt="Imagem do produto" />
                  </div>
                )) : (
                  <div id="NewButton" onClick={() => handleDetails(productDto.id)} type="button">
                    <img src={Default} alt="Imagem do produto" />
                  </div>
                )}
            </StyledCarousel>
          </StyledPicture>
        )}
        <StyledProductColor
          value={
            productDto.specifications.filter(
              itemSpec => itemSpec.typeId === 2,
            ).length
          }
        >
          <div
            style={{
              margin: '0 auto',
              alignItems: 'center',
              boxSizing: 'border-box',
              minHeight:
                productDto.specifications.filter(
                  itemSpec => itemSpec.typeId === 2,
                ).length < 17
                  ? '47px'
                  : '40px',
              justifyContent:
                productDto.specifications.filter(
                  itemSpec => itemSpec.typeId === 2,
                ).length < 17
                  ? 'center'
                  : 'flex-start',
              display:
                productDto.specifications.filter(
                  itemSpec => itemSpec.typeId === 2,
                ).length < 17
                  ? 'block'
                  : 'flex',
              overflowX:
                productDto.specifications.filter(
                  itemSpec => itemSpec.typeId === 2,
                ).length < 17
                  ? 'hidden'
                  : 'auto',
            }}
          >
            {productDto.specifications
              .filter(itemSpec => itemSpec.typeId === 2)
              .map((itemSpec, indexSpec) => (
                <StyledColors
                  type="button"
                  onClick={() =>
                    handleFilter(itemSpec.typeId, itemSpec.value)}
                  active={variation?.specifications?.some(
                    spec =>
                      spec.typeId === 2 && spec.value === itemSpec.value,
                  )}
                  key={indexSpec}
                  style={{
                    backgroundColor: colorValues.find(x => {
                      return (
                        x.name.toLowerCase() ===
                        itemSpec.value
                          .replace('/', '-')
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .toLowerCase()
                      );
                    })?.value,
                  }}
                />
              ))}
          </div>
        </StyledProductColor>
        <StyledListSize>
          <div
            style={{
              margin: '0 auto',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              display:
                productDto.specifications.filter(
                  itemSpec => itemSpec.typeId === 1,
                ).length > 7
                  ? 'block'
                  : 'flex',
            }}
          >
            {ordened.map((itemSpec, indexSpec) => (
              <StyledProductSize
                active={variation.specifications.some(
                  spec => spec.typeId === 1 && spec.value === itemSpec.value,
                )}
                type="button"
                onClick={() => handleFilter(itemSpec.typeId, itemSpec.value)}
                key={indexSpec}
              >
                {itemSpec.description}
              </StyledProductSize>
            ))}
          </div>
        </StyledListSize>
        {variation && (
          <>
            <StyledTitle>{variation.name}</StyledTitle>
            <div
              style={{
                minHeight: '70px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {variation?.listPrice !== variation?.basePrice &&
                variation?.listPrice > variation?.basePrice ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingTop: '10px',
                  }}
                >
                  <s
                    style={{
                      fontSize: '16px',
                      fontWeight: '300',
                      color: '#828282',
                    }}
                  >
                    {variation &&
                      Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(variation.listPrice)}
                  </s>
                  {' | '}
                  <Text fontSize="18px" fontWeight="300" color="#FF4040">
                    {variation &&
                      Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(variation.basePrice)}
                  </Text>
                </div>
              ) : (
                <StyledPrice style={{ color: '#000' }}>
                  {variation &&
                    Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(variation.basePrice)}
                </StyledPrice>
              )}

              <StyledPriceCondition>
                {variation &&
                  `Ou em até 6x de
                    ${Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(variation.basePrice / 6)} sem juros`}
              </StyledPriceCondition>
            </div>
          </>
        )}
        <StyledButton backgroundColor={settings.buttonColor} disabled={handleDisabledButton()} onClick={() => handleAddToCart(variation)}>
          <div className="little-car">
            <MdAddShoppingCart size={16} color={theme.colors.white} />
            {amount[variation?.productId] || 0}
          </div>
          <span>
            {handleButtonContent()}
          </span>
        </StyledButton>
        {toastInfo?.message && (
          <Toast type={toastInfo.isError ? 'error' : 'success'} message={toastInfo.message} position="bottom-center" onClose={() => setToastInfo(null)} />
        )}
      </StyledProduct>
    </>
  );
}

export default memo(Card);
