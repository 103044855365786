import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const skeletonLoadTable = () => {
  const skeletonLoader = [];

  for (let i = 0; i < 15; i++) {
    skeletonLoader.push(
      <tr>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
        <td>
          <Skeleton />
        </td>
      </tr>,
    );
  }

  return skeletonLoader;
};

export const handleStatus = colors => {
  switch (colors) {
    case 'Cancelado':
      return '#E12121';
    case 'Falha na Integração':
      return '#E12121';
    case 'Pedido realizado':
      return '#429B4A';
    case 'Pedido finalizado':
      return '#429B4A';
    case 'Saída para entrega':
      return '#692B82';
    case 'Pronto para retirada':
      return '#692B82';
    case 'Pedido pago':
      return '#00CD98';
    case 'Aguardando validação':
      return '#F6B900';
    case 'Pedido Integrado':
      return '#F6B900';
    case 'Pedido Confirmado':
      return '#F6B900';
    case 'Pedido faturado':
      return '#F6B900';
    case 'Separação em estoque':
      return '#4B88AB';
    case 'Pedido finalizado':
      return '#FFF';
    default:
      return '#FFF';
  }
};

export const handleStatusB = background => {
  switch (background) {
    case 'Cancelado':
      return '#FFE3E3';
    case 'Falha na Integração':
      return '#FFE3E3';
    case 'Pedido realizado':
      return '#B4FFBB';
    case 'Pedido finalizado':
      return '#B4FFBB';
    case 'Saída para entrega':
      return '#ECD9F2';
    case 'Pronto para retirada':
      return '#ECD9F2';
    case 'Pedido pago':
      return '#D9F2EC';
    case 'Aguardando validação':
      return '#FEF6D9';
    case 'Pedido Integrado':
      return '#FEF6D9';
    case 'Pedido Confirmado':
      return '#FEF6D9';
    case 'Pedido faturado':
      return '#FEF6D9';
    case 'Separação em estoque':
      return '#A6DFFF';
    case 'Pedido finalizado':
      return '#3D3D3D';
    default:
      return '#000';
  }
};