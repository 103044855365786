import { StyledCatalogSendButton } from 'pages/Customers/CreateCatalogModal/StyledCatalogSendButton/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Loader from 'react-loader-spinner';
import theme from 'styles/theme';

const CatalogSendButton = ({ onClick, text, disabled, loading }) => {
  return (
    <StyledCatalogSendButton disabled={disabled} type="button" onClick={(e) => onClick(e)}>
      {loading ? (
        <Loader
          type="Oval"
          color={theme.colors.white}
          height={15}
          width={15}
          timeout={0}
        />
      ) : text}
    </StyledCatalogSendButton>
  );
};

CatalogSendButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

CatalogSendButton.defaultProps = {
  onClick: () => null,
  disabled: false,
  loading: false
};

export default CatalogSendButton;
