import ResizableBox from "components/ResizableBox";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { StyledFlexContainer } from 'styles/global';

import {
  StyledMainContainer,
  StyledBar,
  StyledProgressBarContainer,
  StyledMaxProgress,
  StyledCurrentProgress
} from './styles';

function HorizontalBar({ data }) {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const sum = data?.reduce((acc, obj) => (acc + obj.value), 0);
    setTotal(sum);
  }, [data]);

  return (
    <ResizableBox height={250}>
      <StyledMainContainer>
        {data?.map((item, index) => (
          <div key={index}>
            <StyledFlexContainer key={index} justify="space-between">
              <StyledBar>{item?.label}</StyledBar>
              <StyledBar>{`R$ ${item?.value}`}</StyledBar>
            </StyledFlexContainer>
            <StyledProgressBarContainer>
              <StyledMaxProgress>
                <StyledCurrentProgress width={`${(item?.value / total) * 100}%`} />
              </StyledMaxProgress>
            </StyledProgressBarContainer>
          </div>
      ))}
      </StyledMainContainer>
    </ResizableBox>
  );
}

HorizontalBar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
};

export default HorizontalBar;
