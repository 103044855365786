import InputTemplate from 'components/Forms/InputTemplate';
import Modal from 'components/Modals/Modal';
import Toast from 'components/Toast';
import Text from 'components/Typografy/Text';
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import * as RequestContext from 'services/requests-services';
import theme from 'styles/theme';
import Default from 'assets/images/default-product.png';
import * as yup from 'yup';

import {
  StyledContainer,
  StyledButton,
  StyledDivButton,
  StyledTitle,
  StyledFields,
  StyledTable,
  StyledTableBody,
} from './styles';

const ModalSendContact = ({
  onCloseCallback,
  onStatusChangeCallBack,
  isLoading,
  requestData,
  products,
}) => {
  const [formData, setFormData] = useState({
    stockKeepingUnit: products.stockKeepingUnit,
    name: '',
    email: '',
  });
  const [verification, setVerification] = useState(false);
  const [toastInfo, setToastInfo] = useState(null);

  const validationSchema = yup.object().shape({
    stockKeepingUnit: yup.string().nullable(),
    name: yup.string().required('Campo obrigatório').nullable(),
    email: yup
      .string()
      .email()
      .nullable()
      .required('Campo obrigatório')
      .typeError('Email inválido'),
  });

  const { register, errors } = useForm({
    validationSchema,
    mode: 'onBlur',
  });

  useMemo(() => {
    validationSchema.isValid(formData).then(x => {
      x ? setVerification(true) : setVerification(false);
    });
  }, [formData]);

  function handleInputChange(event) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  const handleSubmit = async x => {
    const response = await RequestContext.setUserData(x);
    setToastInfo({ isError: response?.isError, message: response?.message });
  };

  return (
    <Modal
      showCloseButton
      width="540px"
      height="auto"
      padding={0}
      isLoading={isLoading}
      onCloseCallback={onCloseCallback}
      isVisible
    >
      <StyledContainer>
        <StyledTitle>
          <Text
            fontSize={theme.fontSize._24px}
            color={theme.colors.cyan}
            textAlign="left"
            fontWeight="bold"
            marginTop="20px"
          >
            Ops, já vendemos todo nosso estoque!!
          </Text>
          <Text
            fontSize={theme.fontSize._16px}
            color="#333333"
            textAlign="left"
          >
            Informe os dados abaixo e nós te avisaremos por e-mail quando este
            produto estiver disponível.
          </Text>
        </StyledTitle>

        <StyledTable>
          <colgroup>
            <col style={{ width: '15%' }} />
            <col style={{ width: '45%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '20%' }} />
          </colgroup>
          <StyledTableBody>
            <tr key={products.id}>
              <td>
                <img src={products?.images[0]?.urlImage || Default} />
              </td>
              <td>{products.completeName}</td>
            </tr>
          </StyledTableBody>
        </StyledTable>

        <StyledFields>
          {/* <StyledAlignDuo> */}
          <InputTemplate
            name="name"
            onChange={e => handleInputChange(e)}
            ref={register({
              required: 'Por favor digite seu nome',
            })}
          >
            Nome
          </InputTemplate>
          <InputTemplate name="email" onChange={e => handleInputChange(e)}>
            E-mail
          </InputTemplate>
          {/* </StyledAlignDuo> */}
        </StyledFields>

        <StyledDivButton>
          {verification ? (
            <>
              <StyledButton
                bgcolor={theme.colors.cyan}
                onClick={() => handleSubmit(formData)}
              >
                Enviar
              </StyledButton>
              {toastInfo && (
                <Toast
                  type={toastInfo.isError ? 'warning' : 'success'}
                  message={toastInfo.message}
                  position="bottom-center"
                  onClose={() => onCloseCallback()}
                />
              )}
            </>
          ) : (
            <StyledButton bgcolor={theme.colors.gray}>
              Preencha todos os campos
            </StyledButton>
          )}
        </StyledDivButton>
      </StyledContainer>
    </Modal>
  );
};

ModalSendContact.propTypes = {
  onCloseCallback: PropTypes.func,
  onStatusChangeCallBack: PropTypes.func,
  isLoading: PropTypes.bool.isRequired,
};

ModalSendContact.defaultProps = {
  onCloseCallback: () => null,
  onStatusChangeCallBack: () => null,
};

export default ModalSendContact;
