import PropTypes from 'prop-types';
import React from 'react';
import theme from 'styles/theme';

import { StyledText } from './styles';

const Text = ({
  children,
  fontWeight,
  fontSize,
  color,
  cursor,
  onClick,
  textAlign,
  marginBottom,
  marginTop,
  ...rest
}) => (
  <StyledText
    fontWeight={fontWeight}
    fontSize={fontSize}
    color={color}
    cursor={cursor}
    onClick={onClick}
    textAlign={textAlign}
    marginBottom={marginBottom}
    marginTop={marginTop}
    {...rest}
  >
    {children}
  </StyledText>
);

Text.propTypes = {
  children: PropTypes.node.isRequired,
  fontWeight: PropTypes.string,
  fontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  color: PropTypes.string,
  cursor: PropTypes.string,
  onClick: PropTypes.func,
  textAlign: PropTypes.string,
};

Text.defaultProps = {
  fontWeight: '300',
  fontSize: theme.fontSize._12px,
  cursor: 'auto',
  color: theme.colors.black55,
  textAlign: 'right',
  onClick: () => {},
};

export default Text;
