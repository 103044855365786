import { CheckboxComponent, CheckboxLabelComponent } from 'components/Forms/Checkbox/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import theme from 'styles/theme';

function Checkbox({
  label,
  name,
  register,
  value,
  checked,
  onClick,
  labelColor,
  invertColor,
  color,
  className,
  ...rest
}) {

  const [checkedState, setCheckedState] = useState(checked);

  useEffect(() => {
    setCheckedState(checked);
  }, [checked]);

  const handleClick = event => {
    setCheckedState(event.target.checked);
    onClick(event.target.checked, { name, value });
  };

  return (
    <>
      <CheckboxComponent
        id={name + value}
        type="checkbox"
        name={name}
        ref={register}
        value={value}
        color={color}
        onClick={handleClick}
        checked={checkedState ? 'checked' : ''}
        invertColor={invertColor}
        {...rest}
      />
      <CheckboxLabelComponent className={className} htmlFor={name + value} labelColor={labelColor}>
        {label}
      </CheckboxLabelComponent>
    </>
  );
}

Checkbox.propTypes = {
  label: PropTypes.string,
  labelColor: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  register: PropTypes.func,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  invertColor: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  onClick: () => { },
  register: () => { },
  label: '',
  checked: false,
  value: true,
  labelColor: theme.colors.black55,
  invertColor: false,
  color: theme.colors.lightGreen,
  className: ''
};

export default Checkbox;
