import styled from 'styled-components';

export const SelectContainer = styled.select`
  width: 250px;
  height: 40px;
  color: #828282;
  border: 1px solid #CECECE;
  border-radius: 4px;
  z-index: 999;
  background-color: ${props => props.disabled && "#D9D9D9"};
  cursor: ${props => props.disabled ? "unset" : "pointer"};
`;

export const StyledContainer = styled.div`
  padding: 30px 50px 10px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled.button`
  width: 94px;
  height: 35px;
  border: none;
  background-color: #3D7981;
  border-radius: 4px;
  justify-items: center;
  padding: 4px 18px;
  margin: 10px 0;
  cursor: pointer;
  align-self: flex-end;

  &:disabled {
    opacity: 0.7;
    cursor: unset;
  }
`;

export const StyledLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 250px;
`
