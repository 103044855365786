import expandLess from 'assets/icons/expandLess.svg';
import expandMore from 'assets/icons/expandMore.svg';

import filter from 'assets/IconsSvg/filter.svg';
import Text from 'components/Typografy/Text';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import useCollapse from 'react-collapsed';

import {
  StyledContainer,
  StyledIcon,
  StyledExpand,
  StyledCategories,
  StyledButton,
} from './styles';

const Expand = ({
  children,
  title,
  titleAlign,
  titleFontSize,
  titleFontWeight,
  titleColor,
  borderBottom,
  height,
  icon1,
  icon2,
  icon3,
  master,
  bColor,
  titleMBottom,
  titleMTop,
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded });

  return (
    <StyledContainer bColor={bColor} marcation={borderBottom}>
      <StyledExpand>
        <Text
          fontSize={titleFontSize}
          fontWeight={titleFontWeight}
          textAlign={titleAlign}
          color={titleColor}
          marginBottom={titleMBottom}
          marginTop={titleMTop}
        >
          {title}
        </Text>
        <div>
          {icon3}
          {master ? (
            <StyledButton
              src={expandMore}
              {...getToggleProps({
                onClick: () => setExpanded(prevExpanded => !prevExpanded),
              })}
            >
              {isExpanded ? (
                <StyledIcon src={filter} />
              ) : (
                <StyledIcon src={filter} />
              )}
            </StyledButton>
          ) : (
            <StyledButton
              src={expandMore}
              {...getToggleProps({
                onClick: () => setExpanded(prevExpanded => !prevExpanded),
              })}
            >
              {isExpanded ? (
                <StyledIcon src={icon1} />
              ) : (
                <StyledIcon src={icon2} />
              )}
            </StyledButton>
          )}
        </div>
      </StyledExpand>
      <StyledCategories {...getCollapseProps()}>{children}</StyledCategories>
    </StyledContainer>
  );
};

Expand.propTypes = {
  height: PropTypes.string,
  icon1: PropTypes.string,
  icon2: PropTypes.string,
};

Expand.defaultProps = {
  height: 'auto',
  icon1: expandLess,
  icon2: expandMore,
};

export default Expand;
