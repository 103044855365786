import Search from 'assets/IconsSvg/search.svg';
import { StyledInputText, StyledImage } from 'pages/Customers/CreateCatalogModal/CatalogSendInputText/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { StyledFlexContainer } from 'styles/global';

const CatalogSendInputText = ({ onClick, onChange, placeholder, disabled }) => {
  return (
    <StyledFlexContainer align="center">
      <StyledInputText
        disabled={disabled}
        type="text"
        placeholder={placeholder}
        onClick={event => onClick(event)}
        onChange={onChange}
      />
      <StyledImage src={Search} alt="icone de lupa" />
    </StyledFlexContainer>
  );
};

CatalogSendInputText.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
};

CatalogSendInputText.defaultProps = {
  onClick: () => null,
  onChange: () => null,
  placeholder: '',
  disabled: false
};

export default CatalogSendInputText;
