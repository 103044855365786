import styled from 'styled-components';
import { darken } from 'polished';

export const IconStyledComponent = styled.div`
  display: inline-block;
  width: ${props => props.width};
  height: ${props => props.height};
  margin-right: ${props => props.mgRight};

  transition: background 0.25s;

  background: ${props => props.color};

  mask-image: url(${props => props.icon});
  mask-repeat: no-repeat;
  mask-position: 0 0;
  mask-size: contain;
  cursor: ${props => (props.pointer ? 'pointer' : 'unset')};
  &:hover,
  &:focus {    
      background: ${props => darken(0.20, props.hoverColor || props.color)};    
  }
`;
