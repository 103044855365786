import api from 'services/api';

export async function getColors() {
  return api.get(`/settings/colors`);
}

export async function getSizes() {
  return api.get(`/settings/sizes`);
}

export async function getCategories() {
  return api.get(`/settings/categories`);
}
