import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyledBody, StyledTitleContainer, } from 'pages/Customers/MacroHelpModal/styles';
import Modal from 'components/Modals/Modal';
import DataGrid from 'components/Datagrid';

const macroOptions = [
    {
        id: 1,
        title: '[nome_usuario]',
        value: 'Nome do usuário destinatário',
    },
    {
        id: 2,
        title: '[seller]',
        value: 'Nome do vendedor responsável',
    },
    {
        id: 3,
        title: '[link_catalogo]',
        value: 'Link do catálogo',
    },
    {
        id: 4,
        title: '[lista_produtos]',
        value: 'Lista de produtos do catálogo',
    },
    {
        id: 5,
        title: '*texto*',
        value: 'Negrito: insira um asterisco antes e depois do texto',
    },
    {
        id: 6,
        title: '%0a%0a',
        value: 'Quebra de linha',
    },
];

const tableBody = () => {
    return macroOptions.map((macro, index) => (
        <tr key={`${macro?.id}-${index}`}>
            <td>
                <span>{macro?.title}</span>
            </td>
            <td>
                <span>{macro?.value}</span>
            </td>
        </tr>
    ));
}

const MacroHelpModal = ({ onCloseCallback }) => {
    return (
        <Modal showCloseButton width="90%" maxWidth='600px' height='400px' overflow='auto' onCloseCallback={onCloseCallback} isVisible>
            <StyledBody>
                <StyledTitleContainer style={{ justifyContent: 'center', textAlign: 'center' }}>
                    Lista de macros para envio de mensagens
                </StyledTitleContainer>
                <DataGrid width="100%" height="unset" minHeight="300px" maxHeight="320px" style={{ marginTop: '15px' }} >
                    <colgroup>
                        <col style={{ width: '35%', minWidth: '120px' }} />
                        <col style={{ width: '65%', minWidth: '120px' }} />
                    </colgroup>
                    <thead style={{ textAlign: 'left', position: 'sticky', backgroundColor: '#FFF', top: '0' }}>
                        <tr>
                            <th>Macro</th>
                            <th>Dados</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableBody()}
                    </tbody>
                </DataGrid>
            </StyledBody>
        </Modal >
    )
}

export default MacroHelpModal

MacroHelpModal.propTypes = {
    onCloseCallback: PropTypes.func,
};

MacroHelpModal.defaultProps = {
    onCloseCallback: () => null,
};
