import styled, { css } from 'styled-components';

export const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 30%;

  textarea {
    background-color: #FAFFFF;
    border: 1px solid #BFCCFB;
    line-height: 1.2;
    margin-bottom: 20px;
  }
`;

export const StyledSuport = styled.div`
  display: ${props => (props.display ? 'inline' : 'none')};
`;

export const StyledLabel = styled.label`
display: flex;
align-items: center;
flex-direction: row;
`;

export const StyledListMacros = styled.ul`  
display: grid;
box-sizing: border-box;
grid-template-columns: repeat(3, 1fr);
grid-gap: 5px;
list-style: none;
position: relative;
height: auto;  
align-items: center;
justify-content: center;

@media (max-width: 1450px) {
  grid-template-columns: repeat(2, 1fr);
}
`;

export const StyledSelectedProductsUpdate = styled.div`
  height: 360px; 
  width: 100%;
  padding: 10px;
  overflow-y: auto;
`;

export const StyledTitleContainer = styled.p`
  height: 60px;
  padding: 0 10px;  
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledListDiv = styled.div`
  height: 360px; 
  width: 100%;
  padding: 10px;
  overflow-y: auto;
`;