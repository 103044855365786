export const Types = {
    GET_STORE_ADDRESS: 'STOREADDRESS/GET_PRODUCT',
    GET_STORE_ADDRESS_SUCCESS: 'STOREADDRESS/GET_STORE_ADDRESS_SUCCESS',
    GET_STORE_ADDRESS_ERROR: 'STOREADDRESS/GET_STORE_ADDRESS_ERROR',
};

const INITIAL_STATE = {
    data: [],
    loading: false,
    error: '',
};

export default function storeAddress(state = INITIAL_STATE, action) {
    switch (action.type) {
        case Types.GET_STORE_ADDRESS: {
            return { data: [], loading: true, error: '' };
        }
        case Types.GET_STORE_ADDRESS_SUCCESS: {
            return {
                data: action.data,
                loading: false,
                error: '',
            };
        }
        case Types.GET_STORE_ADDRESS_ERROR: {
            return {
                data: [],
                loading: false,
                error: action.error,
            };
        }
        default:
            return state;
    }
}
