import ghostImage from 'assets/icons/ghost.svg';
import Collapse from 'components/Collapse';
import DataGrid from 'components/Datagrid';
import Button from 'components/Forms/Button';
import Checkbox from 'components/Forms/Checkbox';
import DatePicker from 'components/Forms/DatePicker';
import Input from 'components/Forms/Input';
import Label from 'components/Forms/Label';
import Pagination from 'components/Forms/newPagination';
import RangeSlider from 'components/Forms/RangeSlider';
import Text from 'components/Typografy/Text/index';
import ModalSendContact from 'pages/Customers/CreateCatalogModal';
import ModalNewContact from 'pages/Customers/CreateCustomer';
import moment from 'moment';
import SelectAllBar from 'pages/Customers/SelectAllBar';
import { skeletonLoadTable } from 'pages/Customers/skeleton';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { mask } from 'remask';
import * as PaymentServices from 'services/payments-services';
import * as SellersServices from 'services/sellers-services';
import theme from 'styles/theme';
import { diffDays } from 'utils/util-date';
import { toFirstLetterUpper } from 'utils/util-string';
import WhatsIcon from 'assets/icons/wpp-button.png';
import { Types as CustomerTypes } from '../../store/ducks/Customer';
import {
  StyledMainContent,
  StyledRow,
  StyledSelectContainer,
  StyledContainer,
  StyledField,
  StyledFilters,
  StyledGhostContainer,
  StyledWppButton,
  StyledLeftFilters,
  StyledCreateCatalog,
  StyledCreateCustomer
} from './styles';

const sizeOptions = [
  { value: 10, label: 'Mostrar 10 por vez' },
  { value: 20, label: 'Mostrar 20 por vez' },
  { value: 30, label: 'Mostrar 30 por vez' }
]

export default function Customers() {

  const dispatch = useDispatch();
  const { register, handleSubmit, getValues } = useForm();
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [seller, setSeller] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openModalCustomer, setOpenModalCustomer] = useState(false);
  const [payment, setPayment] = useState();
  const [sellersOptions, setSellersOptions] = useState([]);
  const [paymentsOptions, setPaymentsOptions] = useState([]);
  const [customersData, setCustomersData] = useState([]);
  const customerState = useSelector(state => state.Customer);

  const [pageSize, setPageSize] = useState({ value: 10, label: 'Mostrar 10 por vez' });
  var paseSizeSelect = 10;

  useEffect(() => {
    onFormSubmit(getValues());
  }, []);

  const onFormSubmit = async (informData, page = 1) => {
    setCustomersData([]);

    const formData = {
      ...informData,
      seller: seller?.id,
      paymentTypeId: payment?.id,
      pageSize: paseSizeSelect,
      pageIndex: page
    };

    dispatch({
      type: CustomerTypes.GET_CUSTOMER,
      payload: {
        data: formData,
      },
    });
  };

  const CloseModal = (status) => {
    setOpenModal(status)
    setOpenModalCustomer(status)
    onFormSubmit(getValues())
  }

  const getSellersData = async () => {
    const response = await SellersServices.getSellers();
    setSellersOptions([{
      name: 'Todos',
      id: ''
    }, ...response?.data?.payload]);
  }

  const getPaymentsData = async () => {
    const response = await PaymentServices.getPaymentTypes();
    setPaymentsOptions([{
      name: 'Todos',
      id: ''
    }, ...response?.data?.payload]);
  }

  useEffect(() => {
    getSellersData();
    getPaymentsData();
  }, []);

  useEffect(() => {
    if (customerState.pager) {
      setTotalRows(customerState.pager.totalRows);
      setTotalPages(customerState.pager.totalPages);
      setCurrentPage(customerState.pager.currentPage);
    }
  }, [customerState.pager]);

  const handleCheckClick = customerData => {
    if (!customersData.some(x => x.id === customerData.id)) {
      setCustomersData(oldCustomersData => [...oldCustomersData, customerData]);
    } else {
      setCustomersData(oldCustomersData =>
        oldCustomersData.filter(c => c.id !== customerData.id),
      );
    }
  };

  const handleSelectAllCheckClick = checked => {
    if (checked) {
      setCustomersData(customerState.data);
    } else {
      setCustomersData([]);
    }
  };

  const handleRemoveAllChecked = () => {
    setCustomersData([]);
  };

  const handleSetPageSize = (value) => {
    setPageSize(value);
    paseSizeSelect = value.value;
    onFormSubmit(getValues())
  };

  const handleSetPage = (page) => {
    paseSizeSelect = pageSize.value;
    onFormSubmit(getValues(), page);
  };

  const sendMessage = async (telephone) => {
    const uri = `https://wa.me/${telephone.replace(/ /g, '')}`;
    window.open(uri, '_blank');
  }

  const tableBody = () => {
    return customerState.data.length > 0 ? (
      customerState.data.map((customer, index) => (
        <tr key={`${customer?.id}-${index}`}>
          <td>
            <Checkbox value={customer?.id} name="contato" onClick={() => handleCheckClick(customer)} checked={customersData.some(x => x.id === customer?.id)} />
          </td>
          <td>
            <span>{toFirstLetterUpper(customer?.name)}</span>
          </td>
          <td>
            {customer?.cpf || "-"}
          </td>
          <td>
            {mask(customer?.phone ?? '', ['99 99999-9999', '+99 99 99999-9999',]) || '-'}
          </td>
          <td>{customer?.email || '-'}</td>
          <td>{customer?.subsidiary || '-'}</td>
          <td>{customer?.seller || '-'}</td>
          <td>
            {(customer?.averageTicket && `R$ ${customer?.averageTicket.toFixed(2)}`) || '-'}
          </td>
          <td>
            {customer?.lastBuy ? `${diffDays(new Date(customer?.lastBuy), new Date(Date.now()),)} dias` : '-'}
          </td>
          <td>
            <StyledWppButton onClick={() => sendMessage(customer.phone)} disabled={!customer.isMobilePhone} type="button" >
              <img src={WhatsIcon} alt="botão do whatsapp" />
            </StyledWppButton>
          </td>
        </tr>
      ))
    ) : (
      <StyledGhostContainer className='ghost-image'>
        <img src={ghostImage} alt="Ilustração de fantasma" />
        <p>Não foram encontrados clientes para o filtro selecionado</p>
      </StyledGhostContainer>
    )
  }

  return (
    <>
      <StyledContainer fluid xs sm md lg xl>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <StyledFilters>
            <StyledLeftFilters>
              <StyledField width="250px" zIndex="12">
                <Label>Comprou no período</Label>
                <DatePicker name="buyPeriod" register={register} height="38px" />
              </StyledField>
              <StyledField width="200px" zIndex="11">
                <Label htmlFor="customerSeller">Vendedor</Label>
                <Select
                  name="customer-seller"
                  inputId="customerSeller"
                  options={sellersOptions}
                  value={seller}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder="Selecione"
                  onChange={(value) => setSeller(value)}
                />
              </StyledField>
              <StyledField width="200px">
                <Label>Documento do cliente</Label>
                <Input type="text" maxLength='18' name="customerDocument" testId="customer-doc" placeholder="Pesquise..." register={register} />
              </StyledField>
              <StyledField width="200px">
                <Label>Nome do cliente</Label>
                <Input type="text" maxLength='50' name="customerName" testId="customer-name" placeholder="Pesquise..." register={register} />
              </StyledField>
              <StyledField width="90px" marginTop="20px">
                <Button backgroundColor={theme.colors.black55} outline type="button" onClick={() => onFormSubmit(getValues())}>
                  FILTRAR
                </Button>
              </StyledField>
            </StyledLeftFilters>
            <StyledField width="200px">
              <StyledCreateCustomer type="button" outline onClick={() => setOpenModalCustomer(true)}>
                NOVO CADASTRO
              </StyledCreateCustomer>
            </StyledField>
            <StyledField width="200px">
              <StyledCreateCatalog type="button" outline onClick={() => setOpenModal(true)}>
                NOVA AÇÃO
              </StyledCreateCatalog>
            </StyledField>
          </StyledFilters>
          <StyledFilters>
            <Collapse buttonLabelIn="EXIBIR FILTROS AVANÇADOS" buttonLabelOut="OCULTAR FILTROS AVANÇADOS">
              <StyledMainContent>
                <StyledField width="250px" zIndex="10">
                  <Label>Não comprou no período</Label>
                  <DatePicker name="notBuyPeriod" register={register} height="38px" />
                </StyledField>
                <StyledField zIndex="4" width="250px">
                  <Label htmlFor="paymentType">Tipo do pagamento</Label>
                  <Select
                    name="payment-type"
                    inputId="paymentType"
                    options={paymentsOptions}
                    value={payment}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder="Selecione"
                    onChange={(value) => setPayment(value)}
                  />
                </StyledField>
                <StyledField width="200px">
                  <Label>Ticket médio</Label>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', }}>
                    <Input type="number" max='10000' name="averageTicketStart" testId="average-ticket-start" placeholder="De" register={register} width="48%" />
                    <Input type="number" max='10000' name="averageTicketEnd" testId="average-ticket-end" placeholder="Até" register={register} width="48%" />
                  </div>
                </StyledField>
                <StyledField width="250px" hidden="true">
                  <Label>Faixa etária</Label>
                  <RangeSlider name="ageGroup" initialValues={[0, 90]} register={register} min={0} max={90} />
                </StyledField>
                <StyledField width="200px">
                  <Checkbox name="onlyValidPhone" register={register} label="Exibir apenas com telefone" />
                </StyledField>
              </StyledMainContent>
            </Collapse>
          </StyledFilters>
        </form>
        <DataGrid width="100%" height="unset" maxHeight="calc(100vh - 320px)" style={{ marginTop: '15px' }} >
          {customersData.length > 0 && (
            <SelectAllBar
              onClickCheckAll={handleSelectAllCheckClick}
              checkBoxLabel={`${customersData.length} de ${totalRows} contatos selecionados`}
              onClickCenterLabel={handleRemoveAllChecked}
              centerLabelText={`Remover a seleção de todos os contatos deste filtro`}
            />
          )}
          {customerState.data.length > 0 && (
            <>
              <colgroup>
                <col style={{ width: '4%' }} />
                <col style={{ width: '20%', minWidth: '120px' }} />
                <col style={{ width: '10%', minWidth: '90px' }} />
                <col style={{ width: '10%', minWidth: '100px' }} />
                <col style={{ width: '15%', minWidth: '150px' }} />
                <col style={{ width: '10%', minWidth: '100px' }} />
                <col style={{ width: '10%', minWidth: '130px' }} />
                <col style={{ width: '7%', minWidth: '100px' }} />
                <col style={{ width: '7%', minWidth: '90px' }} />
                <col style={{ width: '10%', minWidth: '120px' }} />
              </colgroup>
              <thead style={{ textAlign: 'left', position: 'sticky', backgroundColor: '#FFF', top: '0' }}>
                <tr>
                  <th></th>
                  <th>Nome</th>
                  <th>CPF</th>
                  <th>Telefone</th>
                  <th>E-mail</th>
                  <th>Filial</th>
                  <th>Vendedor</th>
                  <th>Ticket médio</th>
                  <th>não compra a</th>
                  <th>Ação</th>
                </tr>
              </thead>
            </>
          )}
          <tbody>
            {customerState.loading ? skeletonLoadTable() : tableBody()}
          </tbody>
        </DataGrid>
        <StyledRow align="center">
          <Text>{`Mostrando ${totalRows >= (currentPage * (pageSize.value || 10)) ? (currentPage * (pageSize.value || 10)) : totalRows} de ${totalRows} resultados`}</Text>
          <Pagination totalCount={totalPages} pageSize={pageSize} onPageChange={(page) => handleSetPage(page)} currentPage={currentPage} />
          <StyledSelectContainer>
            <Select name="pageSize" inputId="pageSize" menuPlacement='auto' options={sizeOptions} value={pageSize} onChange={(value) => handleSetPageSize(value)} />
          </StyledSelectContainer>
        </StyledRow>
        {openModal && (
          <ModalSendContact onphase={customersData.length > 0 ? 0 : 2} customersData={customersData} onCloseCallback={() => CloseModal(false)} isCreate />
        )}
        {openModalCustomer && (
          <ModalNewContact onCloseCallback={() => CloseModal(false)} isCreate />
        )}
      </StyledContainer>
    </>
  );
}

Customers.propTypes = {
};

Customers.defaultProps = {
};
