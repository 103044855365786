import arrowDown from 'assets/icons/arrow-down.svg';
import arrowUp from 'assets/icons/arrow-up.svg';
import {StyledIcon, StyledTopic, StyledBody, StyledCollapseButton, StyledContainer} from 'components/Collapse/styles';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import theme from 'styles/theme';


const Collapse = ({
  children,
  buttonLabelIn,
  buttonLabelOut,
  backgroundColor,
  childrenWidth,
  childrenHeight,
  buttonWidth,
  buttonHeight,
  ...rest
}) => {
    
    const [isVisible, setIsVisible] = useState(false);
    
    const turnVisible = () => {
        setIsVisible(!isVisible);
    };
    
    return (
      <StyledContainer backgroundColor={backgroundColor} {...rest}>
        <StyledBody
          childrenHeight={childrenHeight}
          childrenWidth={childrenWidth}
          visible={isVisible}
        >
          {children}
        </StyledBody>
        
        <StyledCollapseButton
          buttonWidth={buttonWidth}
          buttonHeight={buttonHeight}
          onClick={() => turnVisible()}
        >
          <StyledTopic visible={!isVisible}>
            {buttonLabelIn}
            <StyledIcon src={arrowDown} />
          </StyledTopic>
          <StyledTopic visible={isVisible}>
            {buttonLabelOut}
            <StyledIcon src={arrowUp} />
          </StyledTopic>
        </StyledCollapseButton>
      </StyledContainer>
    );
};

Collapse.propTypes = {
    children: PropTypes.node.isRequired,
    buttonLabelIn: PropTypes.string,
    buttonLabelOut: PropTypes.string,
    backgroundColor: PropTypes.string,
    childrenWidth: PropTypes.string,
    childrenHeight: PropTypes.string,
    buttonWidth: PropTypes.string,
    buttonHeight: PropTypes.string,
};

Collapse.defaultProps = {
    buttonLabelIn: 'EXIBIR',
    buttonLabelOut: 'OCULTAR',
    backgroundColor: theme.colors.white,
    childrenHeight: 'auto',
    childrenWidth: '100%',
    buttonWidth: '166px',
    buttonHeight: '25px'
};

export default Collapse;