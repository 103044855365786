import Button from 'components/Forms/Button';
import styled from 'styled-components';
import media from 'styled-media-query';
import theme from '../../styles/theme';

export const StyledButton = styled(Button)`
  height: 35px;
  width: ${props => props.width || '100%'};
  background-color: ${props => props.buttonColor || theme.colors.black};
  &:hover,
  &:focus {
    background-color: ${props => props.buttonColor || theme.colors.black};
  }
`;

export const StyledContainer = styled.div`
  max-width: 1450px;
  height: auto;
  margin: 0 auto;
  @media (max-width: 890px) {
    width: 100%;
  }
`;

export const StyledCategoriesSelected = styled.div`
  width: 100%;
  max-height: 280px;
  overflow: auto;
`;

export const StyledMobileInputPage = styled.div`
  display: ${props => (props.open ? 'inline' : 'none')};
  position: fixed;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;  
  top: 0;
  left: 0;
  @media (min-width: 890px) {
    display: none;
  }
`;

export const StyledProductList = styled.ul`
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  list-style: none;
  position: relative;
  height: auto;
  align-items: center;
  margin-top: 50px;
  justify-content: center;

  @media (max-width: 1500px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (max-width: 780px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledColors = styled.div`
  width: 100%;
  height: auto;
  overflow: auto;
  padding: 5px;
  margin-top: 5px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 4px;
  overflow-y: auto;
  overflow-x: hidden;
  @media (min-width: 1100px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: 890px) {
    grid-template-columns: repeat(10, 1fr);
  }

  @media (max-width: 610px) {
    grid-template-columns: repeat(8, 1fr);
  }
  @media (max-width: 409px) {
    grid-template-columns: repeat(5, 1fr);
  }
`;

export const StyledColorSelected = styled.button`
  height: 35px;
  width: 35px;
  border: 1px solid ${props => (props.active ? '#20CA67' : '#CECECE')};
  background-color: ${props => props.backgroundColor};
  border-radius: 100%;
  cursor: pointer;
`;

export const StyledSizes = styled.div`
  width: 100%;
  max-height: 480px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px;
  margin-top: 5px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3px;
  @media (min-width: 1100px) {
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 3px;
  }
  @media (max-width: 890px) {
    grid-template-columns: repeat(12, 1fr);
  }

  @media (max-width: 610px) {
    grid-template-columns: repeat(8, 1fr);
  }

  @media (max-width: 409px) {
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 3px;
  }
`;

export const StyledSizeSelected = styled.button`
  width: 30px;
  height: 30px;
  text-align: center;
  background-color: ${props => (props.active ? '#20CA67' : '#fff')};
  color: ${props => (props.active ? '#fff' : '#000')};
  border: 1px solid #9b9b9b;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 3px;
  cursor: pointer;

  span {
    font-size: 10px;
    line-height: 24px;
  }
`;

export const StyledCategories = styled.div`
  width: 100%;
  max-height: 207px;
  padding: 5px;
  margin-top: 5px;
  text-align: left;

  display: flex;
  flex-direction: column;
`;

export const StyledPrices = styled.div`
  width: 100%;
  height: auto;
  overflow: auto;
  padding: 5px;
  margin-top: 5px;
`;

export const StyledListPrices = styled.div`
  width: 100%;
  height: auto;
  padding: 0px 5px;

  align-items: center;
`;

export const StyledVideoSection = styled.div`
  width: 100%;
  height: 250px;
  @media (max-width: 601) {
    height: 150px;
  }
  margin-top: 15px;
  margin-bottom: 30px;
`;

export const StyledPromotion = styled.div`
  width: 100%;
  height: 20px;
  background-color: #000;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 890px) {
    flex-direction: column;
    justify-content: unset;
    min-width: unset;
    width: 100%;
  }
`;

export const StyledText = styled.div`
  max-height: 200px;
  overflow: auto;
  margin-top: 10px;
  font-size: ${theme.fontSize._14px};
  color: ${props => (props.active ? '#20CA67' : theme.colors.black55)};
  font-weight: 300;
  text-align: 'left';
  margin-bottom: 5px;
  :hover {
    cursor: pointer;
    color: #20ca67;
    font-size: ${theme.fontSize._16px};
  }

  &:visited {
    color: #20ca67;
  }
`;

export const StyledBodyCatalog = styled.ul`
  display: grid;
  list-style: none;
  padding-top: 10px;
  margin-left: 20px;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  @media (max-width: 1160px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 890px) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 25px;
    margin-left: unset;
  }
  @media (max-width: 878px) {
    justify-items: center;
    /* align-items: center; */
    grid-template-columns: repeat(2, 1fr);
    /* grid-column-gap: 55px; */
  }

  @media (max-width: 753px) {
    justify-content: center;
    /* align-items: center; */
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ContainerLoader = styled.div`
  /* margin-left: 290px; */
  right: 50%;
  left: 50%;
  padding-top: 150px;

  @media (max-width: 753px) {
    margin-left: 20px;
  }
  @media (max-width: 653px) {
    position: relative;
    left: -170px;
  }
`;

export const StyledExpandMobile = styled.div`
  display: none;  
  @media (max-width: 890px) {
    display: inline;
  }
`;

export const StyledExpandDesktop = styled.div`
  display: inline;
  width: ${props => props.width};
  @media (max-width: 890px) {
    display: none;
  }
`;

export const StyledBodyFilters = styled.div`
  display: flex;
  width: 300px;
  flex-flow: row wrap;
  height: 100%;
  padding: 0px 20px 0px 0px;
  margin-bottom: 20px;
  @media (max-width: 890px) {
    width: 100%;
    padding: 0;
  }
`;

export const StyledFixedFilters = styled.div`
  width: inherit;
  background-color: ${theme.colors.white};
`;

export const StyledAlignVertically = styled.div`
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;

  ${media.greaterThan('small')`
    flex: 0 0 80%;
  `}

  @media (max-width: 890px) {
    margin: 2px 10px;
  }
`;

export const StyledPage = styled(StyledAlignVertically)`
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 20px;
  display: flex;
  padding-top: 15px;
  @media (max-width: 400px) {
    margin-left: 0px;
    margin-right: 0px;
  }
  @media (max-width: 890px) {
    padding-top: 0px;
  }
`;

export const StyledFilterText = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  width:50%;
  padding: 0px 0px 15px 5px;
  font-weight: 300;
  font-size: ${theme.fontSize._10px};
  @media (max-width: 890px) {
    width: 100%;
    padding: 5px 5px 5px 0px;
  }
  /* @media (max-width: 500px) {
    width: 100%;
    padding: 5px 5px 5px 0px;
  } */
`;

export const StyledNotFoundProdText = styled.div`
  padding: 15px 15px 15px 15px;
  font-weight: 300;
  font-size: ${theme.fontSize._10px};
  color: ${theme.colors.black55};
  @media (min-width: 630px) {
    width: 600px;
  }
`;

export const StyledFilterMarck = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px 3px 3px 3px;
  padding: 1px 3px 1px 1px;
  border-radius: 4px;
  background-color: ${theme.colors.green};
  div {
    margin-right: 5px;
    cursor: pointer;
    color: ${theme.colors.black85};
  }
`;

export const StyledFilterContainer = styled.div`
  position: sticky;
  top: 50px;
  padding-left: 10px;
  width: 100%;
  z-index: 900;
  background-color: ${theme.colors.white};
  ${media.greaterThan('small')`
    flex: 0 0 20%;
  `};
  @media (max-width: 400px) {    
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const StyledFilterMItens = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 12px;
`;

export const StyledFilterM = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 890px) {
  flex-direction: column;
  justify-content: flex-start;  
  }
  width:100%;
`;
export const StyledItens = styled.div`
  border-right: 1px solid;
  width: 150px;
  margin-right:5px;
   @media (max-width: 890px) {
    width:100%;
    border-right: unset;
  }
`;
