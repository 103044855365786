import Pagination from 'components/Forms/Pagination/index';
import PropTypes from 'prop-types';
import React from 'react';
import { Row, Col } from 'react-grid-system';

const PaginatorFooter = ({
  totalPages,
  pageIndex,
  onPagerChange,
  register,
}) => {
  return (
    <Row align="center" style={{ paddingTop: '70px' }}>
      <Col md={12}>
        <Pagination
          testId="customer-pagination"
          register={register}
          name="pageIndex"
          totalPages={totalPages}
          pageIndex={pageIndex}
          onChange={onPagerChange}
        />
      </Col>
    </Row>
  );
};

PaginatorFooter.propTypes = {
  register: PropTypes.func.isRequired,
  totalPages: PropTypes.number,
  pageIndex: PropTypes.number,
  onPagerChange: PropTypes.func.isRequired,
};

PaginatorFooter.defaultProps = {
  totalPages: 1,
  pageIndex: 1,
};

export default PaginatorFooter;
