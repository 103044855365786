import styled, { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    
    * {
      margin: 0;
      padding: 0;
      outline: 0;
      box-sizing: border-box;
      
      scrollbar-color: #E0E0E0 #F9F9F9;
      scrollbar-width: thin;
      -moz-outline-style: none;
    }
    
    *::-webkit-scrollbar {
      width: 8px;
      height: 5px;
    }
    
    *::-webkit-scrollbar-track {
      background: #f9f9f9;
    }
    
    *::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 8px;
    }

    html, body, #root {
      min-height: 100%;
    }

    body {
      font-family: 'Open Sans', sans-serif;
      font-size: ${props => props.theme.fontSize._16px}rem;
      -webkit-font-smooth: always;
      -webkit-font-smoothing: antialiased !important;
    }
`;

export const StyledFlexContainer = styled.div`
  display: flex;
  align-items: ${(props) => props.align};
  justify-content: ${(props) => props.justify};
  flex-direction: ${(props) => props.direction};
  flex-wrap: ${(props) => props.wrap};

  .message-buttons{
    display: flex;
    margin-left: auto;
  }
`;