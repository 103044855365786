export const colorValues = [
    {name:'Abobora',value:'#ff4500'},
    {name:'AÇO',value:'#b0c4de'},
    {name:'Amarelo',value:'#ffff00'},
    {name:'Amarelo Canario',value:'#FFFF00'},
    {name:'Amarelo Claro',value:'#ffff75'},
    {name:'Amarelo Creme',value:'#FFFACD'},
    {name:'Amarelo Escuro',value:'#ffff00'},
    {name:'Amarelo Mascavo',value:'#DAA520'},
    {name:'Amarelo Medio',value:'#ffff00'},
    {name:'Amarelo Queimado',value:'#DAA520'},
    {name:'Amarelo Sunshine',value:'#FFFF00'},
    {name:'Amarelo Vibrante',value:'#FFD700'},
    {name:'Amendoa',value:'#DEB887'},
    {name:'Anoitecer',value:'#4682B4'},
    {name:'Areia',value:'#eee8aa'},
    {name:'ASTRAL',value:'#3A7CA7'},
    {name:'Azul',value:'#000080'},
    {name:'AZUL',value:'#1D5DEC'},
    {name:'Azul Anoitecer',value:'#1E90FF'},
    {name:'Azul Bic',value:'#000073'},
    {name:'Azul c- Amarelo',value:'#000080'},
    {name:'Azul c- Laranja',value:'#000080'},
    {name:'Azul c- Marrom',value:'#000080'},
    {name:'Azul Caribe',value:'#000080'},
    {name:'Azul c-Branco',value:'#000080'},
    {name:'Azul Claro',value:'#add8e6'},
    {name:'Azul Claro c- Areia',value:'#add8e6'},
    {name:'Azul Claro c-Branco',value:'#add8e6'},
    {name:'Azul c-Turquesa',value:'#000080'},
    {name:'Azul c-Verde',value:'#000080'},
    {name:'Azul Denim',value:'#4682B4'},
    {name:'Azul Escuro',value:'#104e8b'},
    {name:'Azul Escuro',value:'#104e8b'},
    {name:'Azul Forte',value:'#000080'},
    {name:'Azul Indigo',value:'#2d7891'},
    {name:'Azul Jeans',value:'#2d7891'},
    {name:'Azul Jeans c- Marinho',value:'#2d7891'},
    {name:'Azul Marinho',value:'#23238E'},
    {name:'Azul Medio',value:'#0000cd'},
    {name:'Azul Mescla',value:'#708090'},
    {name:'Azul Monaco',value:'#4169E1'},
    {name:'Azul Nostalgia',value:'#6495ED'},
    {name:'Azul Old Navy',value:'#1E90FF'},
    {name:'Azul Pacifico',value:'#000080'},
    {name:'Azul Pastel',value:'#87CEFA'},
    {name:'Azul Petroleo',value:'#416f6f'},
    {name:'Azul Piscina',value:'#00ffff'},
    {name:'Azul Royal',value:'#0000CD'},
    {name:'Azul Turquesa',value:'#40e0d0'},
    {name:'Azul Young Blue',value:'#6495ED'},
    {name:'AZUL/MARFIN',value:'#1D5DEC'},
    {name:'Azul-Cinza',value:'#708090'},
    {name:'Bege',value:'#efe891'},
    {name:'Bege Claro',value:'#F5DEB3'},
    {name:'Beringela',value:'#8b3a62'},
    {name:'Beterraba',value:'#8b1c62'},
    {name:'BIC',value:'#0d214f'},
    {name:'Blue',value:'#000080'},
    {name:'Blush',value:'#eacac2'},
    {name:'Bordeaux',value:'#500805'},
    {name:'Bordo',value:'#800000'},
    {name:'Branco',value:'#ffffff'},
    {name:'Branco c- Preto',value:'#ffffff'},
    {name:'Branco c-Areia',value:'#D1C7B8'},
    {name:'Branco c-Azul',value:'#ffffff'},
    {name:'Branco c-Azul Claro',value:'#ffffff'},
    {name:'Branco c-Azul Medio',value:'#000057'},
    {name:'Branco c-Cinza',value:'#ffffff'},
    {name:'Branco c-Marinho',value:'#ffffff'},
    {name:'Branco c-Rosa',value:'#ffffff'},
    {name:'Branco c-Turquesa',value:'#ffffff'},
    {name:'Branco c-Vermelho',value:'#7d0948'},
    {name:'Branco c-Vinho',value:'#ffffff'},
    {name:'Cafe',value:'#3c2a1e'},
    {name:'Cafe c- Bege',value:'#3c2a1e'},
    {name:'Camel',value:'#af824a'},
    {name:'Camurca',value:'#D2B48C'},
    {name:'Canjica',value:'#F0E68C'},
    {name:'Caqui',value:'#cdc673'},
    {name:'Caqui Escuro',value:'#8b864e'},
    {name:'Caramelo',value:'#af824a'},
    {name:'Carbono',value:'#000050'},
    {name:'CASTANHO',value:'#d2b48c'},
    {name:'Castor',value:'#9d7542'},
    {name:'Cereja',value:'#de3163'},
    {name:'Champanhe ',value:'#FFE4C4'},
    {name:'Charuto',value:'#674d2b'},
    {name:'Chocolate',value:'#803f12'},
    {name:'Chumbo',value:'#666666'},
    {name:'Chumbo',value:'#666666'},
    {name:'Chumbo c- Amarelo',value:'#666666'},
    {name:'Chumbo-Verde',value:'#666666'},
    {name:'Cinza',value:'#999999'},
    {name:'Cinza c- Bege',value:'#999999'},
    {name:'Cinza c- Marinho',value:'#999999'},
    {name:'Cinza c- Petroleo',value:'#999999'},
    {name:'Cinza c- Rosa Escuro',value:'#999999'},
    {name:'Cinza c-Azul',value:'#999999'},
    {name:'Cinza c-Branco',value:'#999999'},
    {name:'Cinza c-Laranja',value:'#999999'},
    {name:'Cinza Claro',value:'#cccccc'},
    {name:'Cinza c-Rosa',value:'#999999'},
    {name:'Cinza c-Verde Claro',value:'#999999'},
    {name:'Cinza c-Vinho',value:'#999999'},
    {name:'Cinza Escuro',value:'#6e6e6e'},
    {name:'Cinza Medio',value:'#999999'},
    {name:'Cinza Mescla',value:'#bebebe'},
    {name:'Cinza Rato',value:'#8b795e'},
    {name:'Coral',value:'#ff7f50'},
    {name:'Cru',value:'#fffacd'},
    {name:'Denim Claro',value:'#87CEFA'},
    {name:'Denim Escuro',value:'#4682B4'},
    {name:'Dourado',value:'#daa520'},
    {name:'DOURADO',value:'#daa520'},
    {name:'Ferrugem',value:'#cd6600'},
    {name:'Framboesa',value:'#b20949'},
    {name:'Fucsia',value:'#FF00FF'},
    {name:'Gelo',value:'#f0ffff'},
    {name:'Goiaba',value:'#cd5555'},
    {name:'Grafite',value:'#292929'},
    {name:'GRAFITE',value:'#251607'},
    {name:'Grafite Mescla',value:'#292929'},
    {name:'Jade',value:'#00a86b'},
    {name:'Jeans',value:'#778899'},
    {name:'JEANS',value:'#7caac6'},
    {name:'Kiwi',value:'#d3e7c1'},
    {name:'Laranja',value:'#ff8c00'},
    {name:'Laranja Alvorada',value:'#FF7F50'},
    {name:'Laranja Aquarela',value:'#FFA07A'},
    {name:'Laranja c-Cinza',value:'#ff8c00'},
    {name:'Laranja Claro',value:'#ff8c00'},
    {name:'Laranja Escuro',value:'#ee7600'},
    {name:'Laranja Folha',value:'#FF8C00'},
    {name:'Laranja Medio',value:'#ff7f00'},
    {name:'Laranja Shell',value:'#FFE4B5'},
    {name:'Lavanda',value:'#eee0e5'},
    {name:'Lilas',value:'#ee82ee'},
    {name:'Lilas Escuro',value:'#ee82ee'},
    {name:'LIRIS',value:' #ffa500'},
    {name:'Magenta',value:'#DC143C'},
    {name:'Marca Texto',value:'#FF4500'},
    {name:'MARFIN',value:' #ecdbb6'},
    {name:'Marinha c-Turquesa',value:'#000057'},
    {name:'Marinho',value:'#000057'},
    {name:'Marinho',value:'#000057'},
    {name:'Marinho c- Verde',value:'#000057'},
    {name:'Marinho C/ Bege',value:'#00008B'},
    {name:'Marinho c-Cafe',value:'#000057'},
    {name:'Marinho c-Marrom',value:'#000057'},
    {name:'Marinho c-Rosa',value:'#000057'},
    {name:'Marinho c-Turquesa',value:'#000057'},
    {name:'Marinho c-Vermelho',value:'#000057'},
    {name:'Marinho c-Vinho',value:'#000057'},
    {name:'Marinho Escuro',value:'#000057'},
    {name:'MARINHO/PINK',value:'#FFC0CB'},
    {name:'MARINHO/ROMANCE',value:'#23238E'},
    {name:'Marinho-Amarelo',value:'#000057'},
    {name:'Marinho-Azul Claro',value:'#000057'},
    {name:'Marinho-Caqui',value:'#000057'},
    {name:'Marrom',value:'#674d2b'},
    {name:'Marrom c-Azul',value:'#674d2b'},
    {name:'Marrom Claro',value:'#674d2b'},
    {name:'Marrom c-Preto',value:'#674d2b'},
    {name:'Mel',value:'#cd8500'},
    {name:'Melancia',value:'#fb7969'},
    {name:'Melao',value:'#caff70'},
    {name:'Menta',value:'#97d5b1'},
    {name:'Mente',value:'#a9ffd4'},
    {name:'Mescla',value:'#778899'},
    {name:'MESCLA',value:'#dcdcdc'},
    {name:'Mescla Escuro',value:'#363636'},
    {name:'Mescla Gelo',value:'#FFFFE0'},
    {name:'Mescla White',value:'#D3D3D3'},
    {name:'Mostarda',value:'#ffcb0c'},
    {name:'NATURAL',value:'#E3BC9A'},
    {name:'Navy',value:'#000080'},
    {name:'NUDE',value:'#e3bc9a'},
    {name:'Off White',value:'#f5f5dc'},
    {name:'Off White c- Chumbo',value:'#f5f5dc'},
    {name:'Ouro',value:'#daa520'},
    {name:'PARADISE',value:'#e63e62'},
    {name:'Pessego',value:'#ffdab9'},
    {name:'Petroleo',value:'#778899'},
    {name:'Pink',value:'#ff69b4'},
    {name:'Pistache',value:'#93c572'},
    {name:'PO DE ARROZ',value:'f5f5dc '},
    {name:'Prata',value:'#c0c0c0'},
    {name:'Prata c- Azul',value:'#c0c0c0'},
    {name:'Prata c- Verde',value:'#c0c0c0'},
    {name:'Prata c- Vermelho',value:'#c0c0c0'},
    {name:'Preto',value:'#000000'},
    {name:'Preto c- Caqui',value:'#000000'},
    {name:'Preto c- Chumbo',value:'#000000'},
    {name:'Preto c- Vinho',value:'#000000'},
    {name:'Preto c-Azul',value:'#000000'},
    {name:'Preto c-Marinho',value:'#000000'},
    {name:'Preto c-Marrom',value:'#000000'},
    {name:'Preto c-Off White',value:'#000000'},
    {name:'Preto c-Verde',value:'494649'},
    {name:'Preto Mescla',value:'#000000'},
    {name:'PRETO/DIVINO',value:'#FFFFFF'},
    {name:'PRETO/PINK',value:'#FFC0CB'},
    {name:'PRETO/PRATA',value:'#c0c0c0'},
    {name:'PRETO/ROMANCE',value:'#FFFFFF'},
    {name:'PRETO/VERMELHO',value:'#FF0000'},
    {name:'Preto-Cafe',value:'#000000'},
    {name:'Purpura',value:'#800080'},
    {name:'ROMANCE',value:'#E9606C'},
    {name:'Rosa',value:'#ff69b4'},
    {name:'Rosa Antigo',value:'#cd919e'},
    {name:'Rosa Bebe',value:'#ffc0cb'},
    {name:'Rosa Cha',value:'#FFB6C1'},
    {name:'Rosa Choque',value:'#FF1493'},
    {name:'Rosa Claro',value:'#ffc0cb'},
    {name:'Rosa Confetti',value:'#DB7093'},
    {name:'Rosa Coral',value:'#F08080'},
    {name:'Rosa Cupcake',value:'#FFC0CB'},
    {name:'Rosa Escuro',value:'#cd6090'},
    {name:'Rosa Goiaba',value:'#FF6347'},
    {name:'Rosa Iogurte',value:'#FF69B4'},
    {name:'Rosa Iorgurte',value:'#FF69B4'},
    {name:'Rosa Medio',value:'#ff69b4'},
    {name:'Rosa Paris',value:'#FFB6C1'},
    {name:'Rosa Pastel',value:'#FFC0CB'},
    {name:'Rosa Pessego',value:'#FFB6C1'},
    {name:'Rosa Sensaçao',value:'#FF69B4'},
    {name:'Rosa Soft',value:'#FFB6C1'},
    {name:'Rose',value:'#eea2ad'},
    {name:'Rose Charlotte',value:'#BC8F8F'},
    {name:'Rose Gold',value:'#e7b2a4'},
    {name:'Roxo',value:'#800080'},
    {name:'Roxo Claro',value:'#ee82ee'},
    {name:'Roxo Escuro',value:'#800080'},
    {name:'Royal',value:'#3a5fcd'},
    {name:'RUBRO/MARFIN',value:'#ff6961'},
    {name:'Salmao',value:'#ff8c69'},
    {name:'SALMON',value:'#FA8072'},
    {name:'SATIN',value:'#ECD9C9'},
    {name:'Solar',value:'#f49e12'},
    {name:'TABACO',value:'#684f3c'},
    {name:'Tartaruga',value:'#90ee90'},
    {name:'TERRA',value:'#e2725b'},
    {name:'Terracota',value:'#D2691E'},
    {name:'Tiffany Claro',value:'#7FFFD4'},
    {name:'Turmalina',value:'#DB7093'},
    {name:'Turquesa',value:'#40e0d0'},
    {name:'ULTRA PINK',value:'#FF6FFF'},
    {name:'Uva',value:'#7d0948'},
    {name:'VANILA',value:'#F3E5AB'},
    {name:'Verde',value:'#006600'},
    {name:'Verde Acizentado',value:'#8FBC8F'},
    {name:'Verde Agua',value:'#9fffc9'},
    {name:'Verde Alface',value:'#e0ffa5'},
    {name:'Verde Bali',value:'#8FBC8F'},
    {name:'Verde Bambu',value:'#556B2F'},
    {name:'Verde Bandeira',value:'#00681a'},
    {name:'Verde Candy',value:'#98FB98'},
    {name:'Verde Capri',value:'#5F9EA0'},
    {name:'Verde C-Azul',value:'#006600'},
    {name:'Verde Cha',value:'#8FBC8F'},
    {name:'Verde Claro',value:'#90ee90'},
    {name:'Verde Escuro',value:'#177245'},
    {name:'Verde Esmeralda',value:'#319b54'},
    {name:'Verde Folha',value:'#228b22'},
    {name:'Verde Garrafa',value:'#228b22'},
    {name:'Verde Italia',value:'#008000'},
    {name:'Verde Jade',value:'#00a817'},
    {name:'Verde Medio',value:'#006600'},
    {name:'Verde Menta',value:'#8FBC8F'},
    {name:'Verde Militar',value:'#4d4d00'},
    {name:'Verde Musgo',value:'#445a16'},
    {name:'Verde Oliva',value:'#BDB76B'},
    {name:'Verde Petroleo',value:'#2F4F4F'},
    {name:'Verde Turquesa',value:'#40E0D0'},
    {name:'Verde-Marinho',value:'#006600'},
    {name:'Vermelho',value:'#ee0000'},
    {name:'VERMELHO',value:'#ee0000'},
    {name:'Vermelho c- Marinho',value:'#ee0000'},
    {name:'Vermelho Claro',value:'#ff0000'},
    {name:'Vermelho Escuro',value:'#cd0000'},
    {name:'Vermelho Pimenta',value:'#F08080'},
    {name:'Vermelho Rubi',value:'#B22222'},
    {name:'VERMELHO/BRANCO',value:'#FF0000'},
    {name:'VERMELHO/MARFIN',value:'#FF0000'},
    {name:'Vinho',value:'#700000'},
    {name:'Vinho c- Chumbo',value:'#700000'},
    {name:'Vinho c- Cinza',value:'#700000'},
    {name:'Vinho Claro',value:'#8b0000'},
    {name:'Vinho-Petroleo',value:'#700000'},
    {name:'Violeta',value:'#8b2252'}
];
