import styled from 'styled-components';

export const CheckboxLabelComponent = styled.label`
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin: 0px;
  cursor: pointer;
  font-size: ${props => props.theme.fontSize._14px}rem;
  color: ${props => props.labelColor};
  &.settings-checkout {
    font-size: ${props => props.theme.fontSize._14px}rem;
  }
`;

export const CheckboxComponent = styled.input`
  display: none;

  & + ${CheckboxLabelComponent}:before {
    content: '';
    background: #fff;
    border-radius: 2px;
    border: 1px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
    width: 14px;
    height: 14px;
    padding: 1px;
    margin-right: 10px;
  }

  &:checked + ${CheckboxLabelComponent}:after {
    content: '';
    padding: 1px;
    position: absolute;
    width: 3px;
    height: 8px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    border-color: ${props =>props.invertColor ? props.color : props.theme.colors.white};
    transform: rotate(45deg);
    top: 2px;
    left: 5.8px;
  }

  &:checked + ${CheckboxLabelComponent}:before {
    background-color: ${props => props.invertColor ? props.theme.colors.white : props.color};
    border: 1px solid ${props => (props.invertColor ? props.theme.colors.white : props.color)};
  }
`;
