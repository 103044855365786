import Modal from 'components/Modals/Modal/index';
import Text from 'components/Typografy/Text/index';
import { StyledContainer, StyledButton, SelectContainer, StyledLoading } from 'Layout/SelectStoreModal/styles';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import * as CustomersServices from 'services/customers-services';
import * as SellersServices from 'services/sellers-services';
import smartSalesSessionStorage, { storageKeys } from 'storage/smart-sales-session-storage';
import theme from 'styles/theme';

const SelectStoreModal = ({
  onCloseCallback
}) => {
  const [storeIndex, setStoreIndex] = useState(0);
  const [sellerIndex, setSellerIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSellers, setIsLoadingSellers] = useState(true);
  const [stores, setStores] = useState([]);
  const [sellers, setSellers] = useState([]);

  const getStores = async () => {
    setIsLoading(true);
    const response = await CustomersServices.getStore();
    setStores(response.data.payload);
    handleSelectStore(storeIndex, response.data.payload);
    setIsLoading(false);
  };

  const getSellers = async (store, storesResponse) => {
    setIsLoadingSellers(true);
    if (store !== undefined) {
      const response = await SellersServices.getSellersLogin(store.storeCode);
      setSellers(response.data.payload);
      setIsLoadingSellers(false);
    } else if (storesResponse !== undefined && storesResponse !== null) {
      const response = await SellersServices.getSellersLogin(storesResponse[0].storeCode);
      setSellers(response.data.payload);
      setIsLoadingSellers(false);
    }
    setIsLoadingSellers(false);
  };

  useEffect(() => {
    getStores();
  }, []);

  const handleSelectStore = (currentIndex, storesResponse) => {
    setStoreIndex(currentIndex);
    getSellers(stores[currentIndex], storesResponse);
  };

  const handleSelectSeller = (id) => {
    setSellerIndex(sellers?.findIndex((element) => element.id.toString() === id));
  }

  const handleClick = () => {
    smartSalesSessionStorage.setItem(storageKeys.STORE, stores[storeIndex]);
    smartSalesSessionStorage.setItem(storageKeys.SELLER, sellers[sellerIndex]);

    onCloseCallback();
    window.location.reload(false);
  };

  const handleCloseButton = () => {
    if (smartSalesSessionStorage.getItem(storageKeys.STORE) && smartSalesSessionStorage.getItem(storageKeys.SELLER)) {
      return true;
    }
    return false;
  }

  return (
    <Modal showCloseButton={handleCloseButton()} width="90vw" maxWidth="360px" height="max-content" maxHeight='90vh' padding="0px" onCloseCallback={onCloseCallback} isVisible>
      {isLoading ? (
        <StyledLoading>
          <Loader type="Oval" color={theme.colors.lightGreen} height={40} width={40} timeout={0} />
          <Text fontSize="18px">Carregando</Text>
        </StyledLoading>
      ) : (
        <StyledContainer>
          <Text fontSize="18px" marginBottom="5px">Selecione a Loja</Text>
          <SelectContainer value={storeIndex} onChange={e => handleSelectStore(e.target.value)}>
            {stores &&
              stores.map((item, index) => (
                <option key={item.portalUrl} value={index}>
                  {item.name}
                </option>
              ))}
          </SelectContainer>
          <Text fontSize="18px" marginTop="10px" marginBottom="5px">Selecione um(a) vendedor(a)</Text>
          <SelectContainer disabled={isLoadingSellers} onChange={e => handleSelectSeller(e.target.value)}>
            {sellers &&
              sellers.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
          </SelectContainer>
          <br />
          <StyledButton disabled={!(stores.length && sellers.length)} onClick={handleClick}>
            <Text fontSize={theme.fontSize._10px} cursor="pointer" color="#fff">
              CONTINUAR
            </Text>
          </StyledButton>
        </StyledContainer>
      )}
    </Modal>
  );
};

SelectStoreModal.propTypes = {
  onCloseCallback: PropTypes.func
};

SelectStoreModal.defaultProps = {
  onCloseCallback: () => null
};

export default SelectStoreModal;
