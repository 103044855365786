import SmartLogoFooter from 'assets/images/linx-footer.png';
import PagarmeLogoFooter from 'assets/images/pagarme.svg';
import StoneLogoFooter from 'assets/images/stone.png';
import PropTypes from 'prop-types';
import React from 'react';
import theme from '../../styles/theme';
import Text from '../Typografy/Text';
import {
  FooterStyledComponent,
  StyledContent,
  StyledIcon,
  StyledImage,
  StyledImageStone,
  StyledImagePagarme,
  StyledImageLinx,
} from './styles';

const Footer = ({ backgroundColor, logo, footerMessage, bagColor, ...rest }) => {
  return (
    <FooterStyledComponent backgroundColor={backgroundColor}      {...rest}    >
      <StyledContent>
        <Text fontSize={theme.fontSize._12px} color={bagColor || "white"} className="text">
          {footerMessage}
        </Text>
      </StyledContent>
      <div className='social-media'>
        <StyledIcon>
          <StyledImage alt="logo" src={logo || SmartLogoFooter} />
        </StyledIcon>
        <StyledIcon href="https://www.linx.com.br/" >
          <StyledImageLinx alt="logo" src={SmartLogoFooter} />
        </StyledIcon>
        <StyledIcon href="https://www.stone.com.br/" >
          <StyledImageStone alt="logo" src={StoneLogoFooter} />
        </StyledIcon>
        <StyledIcon href="https://pagar.me/" >
          <StyledImagePagarme alt="logo" src={PagarmeLogoFooter} />
        </StyledIcon>
      </div >
    </FooterStyledComponent>
  );
};

Footer.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  backgroundColor: PropTypes.string,
  logo: PropTypes.string,
  footerMessage: PropTypes.string,
  bagColor: PropTypes.string,
};

Footer.defaultProps = {
  width: '100%',
  height: '76px',
  backgroundColor: 'black',
  logo: '',
  footerMessage: ''
};

export default Footer;
