import warningIcon from 'assets/icons/warning.svg';
import defaultProduct from 'assets/images/default-product.png';
import Modal from 'components/Modals/Modal';
import Text from 'components/Typografy/Text';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Container } from 'react-grid-system';
import { mask } from 'remask';
import { salesConfig } from 'services/contact-service';
import * as RequestContext from 'services/requests-services';
import { StyledFlexContainer } from 'styles/global';
import theme from 'styles/theme';

import ModalButton from '../ModalButton';
import {
  StyldContainer,
  StyledDetails,
  StyledTitle,
  StyledCards,
  StyledDivCards,
  StyledTable,
  StyledThead,
  StyledTableBody,
  StyledDetailsCli,
  StyledPrintButton,
  StyledCancelButton,
  StyledFooter,
  StyledConfirmButton,
  StyledCancelModal,
  StyledAbortModal
} from './styles';

const DetailsModal = ({ onCloseCallback, pedido }) => {
  const [color, setColor] = useState();
  const [modalidades, setModalidade] = useState();
  const [productDetails, setProductDetails] = useState();
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [statusState, setStatusState] = useState(pedido?.status);
  const [seller, setSeller] = useState(pedido?.sellerName);
  const [config, setConfig] = useState();

  const getConfig = async () => {
    const response = await salesConfig();
    setConfig(response?.data?.payload)
  }

  useEffect(() => {
    getConfig();
  }, []);

  const getProducts = async () => {
    const response = await RequestContext.getRequestsProducts(pedido?.id);
    setProductDetails(response?.data?.payload);
  };

  useEffect(() => {
    getProducts();

    switch (statusState) {
      case 'Cancelado':
        setColor('#E12121');
        break;
      case 'Pedido realizado':
        setColor('#429B4A');
        break;
      case 'Saída para entrega':
        setColor('#692B82');
        break;
      case 'Pedido pago':
        setColor('#00CD98');
        break;
      case 'Aguardando validação':
        setColor('#F6B900');
        break;
      case 'Separação em estoque':
        setColor('#4B88AB');
        break;
      case 'Pedido finalizado':
        setColor('#000');
        break;
      default:
        setColor('#828282');
        break;
    }

    switch (pedido?.orderType) {
      case 1:
        setModalidade('Entrega');
        break;
      case 2:
        setModalidade('Retirada na loja');
        break;
      default:
        setModalidade('');
        break;
    }
  }, [statusState, pedido]);

  return (
    <Modal
      showCloseButton
      minWidth="330px"
      width="100%"
      maxWidth="900px"
      height="600px"
      maxHeight="754px"
      padding={5}
      onCloseCallback={onCloseCallback}
      isVisible
      style={{ margin: '0px 20px' }}
      overflow="hidden"
    >
      <Container style={{ maxWidth: '100%', padding: '0' }} fluid xs sm md lg xl>
        <StyldContainer>
          <StyledTitle>
            <Text fontSize={theme.fontSize._16px} color="#828282" textAlign="left">
              Pedido #{pedido?.id} - Detalhes do pedido
            </Text>
            <Text fontSize={theme.fontSize._10px} color="#828282" textAlign="left" fontWeight={700}>
              {`Pedido realizado em ${moment(pedido?.date).format('DD/MM/YYYY')} às ${moment(pedido?.date).format('LT')}`}
            </Text>
          </StyledTitle>
          <StyledDetails>
            <StyledDivCards>
              <StyledCards md={5.5} direction="column">
                <Text fontSize={theme.fontSize._12px} color="#828282" textAlign="left">
                  Pedido
                </Text>
                <Text fontSize={theme.fontSize._12px} color="#828282" textAlign="left" fontWeight={600}>
                  {pedido?.reference}
                </Text>
              </StyledCards>
              <StyledCards md={5.5} direction="column">
                <Text fontSize={theme.fontSize._12px} color="#828282" textAlign="left">
                  Forma de entrega
                </Text>
                <Text fontSize={theme.fontSize._12px} color="#555" textAlign="left" fontWeight={600} >
                  {pedido?.shippingName}
                </Text>
              </StyledCards>
            </StyledDivCards>
            <StyledDivCards>
              <StyledCards md={5.5} direction="row">
                <StyledFlexContainer direction="column" justify="center" style={{ width: '100%' }}>
                  <Text fontSize={theme.fontSize._12px} color="#828282" textAlign="left"  >
                    Status
                  </Text>
                  <Text fontSize={theme.fontSize._12px} color={color || '#828282;'} textAlign="left" fontWeight={600}>
                    {statusState}
                  </Text>
                </StyledFlexContainer>
                {config?.changeOrderStatus && (<ModalButton onChange={(value) => setStatusState(value)} requestData={pedido?.id} type="status" label="ALTERAR" />)}
              </StyledCards>
              <StyledCards md={5.5} direction="row">
                <StyledFlexContainer direction="column" justify="center" style={{ width: '100%' }}>
                  <Text fontSize={theme.fontSize._12px} color="#828282" textAlign="left">
                    Vendedor
                  </Text>
                  <Text fontSize={theme.fontSize._12px} textAlign="left" fontWeight={600} >
                    {seller || 'Nenhum'}
                  </Text>
                </StyledFlexContainer>
                {config?.changeSeller && (<ModalButton onChange={(value) => setSeller(value)} requestData={pedido?.id} type="seller" label="ALTERAR" />)}
              </StyledCards>
            </StyledDivCards>
            <StyledTable>
              <colgroup>
                <col style={{ width: '15%' }} />
                <col style={{ width: '45%' }} />
                <col style={{ width: '20%' }} />
                <col style={{ width: '20%' }} />
              </colgroup>
              <StyledThead>
                <tr style={{ height: '30px', padding: '0px 15px' }}>
                  <th colSpan="2">Produto</th>
                  <th>Quantidade</th>
                  <th>Valor</th>
                </tr>
              </StyledThead>
              <StyledTableBody>
                {productDetails &&
                  productDetails.orderItens.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <img src={item?.image || defaultProduct} alt="imagem do produto" />
                      </td>
                      <td>
                        {item?.name}
                        <br />
                        {`Cor: ${item?.color}`}
                        <br />
                        {`Tamanho: ${item?.size}`}
                        <br />
                        {item?.sku}
                        <br />
                      </td>
                      <td>{item?.quantity}</td>
                      <td>
                        {Intl.NumberFormat('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        }).format(item?.netValue)}
                      </td>
                    </tr>
                  ))}
                <tr className="valores">
                  <td colSpan="3">Subtotal</td>
                  <td>
                    {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', }).format(productDetails?.orderGrossValue || 0)}
                  </td>
                </tr>
                <tr className="valores">
                  <td colSpan="3">Frete {pedido?.shippingName}</td>
                  <td>
                    {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', }).format(productDetails?.shippingAmmount || 0)}
                  </td>
                </tr>
                <tr className="valores">
                  <td colSpan="3">Descontos</td>
                  <td>
                    {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', }).format(productDetails?.discount || 0)}
                  </td> 
                </tr>
                <tr className="total">
                  <td colSpan="3">Total</td>
                  <td>
                    {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', }).format(productDetails?.orderNetValue || 0)}
                  </td>
                </tr>
                <tr className="pagtamento">
                  <td colSpan="3" rowSpan="4" style={{ verticalAlign: 'top', padding: '10px 0px' }} >
                    Método de pagamento
                  </td>
                </tr>
                <tr className="valores">
                  <td colSpan="4">{productDetails?.paymentType}</td>
                </tr>
                <tr className="valores">
                  <td colSpan="4">{productDetails?.cardNumber}</td>
                </tr>
                <tr className="valores">
                  <td colSpan="4">
                    {`${productDetails?.installments || 0}x de `}
                    {Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', }).format(productDetails?.orderNetValue / productDetails?.installments || 0,)}
                  </td>
                </tr>
              </StyledTableBody>
            </StyledTable>
            <StyledTitle>
              <Text fontSize={theme.fontSize._16px} color="#828282" textAlign="left" >
                Detalhes do Cliente e de Entrega
              </Text>
            </StyledTitle>
            <StyledDetailsCli>
              <StyledFlexContainer direction="column" style={{ width: '50%' }}>
                <Text fontSize={theme.fontSize._10px} color="#828282" textAlign="left" fontWeight="bold">
                  Detalhes do Cliente
                </Text>
                <Text fontSize={theme.fontSize._10px} color="#828282" textAlign="left" >
                  {pedido?.customerName}
                </Text>
                <Text fontSize={theme.fontSize._10px} color="#828282" textAlign="left">
                  {pedido?.email}
                </Text>
                <Text fontSize={theme.fontSize._10px} color="#828282" textAlign="left">
                  {mask(pedido?.phone, ['99 99999-9999', '+99 99 99999-9999',]) || '-'}
                </Text>
                <Text fontSize={theme.fontSize._10px} color="#828282" textAlign="left" >
                  CPF
                  {' '}
                  {mask(pedido?.cpf, ['999.999.999-99']) || '-'}
                </Text>
                {/* <Text
                  fontSize={theme.fontSize._10px}
                  color="#828282"
                  textAlign="left"
                >
                  RG 
                  {' '}
                  {pedido?.rg ?? mask(pedido.rg, ['99.999.999-9'])}
                </Text> */}
              </StyledFlexContainer>
              <StyledFlexContainer direction="column">
                <Text fontSize={theme.fontSize._10px} color="#828282" textAlign="left" fontWeight="bold" >
                  Detalhes da Entrega
                </Text>
                <Text fontSize={theme.fontSize._10px} color="#828282" textAlign="left" >
                  CEP
                  {' '}
                  {mask(pedido?.cep, ['99999-999']) || '-'}
                </Text>
                <Text fontSize={theme.fontSize._10px} color="#828282" textAlign="left" >
                  {pedido?.address} - Número: {pedido?.addressNumber} - Complemento: {pedido?.complement}
                </Text>
                <Text fontSize={theme.fontSize._10px} color="#828282" textAlign="left" >
                  {pedido?.district}
                </Text>
                <Text fontSize={theme.fontSize._10px} color="#828282" textAlign="left">
                  {`${pedido?.city}/ ${pedido?.state}`}
                </Text>
              </StyledFlexContainer>
            </StyledDetailsCli>
            {/* <StyledFooter>
              <StyledCancelButton isVisible={false} onClick={() => setOpenCancelModal(true)}>
                CANCELAR PEDIDO
              </StyledCancelButton>
            </StyledFooter>
            {openCancelModal && (
              <Modal
                showCloseButton
                width="300px"
                maxWidth="300px"
                height="280px"
                padding={30}
                onCloseCallback={() => setOpenCancelModal(false)}
                isVisible
                overflow="none"
              >
                <StyledCancelModal>
                  <img src={warningIcon} alt="Icone" />
                  <p>Atenção! Você deseja realmente excluir este pedido e extornar o pagamento ao cliente?</p>
                  <StyledConfirmButton>CONTINUAR CANCELAMENTO</StyledConfirmButton>
                  <StyledAbortModal onClick={() => setOpenCancelModal}>VOLTAR</StyledAbortModal>
                </StyledCancelModal>
              </Modal>
            )} */}
          </StyledDetails>
        </StyldContainer>
      </Container>
    </Modal>
  );
};

DetailsModal.propTypes = {
  onCloseCallback: PropTypes.func,
  pedido: PropTypes.any,
};

DetailsModal.defaultProps = {
  onCloseCallback: () => null,
  pedido: {},
};

export default DetailsModal;
