import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 3000;
  background-color: #411E5A;
  height: 100%;
  width: ${(props) => props.appearance ? '220px' : '60px'};
  transition: width 100ms ease-out;

  border-right: 1px solid ${props => props.theme.colors.black20};
`;

export const StyledHead = styled.div`
  top: 0;
  height: 70px;
  width: 100%;
  padding: 7px 0;
  border-bottom: 1px solid ${props => props.theme.colors.black20};
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledNavLinkTo = styled(NavLink)`
  ${({ theme }) => css`
    width: 100%;
    height: 55px;
    background-color: none;
    margin: 8px 0;
    text-decoration: none;
    color: #FFF;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.isLast {
      margin-top: auto;
    }

    &.selected {
      background-color: rgba(173, 111, 253, 0.15);
      border-left: #FFB200 5px solid;
      border-radius: 0px 50px 50px 0px;

      img {
        margin-right: 5px;
      }
    }
  `}
`;

export const StyledIcons = styled.img`
  padding: 5px 0px;
  margin: 0 15px;
  width: 45px;
  height: 35px;
`;

export const StyledSideMark = styled.div`
  position: absolute;
  left: 0px;
  display: none;

  height: 13%;
  width: 5px;

  border-radius: 0px 10px 10px 0px;
  background-color: ${props => props.theme.colors.principal};

  display: ${props => (props.actived ? 'block' : 'none')};
`;

export const StyledTitle = styled.h3`
  font-size: 13px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  width: 100%;
  margin: 0 10px;
  transition: all 100ms ease-out;
`;

export const StyledFirstTitle = styled(StyledTitle)`
  font-size: 20px;
  white-space: nowrap;
  font-weight: 400;
  color: #616161;
`;

export const StyledSidebarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: calc(100% - 70px);
`;
