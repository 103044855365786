import styled from 'styled-components';


export const StyledTr = styled.tr`
  cursor: pointer;

  :hover {
    background-color: #F1F1F1;
  }
`;

export const StyledButton = styled.button`
  margin-right: 10px;
  cursor: pointer;
  border: none;
  background-color: #3D7981;
  color: white;
  border-radius: 4px;
  padding: 5px 18px;
  font-size: 11px;
  font-weight: 500;

  :hover {
    opacity: 0.8;
  }
`;
