import { call, put } from 'redux-saga/effects';

import api from '../../../../services/api';
import { Types as ProductsTypes } from '../../../ducks/Catalog/Products';
import smartSalesSessionStorage, { storageKeys,} from 'storage/smart-sales-session-storage';

export function* getProducts(action) {
  try {
    const { term, page, categoryId } = action.payload;
    const store = smartSalesSessionStorage.getItem(storageKeys.STORE);

    const response = yield call(api.get, 'product/search?hasNext=true', {
      params: {
        term,
        pageIndex: page,
        categoryId,
        StoreCode: store?.storeCode
      },
    });
    if (response.status === 200) {
      yield put({
        type: ProductsTypes.GET_PRODUCTS_SUCCESS,
        data: response.data.payload,
        pager: response.data.pager,
      });
    }
  } catch (error) {
    yield put({
      type: ProductsTypes.GET_PRODUCTS_ERROR,
      error,
    });
  }
}
