import styled from 'styled-components';

export const StyledButton = styled.button`
  width: 94px;
  height: 35px;
  border: none;
  background-color: #3D7981;
  border-radius: 4px;
  justify-items: center;
  padding: 4px 18px;
  margin: 10px 0;
  cursor: pointer;
  align-self: flex-end;

  &:disabled {
    opacity: 0.7;
    cursor: unset;
  }
`;

export const StyledTransparentButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const StyledFooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: max-content;
  padding: 12px;
  align-items: center;
  background-color: #EAF5FB;
`;

export const StyledFields = styled.div`
  width: 100%;
  max-width: 750px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px 10px 10px 10px;   
`;


export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;

  &.wrap {
    @media (max-width: 500px) {
      flex-direction: column;
      .last-field {
        margin-right: 0;
      }
    }
  }

  .no-wrap {
    width: 100%;;
  }
`;

export const StyledExternalCircle = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid #CACACA;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
  cursor: pointer;
`;

export const StyledType = styled.p`
  color: #828282;
  margin-right: 15px;
  font-size: 13px;
`;

export const StyledInternalCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${(props) => props.color || '#37D0B5'} ;
`;

export const StyledLabel = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-right: 10px;
  margin-left: 10px;

  &.no-margin {
    margin-right: 0;
  }

  p {
    color: #828282;
    margin-bottom: 5px;
  }
`;

export const StyledInput = styled.input`
  padding: 10px;
  width: 100%;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
`;
