/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  StyledInput,
  StyledButton,
  StyledContainer,
} from './styles';

function Input({ value, setValue, disabled, onClick, label, buttonName, placeholder }) {

  const settings = useSelector(state => state.Setting.data);

  return (
    <StyledContainer>
      <label htmlFor="discount">
        {label}
        <StyledInput placeholder={placeholder} onChange={setValue} value={value} id="discount" />
      </label>
      <StyledButton style={{ backgroundColor: settings.buttonColor }} type="button" disabled={disabled} onClick={() => onClick()}>
        {buttonName}
      </StyledButton>
    </StyledContainer>
  );
};

Input.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  setValue: PropTypes.func,
  onClick: PropTypes.func,
  label: PropTypes.string,
  buttonName: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
};

Input.defaultProps = {
  value: '',
  setValue: () => { },
  onClick: () => { },
  label: '',
  buttonName: 'CONFIRMAR',
  placeholder: '',
  disabled: false
};

export default Input;
