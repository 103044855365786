import styled from 'styled-components';


export const StyledMainContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 15px 15px 15px;

  .isolade-field {
    width: calc(33% + 5px);
  }
`;

export const StyledTitle = styled.h1`
  font-weight: normal;
  font-size: 16px;
  color: #616161;
  padding: 20px 0 10px 0;
`;

export const StyledRowContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;

  @media screen and (max-width: 850px) {
    flex-wrap: wrap;
  }
`;

export const StyledRowContainerFlex = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding-top: 20px;

  @media screen and (max-width: 850px) {
    flex-wrap: wrap;
  }
`;

export const StyledFlexTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const StyledLog = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  flex-direction: row;
  cursor: pointer;
  color: #3D7981;

  :hover {
    text-decoration: underline;
  }

  img {
    margin-left: 10px;
    margin-top: 3px;
    width: 12px;
  }
`

export const StyledSubmitButton = styled.button`
  background-color: #3D7981;
  color: white;
  border: none;
  border-radius: 4px;
  width: 145px;
  height: 35px;
  font-size: 12px;
  cursor: pointer;

  :hover {
    opacity: 0.7;
  }

  :disabled {
    opacity: 0.5;
    cursor: unset;
  }
`;

export const StyledChanges = styled.p`
  background-color: #FFEADA;
  color: #BE563F;
  padding: 10px;
  width: calc(33% - 4px);
  font-size: 12px;
`;

export const StyledFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding-bottom: 20px;
`;

export const StyledBannerImage = styled.img`
  width: 1000px;
  height: 400px;
  object-fit: scale-down;
`;

export const StyledLogoImage = styled.img`
  width: 400px;
  height: 400px;
  object-fit: scale-down;
`;

export const StyledLabel = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 10px;
  padding-bottom: 0;
  padding-left: 0;
`;
