import ReactSlider from 'react-slider'
import styled from 'styled-components';
import theme from 'styles/theme';

export const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 7px;
`;

export const DetailThumb = styled.div`
    /*
    height:10px;
    width:10px;
    background-color: #3D7981;
    border-radius: 100%;
    position: absolute;
    top: 20%;
    left: 24%;
    */
`;

export const Value = styled.h5`
    position: absolute;
    top: 15px;
    left:-4px;
    font-weight:600;
    text-align:center;
    color: #3D7981;
`;

export const StyledThumb = styled.div`
    position: relative;
    height: 18px;
    line-height: 18px;
    width: 18px;
    background-color: #F5F5F5;
    border: 5px solid #3D7981;
    color: #fff;
    border-radius: 100%;
    cursor: grab;
    top:-7px;
    z-index:0;
    
    &:hover {
      box-shadow: 0 0 0 15px rgba(98,0,238,.1);
    }
`;

export const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    left: 25px;
    
    background-color: ${props => (props.index === 2 ? theme.colors.black20 : props.index === 1 ? theme.colors.lightGreen : theme.colors.black20)};
    
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
`;

export const StyledContainer = styled.div`
    height: 29px;
    resize: ${props =>props.resize};
    overflow: ${props => props.overflow};
    width: ${props => props.width};
    max-width: 100%;
    margin-top: 7px;
`;