import styled from 'styled-components';

export const StyledCounter = styled.div`
  display: flex;
  align-items: center;
  display: ${props => (props.sumir ? 'none' : 'inline')};
`;

export const StyledCounterButton = styled.button`
  border: unset;
  background-color: unset;
  cursor: pointer;
`;

export const StyledInput = styled.input`
  border-radius: 4px;
  border: 1px solid #ccc;
  height: 30px;
  margin: 0 10px;
  width: 50px;
  text-align: center;
`;
