import styled from 'styled-components';

export const StyledMainContent = styled.div`
  display: flex;  
  align-items: center;
  justify-content: center;
  padding: 0px;
  font-size: 16px; 

  &.success {
    span {
      color: ${(props) => props.color};
    }
  }

  &.error {
    span {
      color: #EE3434;      
    }
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  font-size: 14px;
  padding: 20px;
  text-align: center;
`;

export const StyledErroButton = styled.button`
  background-color: #CCCCCC;
  color: white;  
  border-radius: 4px;
  width: 250px;
  height: 55px;
  margin-top: 15px;
  cursor: pointer;
  font-weight: 700;
  
  &:hover {
    opacity: 0.8
  }  
`;

export const StyledSuccessButton = styled.button`
  background-color: ${(props) => props.color};
  color: white;
  border: 1px solid ${(props) => props.color};
  width: 250px;
  height: 55px;
  margin-top: 15px;
  cursor: pointer;
  font-weight: 700;
  
  &:hover {
    opacity: 0.8
  }  
`;

export const StyledOperationIcon = styled.img`
  width: 64px;
  height: 64px;
  margin-right: 15px;  
  color: ${(props) => props.color};
`;

export const StyledOrderReference = styled.p`
  color: #4F4F4F;
  font-weight: 400;
  font-size: 16px;
  margin-top: 5px;
`;
