import styled, { css } from 'styled-components';

export const StyledLogo = styled.img`
  width: auto;
  height: auto;
  max-height: 60px;
  object-fit: cover;
  cursor: pointer;
`;

export const StyledPromotion = styled.div`
  background-color: #000;
`;

export const HeaderStyleBody = styled.header`
  width: ${props => props.width};
  height: 68px;
  padding: 0 15px;
  background-color: ${props => props.backgroundColor};
  display: flex;
  align-items: center;
  margin: 0;
  z-index: 1000;
  top: 0;
  position: sticky;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #eaeaea;
`;

export const HeaderStyledComponent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

export const StyledIcons = styled.div`  
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledBag = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const StyledCount = styled.div`
  ${({ theme }) => css`
    transition: all 0.3s;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${props => props.backgroundColor || '#00cd98'};
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: -5.5px;
    margin-left: 14.5px;

    span {
      color: ${theme.colors.offWhite};
      font-size: 10px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }    
  `}
`;
