import HamburguerIcon from 'assets/icons/hamburguer.svg';
import Text from 'components/Typografy/Text/index';
import { StyledBackOfficeHeaderContainer, StyledTitle, StyledStore, StyledBurguerButton } from 'Layout/BackOfficeHeader/styles';
import SelectStoreButton from 'Layout/SelectStoreButton';
import SelectStoreModal from 'Layout/SelectStoreModal';
import PropTypes from 'prop-types';
import React, { memo, useState, useEffect } from 'react';
import smartSalesSessionStorage, { storageKeys } from 'storage/smart-sales-session-storage';
import { StyledFlexContainer } from 'styles/global';
import theme from 'styles/theme';
import { getAppName } from 'utils/app-info';
import LogoLinx from 'assets/icons/Logo_Linx.svg';
import useWindowDimensions from 'utils/hooks/getScreenSize';

const BackOfficeHeader = ({ title, handleClick }) => {

  const [storeState, setStoreState] = useState(smartSalesSessionStorage.getItem(storageKeys.STORE));
  const [sellerState, setSellerState] = useState(smartSalesSessionStorage.getItem(storageKeys.SELLER));
  const [showModalState, setShowModalState] = useState(false);
  const windowDimensions = useWindowDimensions();

  const resetShowModalStateHandler = () => {
    setShowModalState(true);
  }

  const showModal = () => (
    <SelectStoreModal onCloseCallback={resetShowModalStateHandler} />
  );

  return (
    <>
      {!storeState && (!showModalState && showModal())}
      <StyledBackOfficeHeaderContainer>
        <StyledFlexContainer direction="row" align="center" justify="space-between">
          {/* <StyledBurguerButton type="button" onClick={() => handleClick()}>
              <img src={HamburguerIcon} alt="botão hamburguer" />
            </StyledBurguerButton> */}
          <img src={LogoLinx} alt="Logo linx" />
          {windowDimensions.width >= 570 ? (
            <StyledTitle>
              <Text fontSize={theme.fontSize._20px} color={theme.colors.black70} fontWeight="normal" textAlign="center">
                {`/ ${title}`}
              </Text>
            </StyledTitle>) : (<></>)
          }
        </StyledFlexContainer>
        <StyledStore>
          {windowDimensions.width >= 570 ? (<>
            <Text fontSize={theme.fontSize._13px}>
              Loja:
              {storeState?.name && (
                <Text fontSize={theme.fontSize._13px} fontWeight="600">
                  {storeState.name}
                </Text>
              )}
            </Text>
            <Text fontSize={theme.fontSize._13px}>
              Vendedor(a):
              {sellerState?.name && (
                <Text fontSize={theme.fontSize._13px} fontWeight="600">
                  {sellerState.name}
                </Text>
              )}
            </Text></>) : (<>
              {sellerState?.name && (
                <Text fontSize={theme.fontSize._13px} fontWeight="600">
                  {sellerState.name}
                </Text>
              )}
            </>)
          }
          <SelectStoreButton onStatusChangeCallBack={status => setStoreState(status)} label="TROCAR" style={{ float: 'right', width: '80px' }} />
        </StyledStore>
      </StyledBackOfficeHeaderContainer>
    </>
  );
};

BackOfficeHeader.propTypes = {
  title: PropTypes.string,
  handleClick: PropTypes.func
};

BackOfficeHeader.defaultProps = {
  title: getAppName(),
  handleClick: () => { }
};

export default memo(BackOfficeHeader);
