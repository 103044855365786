import Button from 'components/Forms/Button';
import TextArea from 'components/Forms/TextArea/index';
import Modal from 'components/Modals/Modal';
import Toast from 'components/Toast';
import Text from 'components/Typografy/Text/index';
import ButtonOption from 'components/ButtonOption';
import newcatalog from 'assets/icons/newcatalog.svg';
import updatecatalog from 'assets/icons/updatecatalog.svg';
import CatalogItemCard from 'pages/Customers/CreateCatalogModal/CatalogItemCard';
import CatalogSendInputText from 'pages/Customers/CreateCatalogModal/CatalogSendInputText';
import CatalogSendButton from 'pages/Customers/CreateCatalogModal/StyledCatalogSendButton';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-grid-system';
import { FaWhatsapp } from 'react-icons/fa';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import { createDispatchHook, useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  TelegramIcon,
} from "react-share";
import * as CatalogServices from 'services/catalog-services';
import { createCatalog, postCustomerReceived, UpdateCatalogCustomers } from 'services/catalog-services';
import * as ProductsServices from 'services/products-service';
import smartSalesSessionStorage, { storageKeys } from "storage/smart-sales-session-storage";
import { StyledFlexContainer } from 'styles/global';
import theme from 'styles/theme';
import AllProducts from '../../../assets/icons/AllProducts.svg';
import Box from '../../../assets/icons/box.svg';
import Pencil from '../../../assets/icons/pencil.svg';
import products, { Types as ProductsTypes } from '../../../store/ducks/Catalog/Products';
import {
  Footer,
  StyledBox,
  StyledCard,
  StyledBody,
  StyledButton,
  StyledSuport,
  StyledCheckBoxArea,
  StyledAreaAllProductsSelected,
  StyledCheckBoxAllProducts,
  StyledHeaderContainer,
  StyledTitleContainer,
  StyledSearchResult,
  StyledNumberProducts,
  StyledFooterContainer,
  StyledTransparentButton,
  StyledListProducts,
  StyledSelectedProductsUpdate,
  StyledListProductsUpdate,
  StyledTitleInput,
  StyledLabel,
  StyledSelectedProducts,
  StyledNextCampaign,
  StyledTransparentFoorter,
  StyledPromocodeContainer,
  StyledSelectContainer,
  StyledPromocodeDescription,
  StyledApplyCampaign,
  StyledDescriptionRow,
  StyledSubtitle,
} from './styles';

const ModalSendContact = ({ onCloseCallback, isLoading, customersData, associetedCampaign, selectedItems, setSelectedItems, isCreate, handleUpdateCatalog, onphase }) => {
  const dispatch = useDispatch();
  const [showTooltip, setShowTooltip] = useState(false);
  const [selectedCatalog, setSelectedCatalog] = useState([]);
  const [store, setStore] = useState(smartSalesSessionStorage.getItem(storageKeys.STORE));
  const [toastInfo, setToastInfo] = useState();
  const [phase, setPhase] = useState(0);
  const [sendedUsers, setSendedUsers] = useState([]);
  const [catalogId, setCatalogId] = useState();
  const [catalogOptions, setCatalogsOptions] = useState([]);
  const [customers, setCustomers] = useState(null);
  const [statusCheckBox, setStatusCheckBox] = useState(false);
  const [text, setText] = useState('*Olá*, [nome_usuario] sou [seller] %0a%0aSeparamos ofertas especiais pra você, da uma olhada nessa lista: %0a%0a[lista_produtos] Mas não esquece que as ofertas são por tempo limitado ou até acabarem nossos estoques. %0a%0aEntão corre lá e confere o link [link_catalogo]');

  const [copied, setCopied] = useState(false);
  const [category, setCategory] = useState('');
  const [title, setTitle] = useState('Catálogo de produtos');
  const [categories, setCategories] = useState([]);
  const sellerId = smartSalesSessionStorage.getItem(storageKeys.SELLER);
  const [hasReshop, setHasReshop] = useState(false);
  const [modalWidth, setModalWidth] = useState('80%');

  const [promocodeOptions, setPromocodeOptions] = useState([
    {
      id: 1,
      name: 'Campanha_remarketing_40off_femJeans',
      description: 'Campanha de e-mail enviada para as clientes que compraram calça jeans entre dezembro de 2021 e fevereiro de 2022',
      promocode: 'MEUJEANSCOM4OFF'
    },
    {
      id: 2,
      name: 'Campanha_remarketing_50off_femJeans',
      description: 'Campanha de e-mail enviada para as clientes que compraram calça jeans entre dezembro de 2021 e fevereiro de 2022',
      promocode: 'MEUJEANSCOM5OFF'
    }
  ]);

  const [promocode, setPromocode] = useState();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [term, setTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const ProductsState = useSelector(state => state.Products);

  const [customersAdd, setCustomersAdd] = useState(0);
  const [customersExist, setCustomersExist] = useState(0);
  const [customersAll, setCustomersAll] = useState(0);
  const [sellerName, setSellerName] = useState();
  const [descriptionCatalogInfo, setDescriptionCatalogInfo] = useState("");

  useEffect(() => {
    if (selectedItems.length) {
      setSelectedCatalog(selectedItems);
    }
  }, [selectedItems]);

  useEffect(() => {
    setPromocode(associetedCampaign);
  }, [associetedCampaign])

  useEffect(() => {
    setSelectedItems(selectedCatalog);
  }, [selectedCatalog]);

  useEffect(() => {
    setFilteredProducts([...filteredProducts, ...ProductsState.data]);
  }, [smartSalesSessionStorage, ProductsState]);

  const getCatalogsData = async () => {
    const response = await CatalogServices.getCatalogsEnum({
      store: store?.storeCode
    });
    setCatalogsOptions([...response?.data?.payload])
  }
  const getCategories = async () => {
    const response = await ProductsServices.getCategories();
    setCategories([{ name: 'Todos', id: '' }, ...response?.data?.payload]);
  }

  useEffect(() => {
    getCategories();
    SetOnPhase(onphase)
  }, []);

  function SetOnPhase(tophase) {
    if (tophase == 0)
      setModalWidth('30%')
    else
      setModalWidth('100%')

    if (tophase == 1) {
      setCustomersAdd(customersData);
      getCatalogsData();
    }

    if (tophase == 2) {
      if (!hasReshop) {
        tophase = 3;
      }
    }

    setPhase(tophase);
  }

  const handleCategory = (value) => {
    setShowTooltip(true);
    setFilteredProducts([]);
    setPage(1);
    setCategory(value);
  }

  const handleSearch = event => {
    event.persist();
    setFilteredProducts([]);
    setPage(1);

    (async () => {
      try {
        if (event.target.value.length > 0) {
          setShowTooltip(true);
        } else {
          setShowTooltip(false);
        }
        setSearch(event.target.value);
      } catch (error) { }
    })();
  };

  useEffect(() => {
    if (search !== null) {
      const timeOut = setTimeout(() => setTerm(search), 1000);
      return () => clearTimeout(timeOut);
    }
    return true;
  }, [search]);

  const handleTextArea = e => {
    setText(e);
  };

  const handleAddProduct = product => {
    if (!selectedCatalog.some(x => x.id === product.id)) {
      setSelectedCatalog([...selectedCatalog, ...[product]]);
    }
  };

  const handleRemoveProduct = product => {
    setSelectedCatalog(selectedCatalog.filter(x => x.id !== product.id));
  };

  const handleUpdateCustomer = async (tophase) => {
    setLoading(true);

    try {
      const body = { customersIds: customers.map(x => x.id), catalogId: catalogId };
      const response = await UpdateCatalogCustomers(catalogId, body);
    }
    catch (error) {
      setToastInfo({ isError: true, message: 'Ocorreu um erro durante a atualização do catálogo' });
    }

    setLoading(false);
    SetOnPhase(tophase);
  };

  const handleSelectCatalogDropdown = async (value) => {
    setLoading(true);

    if (catalogId == value.id) {
      setLoading(false);
      return;
    }

    try {
      const resultCatalog = await CatalogServices.getSpecificCatalog(value.id);
      setSellerName(resultCatalog.data.payload.seller.name);
      console.log(resultCatalog.data.payload)
      setCatalogId(value.id);

      const body = {
        customersIds: customersData.map(x => x.id),
        catalogId: value.id
      };

      const response = await CatalogServices.getUpdatedCatalogCustomers(value.id, body);

      setSelectedCatalog(resultCatalog.data.payload.products);
      setDescriptionCatalogInfo(`${resultCatalog.data.payload.products?.length} Produtos neste catálogo | Criado por ${resultCatalog.data.payload?.seller?.name ?? 'loja'} dia ${moment(new Date(resultCatalog.data.payload.createdAt)).format('DD/MM/YY, HH:mm')}`)
      setCustomersAll(response.data.payload.length);
      setCustomersExist(resultCatalog.data.payload.customers?.length);
      setCustomers(response.data.payload);
    }
    catch (error) {
      setToastInfo({ isError: true, message: 'Ocorreu um erro durante a atualização do catálogo' });
    }

    setLoading(false);
  }

  const handleCreateCatalog = async () => {
    setLoading(true);

    if (statusCheckBox) {
      setCustomers(customersData);
      SetOnPhase(4);
      return;
    }
    
    try {
      const response = await createCatalog({
        sellerId: sellerId?.id || null,
        name: title,
        productsIds: selectedCatalog.map(x => x.id),
        customersIds: customersData.map(x => x.id),
        message: text
      });

      setCatalogId(response?.data?.payload);

      const customersResponse = await CatalogServices.getCatalogCustomers(response.data.payload,);
      setLoading(false);

      setCustomers(customersResponse.data.payload);
      SetOnPhase(4);
    } catch (error) {
      setLoading(false);
      setToastInfo({ isError: true, message: 'Ocorreu um erro durante a criação do catálogo' });
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    await handleUpdateCatalog(e);
    setLoading(false);
  }

  const sendMessage = async (customerId, name, telephone) => {
    setSendedUsers([...sendedUsers, customerId]);
    const link = getLink()
    const listProducts = getListProductsString();

    const whatsappMessage = text.replace('[nome_usuario]', name).replace('[seller]', sellerName).replace('[lista_produtos]', listProducts).replace('[link_catalogo]', link);
    const uri = `https://wa.me/${telephone}?text=${whatsappMessage}`;
    window.open(uri, '_blank');



    await postCustomerReceived(catalogId, customerId);
  }

  const getListProductsString = () => {

    let productsListString = ' ';
    console.log(selectedCatalog);
    const productsNames = selectedCatalog.map(({ name, basePrice }) => ({ name, basePrice }));

    productsNames.forEach((element) => {
      productsListString += '• *' + element.name + "*  por apenas: *R$: " + element.basePrice + "* ,"
    });

    console.log(productsListString);

    productsListString = productsListString.split(",").join(" %0a%0a  ")


    console.log(productsListString);

    return productsListString;
  }

  const getLink = () => {
    let link = '';
    if (statusCheckBox !== true) {
      link = `${window.location.origin}/${store?.portalUrl}/catalogo/${catalogId}?sellerId=${sellerId.id}`;
    } else {
      link = `${window.location.origin}/${store?.portalUrl}/vitrine?sellerId=${sellerId.id}`;
    }
    return link;
  }

  const handleCopyLink = () => {
    const link = getLink();
    navigator.clipboard.writeText(link);
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, [3000])
  }

  function handleCheckBoxStatus() {
    setStatusCheckBox(!statusCheckBox);
    setSelectedCatalog([]);
    setFilteredProducts([]);
  }

  useEffect(() => {
    if (term || category) {
      dispatch({
        type: ProductsTypes.GET_PRODUCTS,
        payload: {
          term,
          page,
          categoryId: category.id
        },
      });
    }
  }, [term, page, category]);

  const handleSeeMore = () => {
    setPage(page + 1);
  }

  const handleCampaign = (applyPromo = true) => {
    setPhase(1);
    if (!applyPromo) {
      setPromocode();
    }
  }

  return (
    <Modal showCloseButton height='max-content' overflow='auto' minWidth="330px" width={modalWidth} maxWidth="920px" maxHeight="754px" padding={0} isLoading={isLoading} onCloseCallback={onCloseCallback} isVisible >

      {/* Editar ou criar */}
      <StyledSuport style={{ height: 'calc(100% - 80px)', width: '50%' }} display={phase === 0}>
        <StyledBody>
          <StyledLabel style={{ justifyContent: 'center', textAlign: 'center', marginTop: '10px' }}>
            Selecione abaixo como você <br />
            deseja prosseguir
          </StyledLabel>
          <Row style={{ padding: '15px', justifyContent: 'space-around' }} >
            <ButtonOption handleChange={() => SetOnPhase(1)} title="Vincular Clientes" description="Vincular os clientes selecionados a um catálogo existente" image={updatecatalog} />
            <ButtonOption handleChange={() => SetOnPhase(2)} title="Novo Catálogo" description="Criar um novo catálogo e enviar aos clientes selecionados" image={newcatalog} />
          </Row>
        </StyledBody>
      </StyledSuport>

      {/* vincular */}
      <StyledSuport display={phase === 1}>
        <Container style={{ height: 'calc(100% - 70px)' }} fluid xs sm md lg xl>
          <StyledHeaderContainer style={{ padding: '15px' }} >
            <StyledLabel style={{ justifyContent: 'left', textAlign: 'left' }}>
              Selecione o catálogo que deseja vincular aos clientes selecionados
            </StyledLabel>
          </StyledHeaderContainer>
          <Row style={{ justifyContent: 'space-between', marginTop: '10px', marginLeft: '25px' }} >
            <StyledLabel md={4} disabled='true' style={{ width: '190px', fontSize: '12px' }}>
              {`Selecionados: ${customersAdd.length}`}
            </StyledLabel>
            +
            <StyledLabel md={4} disabled='true' style={{ width: '190px', fontSize: '12px' }}>
              {`Catálogo: ${customersExist}`}
            </StyledLabel>
            -
            <StyledLabel md={1} disabled='true' style={{ width: '190px', fontSize: '12px' }}>
              {`Duplicados: ${catalogId ? (customersAdd.length + customersExist) - customersAll : 0}`}
            </StyledLabel>
            =
            <StyledLabel md={4} disabled='true' style={{ width: '190px', fontSize: '12px' }}>
              {`Final: ${customersAll}`}
            </StyledLabel>
          </Row>
          <Row style={{ justifyContent: 'space-evenly', marginTop: '20px', borderRadius: '0 0 4px 4px', }} >
            <div style={{ align: 'center', height: 'max-content', width: '93%' }}>
              <Select zIndex="11000"
                name="customer-seller"
                inputId="customerSeller"
                options={catalogOptions}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                placeholder="Selecione o catálogo"
                onChange={(value) => handleSelectCatalogDropdown(value)} />
            </div>
          </Row>
          <Row style={{ justifyContent: 'initial', marginLeft: '20px', marginTop: '10px' }}>
            <StyledLabel style={{ justifyContent: 'left', textAlign: 'left', fontSize: '10px' }}>
              {descriptionCatalogInfo}
            </StyledLabel>
          </Row>
          <Row style={{ justifyContent: 'center' }}>
            {selectedCatalog.length > 0 ?
              (
                <StyledSelectedProductsUpdate>
                  <StyledListProductsUpdate>
                    {selectedCatalog.map(item => (<CatalogItemCard key={item.id} product={item} disableButton="true" />))}
                  </StyledListProductsUpdate>
                </StyledSelectedProductsUpdate >
              ) :
              (
                <StyledListProducts style={{ alignItems: 'center' }} >
                  <img src={Box} alt="Icone para nenhum produto adicionado" />
                  <p>Selecione para ver os produtos do catálogo</p>
                </StyledListProducts>
              )}
            <StyledFooterContainer>
              <StyledTransparentButton onClick={onCloseCallback}>Cancelar</StyledTransparentButton>
              <CatalogSendButton onClick={() => handleUpdateCustomer(4)} text="VINCULAR" loading={loading} />
            </StyledFooterContainer>
            {toastInfo?.message && (<Toast type={toastInfo.isError ? 'error' : 'success'} message={toastInfo.message} position="bottom-center" onClose={() => setToastInfo(null)} />)}
          </Row>
        </Container>
      </StyledSuport>

      {/* Campanha */}
      <StyledSuport display={phase === 2}>
        <Container style={{ height: 'calc(100% - 70px)' }} fluid xs sm md lg xl>
          <form>
            <StyledPromocodeContainer>
              <h2>
                Selecione abaixo a campanha promocional que será vinculada à este catálogo
              </h2>
              <StyledSelectContainer>
                <Select
                  name="promocode"
                  inputId="promocode"
                  req
                  options={promocodeOptions}
                  value={promocode}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder="Selecione"
                  onChange={(value) => setPromocode(value)}
                />
                <p>Obs: Serão exibidas aqui apenas campanhas de PromoCode</p>
              </StyledSelectContainer>
              <StyledPromocodeDescription>
                {promocode?.description ? (
                  <StyledFlexContainer direction="column">
                    <StyledDescriptionRow>
                      <span>Nome:</span>
                      <p>{promocode?.name}</p>
                    </StyledDescriptionRow>
                    <StyledDescriptionRow>
                      <span>Descrição:</span>
                      <p>{promocode?.description}</p>
                    </StyledDescriptionRow>
                    <StyledDescriptionRow>
                      <span>Cupom:</span>
                      <p>{promocode?.promocode}</p>
                    </StyledDescriptionRow>
                  </StyledFlexContainer>
                ) : (
                  'Dados da campanha: Selecione para visualizar'
                )}
              </StyledPromocodeDescription>
              <StyledApplyCampaign disabled={!promocode} type='button' onClick={() => handleCampaign()}>
                APLICAR CAMPANHA
              </StyledApplyCampaign>
            </StyledPromocodeContainer>
            <StyledTransparentFoorter>
              <StyledNextCampaign type='button' onClick={() => handleCampaign(false)}>
                PROSSEGUIR SEM VINCULAR CAMPANHA
              </StyledNextCampaign>
            </StyledTransparentFoorter>
          </form>
        </Container>
      </StyledSuport>

      {/* Seleção de produto */}
      <StyledSuport display={phase === 3}>
        <Container style={{ height: 'calc(100% - 70px)' }} fluid xs sm md lg xl>
          <Row style={{ padding: '20px', borderRadius: '4px 4px 0 0', }} >
            <Col md={12}>
              <StyledHeaderContainer>
                <StyledLabel>
                  <StyledTitleInput id="catalog-title" maxLength={100} value={title} onChange={(e) => setTitle(e.target.value)} />
                  {isCreate && (<img src={Pencil} alt="icone de editar" />)}
                </StyledLabel>
                {customersData.length > 0 && (
                  <StyledSubtitle>
                    {`${customersData.length} contatos selecionados ${promocode?.name ? `| Campanha aplicada: ${promocode?.name}` : ''}`}
                  </StyledSubtitle>
                )}
              </StyledHeaderContainer>

            </Col>
          </Row>
          <Row align="stretch">
            <Col style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyItems: 'center', padding: '0px', placeItems: 'unset', height: '460px' }} md={12}>
              <div style={{ width: '100%', height: '100%', paddingLeft: '6px', maxWidth: '470px' }}>
                <StyledTitleContainer>
                  Produtos em Estoque
                  {isCreate && (
                    <StyledCheckBoxArea>
                      <StyledCheckBoxAllProducts color="#672A93" onClick={handleCheckBoxStatus} />
                      <p>Enviar link da vitrine</p>
                    </StyledCheckBoxArea>
                  )}
                </StyledTitleContainer>
                <div style={{ fontSize: '12px' }}>
                  <Select
                    name="category"
                    inputId="category"
                    options={categories}
                    value={category}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    placeholder="Todas as categorias"
                    onChange={(value) => handleCategory(value)}
                    isDisabled={statusCheckBox}
                  />
                </div>
                <div style={{ height: 'max-content', padding: '10px', backgroundColor: theme.colors.ice, borderRadius: '0 0 4px 4px', }}>
                  <CatalogSendInputText
                    disabled={statusCheckBox}
                    placeholder="Pesquise um produto"
                    onChange={(e) => handleSearch(e)}
                  />
                </div>
                {showTooltip && (
                  <StyledSearchResult>
                    <>
                      {filteredProducts.map(item => (
                        <CatalogItemCard
                          key={item.id}
                          add
                          product={item}
                          onClickAdd={handleAddProduct}
                          selected={selectedCatalog.some((elem) => elem.id === item.id)}
                        />
                      ))}
                      {ProductsState.loading ? (
                        <div className='loading-container'>
                          <Loader
                            type="Oval"
                            color={theme.colors.lightGreen}
                            height={20}
                            width={20}
                            timeout={0}
                          />
                        </div>
                      ) : (ProductsState.data.length === 11) && filteredProducts.length > 0 && (
                        <button onClick={() => handleSeeMore()} type="button">
                          Ver mais 10 produtos
                        </button>
                      )}
                    </>
                  </StyledSearchResult>
                )}
              </div>
              <StyledSelectedProducts>
                <StyledTitleContainer>Listagem de Produtos</StyledTitleContainer>
                <StyledNumberProducts>
                  {`${selectedCatalog.length} Produto${selectedCatalog.length >= 2 ? 's' : ''} adicionado${selectedCatalog.length >= 2 ? 's' : ''}`}
                </StyledNumberProducts>
                <StyledAreaAllProductsSelected style={{ display: statusCheckBox ? 'flex' : 'none' }}>
                  <img src={AllProducts} alt="" />
                  <p>Envio de<b> vitrine </b> selecionado</p>
                </StyledAreaAllProductsSelected>
                {selectedCatalog.length > 0 ? (
                  selectedCatalog.map(item => (
                    <CatalogItemCard key={item.id} product={item} onClickRemove={handleRemoveProduct} />
                  ))
                ) : !statusCheckBox && (
                  <StyledListProducts>
                    <img src={Box} alt="Icone para nenhum produto adicionado" />
                    <p>Adicione produtos ao catálogo a ser enviado</p>
                  </StyledListProducts>
                )}
              </StyledSelectedProducts>
            </Col>
            <StyledFooterContainer>
              <StyledTransparentButton onClick={onCloseCallback}>Cancelar</StyledTransparentButton>
              <CatalogSendButton
                onClick={isCreate ? () => handleCreateCatalog() : (e) => handleSubmit(e)}
                disabled={(selectedCatalog.length === 0 && !statusCheckBox) || loading}
                text={`${isCreate ? 'CRIAR CATÁLOGO' : 'SALVAR CATÁLOGO'}`}
                loading={loading}
              />
            </StyledFooterContainer>
            {toastInfo?.message && (
              <Toast type={toastInfo.isError ? 'error' : 'success'} message={toastInfo.message} position="bottom-center" onClose={() => setToastInfo(null)} />
            )}
          </Row>
        </Container>
      </StyledSuport>

      {/* Visualização de clientes */}
      <StyledSuport display={phase === 4}>
        <StyledBody>
          <Text textAlign="left" fontSize={theme.fontSize._14px} color={theme.colors.black85} fontWeight="bold" >
            {!statusCheckBox ? 'Envio do catálogo' : 'Envio de Vitrine'}
          </Text>
          <Text marginBottom="10px" textAlign="left" fontSize={theme.fontSize._10px} >
            {`${customersData.length} contatos selecionados`}
          </Text>
          <Text textAlign="left" fontWeight="bold" fontSize={theme.fontSize._10px}>Texto a ser enviado na mensagem:</Text>
          <TextArea height="70px" width="100%" type="text" value={text} onChange={e => handleTextArea(e)} />
          <Text textAlign="left" fontWeight="bold" fontSize={theme.fontSize._10px}>
            Lista de envio:
            <Text textAlign="left" fontWeight="bold" fontSize={theme.fontSize._10px} color={theme.colors.lightGreen}>
              {` ${sendedUsers.length} de ${customersAll} mensagens enviadas`}
            </Text>
          </Text>
        </StyledBody>
        <StyledBox height={customers?.length > 0 ? '170px' : '20px'}>
          {customers &&
            customers.map(customer => {
              return (<StyledCard key={customer.id} selected={sendedUsers.includes(customer.id)}>
                {customer.name}
                <StyledButton
                  icon={<FaWhatsapp color={theme.colors.white} size={10} />}
                  onClick={() => sendMessage(customer.id, customer.name, customer.phone)}>
                  ENVIAR
                </StyledButton>
              </StyledCard>
              );
            })}
        </StyledBox>
        <Footer>
          <Button backgroundColor={copied ? theme.colors.green : theme.colors.lightGreen} height="31px" width="220px" onClick={() => handleCopyLink()} >
            {copied ? 'COPIADO!' : 'COPIAR LINK DO CATÁLOGO'}
          </Button>
          <div className='social-media'>
            <FacebookShareButton style={{ display: 'flex' }} quote={title} url={getLink()}>
              <FacebookIcon size="2rem" round />
            </FacebookShareButton>
            <TelegramShareButton style={{ display: 'flex' }} quote={title} url={getLink()}>
              <TelegramIcon size="2rem" round />
            </TelegramShareButton>
            <TwitterShareButton style={{ display: 'flex' }} quote={title} url={getLink()}>
              <TwitterIcon size="2rem" round />
            </TwitterShareButton>
            <WhatsappShareButton style={{ display: 'flex' }} quote={title} url={getLink()}>
              <WhatsappIcon size="2rem" round />
            </WhatsappShareButton>
          </div>
        </Footer>
      </StyledSuport>
    </Modal>
  );
};

ModalSendContact.propTypes = {
  onCloseCallback: PropTypes.func,
  isLoading: PropTypes.bool,
  customersData: PropTypes.array,
  selectedItems: PropTypes.array,
  isCreate: PropTypes.bool,
  setSelectedItems: PropTypes.func,
  handleUpdateCatalog: PropTypes.func,
  associetedCampaign: PropTypes.object,
  onphase: PropTypes.number,
};

ModalSendContact.defaultProps = {
  onCloseCallback: () => null,
  isLoading: false,
  customersData: [],
  selectedItems: [],
  isCreate: false,
  setSelectedItems: () => { },
  handleUpdateCatalog: () => { },
  associetedCampaign: {},
  onphase: 2
};

export default ModalSendContact;
