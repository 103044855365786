import Button from 'components/Forms/Button';
import Checkbox from 'components/Forms/Checkbox';
import styled, { css } from 'styled-components';

import theme from '../../../styles/theme';

export const DivResult = styled.div`
  border-top: 1px dashed #000000;
  display: flex;
  width: 358px;
  margin: auto;
  margin-top: 10%;
  line-height: 30px;
`;

export const DivColumn = styled.div`
  padding: 10px;
  flex-grow: ${props => props.flexGrow};
  text-align: ${props => props.align};
`;

export const DivColumnTotal = styled.div`
  padding: 10px;
  flex-grow: ${props => props.flexGrow};
  text-align: ${props => props.align};
`;

export const StyledSuport = styled.div`
  display: ${props => (props.display ? 'inline' : 'none')};
`;

export const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 30px;
  height: 30%;
`;

export const Footer = styled.footer`
  /* border: 5px solid; */
  background-color: ${theme.colors.azure};
  display: flex;
  justify-content: space-between;
  bottom: 0px;
  height: 60px;
  padding: 0 20px;
  padding-top: 2%;
  width: 100%;

  .social-media {
    display: flex;
    flex-direction: row;
    width: 150px;
    justify-content: space-between;
  }
`;

export const StyledCard = styled.div`
  display: flex;
  flex-direction: row wrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  width: 92%;
  height: 50px;
  border: 1px solid;
  border-color: ${props =>
    props.selected ? theme.colors.azure : theme.colors.green};
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 24px;
  padding: 10px 20px;
  margin-bottom: 10px;
  color: ${theme.colors.black55};
  ${props =>
    props.selected &&
    css`
      button {
        background-color: ${theme.colors.black20};
      }
    `};
`;

export const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  max-height: 50%;
  height: max-content;
  width: 100%;
  padding-left: 5px;
  margin-top: 5px;
  height: ${(props) => props.height};
  overflow-y: over;
`;

export const StyledButton = styled(Button)`
  padding: 0 10px;
  background-color: ${theme.colors.green};
  height: 21px;
  width: 95px;
  font-size: 13px;
  border: none;
  font-family: Open Sans;
  font-style: normal;

  :hover, :focus {
    opacity: 0.8;
    background: ${theme.colors.green};
  }

  :disabled {
    cursor: unset;
    opacity: 1;
    background: #CCCCCC;
  }
`;

export const StyledCheckBoxArea = styled.div`
  max-width: 160px;
  background-color: #672A93;
  padding: 9px 13px;
  justify-content: left;
  align-items: center;
  border-radius: 5px;
  display: flex;
  flex-direction: row;

  p {
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    color: #ffffff;
    white-space: nowrap;
  }
`;

export const StyledCheckBoxAllProducts = styled(Checkbox)``;

export const StyledAreaAllProductsSelected = styled.div`
  background: #ffffff;
  border: 1px dashed #20ca67;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  padding: 17px;
  align-items: center;
  margin: 10px;
  flex-direction: row;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: #ffffff;
    white-space: nowrap;
    margin-left: 30px;
    color: #545454;
  }
`;

export const StyledHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  img {
    margin-left: 8px;
    cursor: pointer;
  }
`;

export const StyledTitleInput = styled.input`
  font-size: 14px;
  font-weight: 150;
  color: ${theme.colors.black85}
  font-weight: 700;
  display: flex;
  align-items: center;
  border: none;
`;

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const StyledTitleContainer = styled.p`
  height: 60px;
  padding: 0 10px;
  background-color: ${theme.colors.offWhite};
  border: 1px solid ${theme.colors.gray};
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledSearchResult = styled.div`
  width: 100%;
  height: 100%;
  max-height: 300px !important;
  padding: 0px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  background-color: ${props => props.theme.colors.white};
  font-weight: bold;
  font-size: ${props => props.theme.fontSize._8px}rem;
  color: ${props => props.theme.colors.black};

  .loading-container {
    margin: 10px;
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 10px 0  15px 0;
    color: ${theme.colors.lightGreen};
  }
`;

export const StyledNumberProducts = styled.div`
  padding: 10px 10px 0 10px;
  font-size: 12px;
  color: ${theme.colors.gray};
`;

export const StyledFooterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: max-content;
  padding: 12px;
  align-items: center;
  background-color: #EAF5FB;
`;

export const StyledTransparentButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

export const StyledListProducts = styled.div`
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 12px;

  p {
    color: ${theme.colors.lightGreen};
    width: 180px;
    text-align: center;
  }
`;

export const StyledSelectedProducts = styled.div`
  width: 100%;
  max-width: 470px;
  height: 100%;
  overflow-y: scroll;
`;

export const StyledSelectedProductsUpdate = styled.div`
  height: 360px; 
  width: 100%;
  padding: 10px;
  overflow-y: auto;
`;

export const StyledListProductsUpdate = styled.ul`  
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  list-style: none;
  position: relative;
  height: auto;  
  align-items: center;
  justify-content: center;

  @media (max-width: 1450px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 780px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledSubtitle = styled.p`
  font-size: 10px;
  color: #4F4F4F;
  font-weight: 400;
`;

export const StyledPromocodeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 40px 0;

  h2 {
    max-width: 450px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
  }
`;

export const StyledSelectContainer = styled.div`
  max-width: 400px;
  width: 100%;
  margin-top: 45px;

  p {
    margin-top: 5px;
    font-size: 11px;
    text-align: center;
  }
`;

export const StyledPromocodeDescription = styled.div`
  max-width: 450px;
  width: 100%;
  height: 120px;
  background-color: #F2F5F8;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #B9B9B9;
  border-style: dashed;
  margin: 20px 0;
  font-size: 11px;
  border-radius: 5px;
  padding: 15px;
`;

export const StyledDescriptionRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 7px;

  span {
    width: 55px;
    margin-right: 3px;
    font-weight: 600;
  }
`;

export const StyledApplyCampaign = styled.button`
  background-color: #3D7981;
  color: white;
  border-radius: 50px;
  padding: 12px;
  border: none;
  font-size: 10px;
  cursor: pointer;
  width: 150px;

  :disabled {
    opacity: 0.6;
    cursor: unset;
  }
`;

export const StyledTransparentFoorter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: max-content;
  padding: 12px;
  align-items: center;
  background-color: #FCFCFC;
`;

export const StyledNextCampaign = styled.button`
  color: #3D7981;
  border: 1px solid #3D7981;
  border-radius: 50px;
  font-size: 9px;
  padding: 12px;
  font-weight: 600;
  background-color: white;
  cursor: pointer;
`;