export const Types = {
  ADD_NSU: '@NSU/ADD_NSU',
  CLEAR_NSU: 'NSU/CLEAR_NSU',
};

const INITIAL_STATE = {
  data: 'nullNsu',
};

export default function nsu(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.ADD_NSU: {
      return {
        data: action.data,
      };
    }
    case Types.CLEAR_NSU: {
      return { data: 'nullNsu' };
    }
    default:
      return state;
  }
}
