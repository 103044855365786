const theme = {
  spacing: {
    padding10: '10px',
    padding15: '20px',
    padding18: '18px',
    padding20: '20px',
    padding30: '30px',
    padding40: '40px',
    padding50: '50px',

    margin5: '5px',
    margin10: '10px',
    margin15: '15px',
    margin18: '18px',
    margin20: '20px',
    margin30: '30px',
    margin40: '40px',
    margin50: '50px',
  },
  colors: {
    principal: '#5B2E90',
    principalDark: '#402065',
    secondary: '#c370fd',
    focus: '#3D7981',
    black: '#000000',
    black85: '#333333',
    black70: '#4F4F4F',
    black55: '#828282',
    black20: '#CCCCCC',

    white: '#FFFFFF',
    offWhite: '#FBFBFB',
    lightGray: '#DDDDDD',
    gray: '#BDBDBD',

    green: '#6FCF97',
    mediumGreen: '#34AF23',
    orange: '#F2994A',
    lightRed: '#FFE3E3',
    red: '#CF6F6F',
    redStrong: '#EE3434',
    lightGreen: '#3D7981',
    cyan: '#37D0B5',
    ice: '#E4F4FF',
    azure: '#EAF5FB',

    linxGradientTheme: 'gradient(90deg, #FF4427 0%, #FFBA00 97.87%)',
  },
  boxShadow: {
    inset: 'rgba(0, 0, 0, 0.1) 0px 0px 3px 0.1px inset',
    allSides: 'rgba(0, 0, 0, 0.2) 0px 0px 5px 0.1px',
    bottom: 'rgba(0, 0, 0, 0.2); 4px 2px -2px',
  },
  fontSize: {
    _8px: 0.5,
    _10px: 0.625,
    _12px: 0.75,
    _14px: 0.875,
    _16px: 1,
    _18px: 1.125,
    _20px: 1.25,
    _22px: 1.375,
    _24px: 1.5,
    _26px: 1.625,
    _28px: 1.75,
    _30px: 1.875,
    _32px: 2,
    _34px: 2.125,
    _36px: 2.25,
    _38px: 2.375,
    _40px: 2.5,
    _42px: 2.625,
    _44px: 2.75,
    _46px: 2.875,
    _48px: 3,
    _50px: 3.125,
  },
};

export default theme;
