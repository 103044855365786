import close from 'assets/icons/close.svg';
import Expand from 'components/Expand';
import Checkbox from 'components/Forms/Checkbox';
import Text from 'components/Typografy/Text';
import PaginatorFooter from 'pages/Showcase/PaginatorFooter';
import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { GrFormClose } from 'react-icons/gr';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as SettingsService from 'services/settings.services';
import { Types as CartTypes } from 'store/ducks/Checkout';
import { Types as NsuTypes } from 'store/ducks/Nsu';
import search from '../../assets/IconsSvg/search.svg';
import Input from '../../components/Forms/Input';
import Icon from '../../components/Icon';
import { Types as ShowcaseTypes } from '../../store/ducks/Showcase';
import { colorValues } from '../../styles/colors';
import theme from '../../styles/theme';
import Card from './Card';
import { prices } from './mock';
import {
  StyledContainer,
  StyledPage,
  StyledPromotion,
  StyledFilterMarck,
  StyledText,
  StyledBody,
  StyledFilterMItens,
  StyledFilterText,
  StyledAlignVertically,
  StyledBodyFilters,
  StyledBodyCatalog,
  StyledColors,
  StyledColorSelected,
  StyledSizeSelected,
  StyledSizes,
  StyledListPrices,
  StyledCategoriesSelected,
  ContainerLoader,
  StyledFixedFilters,
  StyledExpandMobile,
  StyledExpandDesktop,
  StyledFilterContainer,
  StyledNotFoundProdText,
  StyledMobileInputPage,
  StyledButton,
  StyledItens,
  StyledFilterM,
} from './styles';

function Vitrine({ product }) {
  const dispatch = useDispatch();
  const { register, handleSubmit, getValues } = useForm();
  // eslint-disable-next-line
  const [totalRows, setTotalRows] = useState(0);
  // eslint-disable-next-line
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [closeMInput, setCloseMInput] = useState(false);
  // eslint-disable-next-line
  const { id } = useParams();
  const [isTermo, setTermo] = useState();
  const [validTerm, setValidTerm] = useState();

  const [filter, setFilter] = useState([]);
  const [orderBy, setOrderBy] = useState();

  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [precos, setPrecos] = useState(null);
  const [onlyOnStock, setOnlyOnStock] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);

  const category = filter.filter(f => f.typeId === 99);
  const filters = filter.filter(type => type.typeId === 1 || type.typeId === 2);
  const settings = useSelector(state => state.Setting.data);
  const ShowcaseData = useSelector(state => state.Showcase);

  const orderBySelect = [
    { id: 1, name: 'Novidades', type: '' },
    { id: 2, name: 'Menor Preço', type: 'PRICE/ASC' },
    { id: 3, name: 'Maior Preço', type: 'PRICE/DESC' },
    { id: 4, name: 'Nome (A-Z)', type: 'ALPHABETIC/ASC' },
    { id: 5, name: 'Nome (Z-A)', type: 'ALPHABETIC/DESC' },
  ];
  
  useMemo(() => {
    if (!filter || !precos || !orderBy) {
      setPageIndex(1);
    }
  }, [filter, precos, orderBy]);

  useEffect(() => {
    const params = {
      filters: filters.map(x => x.value),
      priceTo: precos?.valueTo,
      priceFrom: precos?.valueFrom,
      categories: category.map(c => c.id),
      term: isTermo,
      orderBy: orderBy?.split('/')[0],
      orderDirection: orderBy?.split('/')[1],
      pageIndex,
    };

    dispatch({ type: ShowcaseTypes.GET_SHOWCASE, payload: { filter: params, }, });
    //cleanUpCart();

    document.documentElement.scrollTop = 0;
  }, [filter, precos, orderBy, pageIndex]);

  const cleanUpCart = () => {
    dispatch({ type: CartTypes.CLEAR_CART, });
    dispatch({ type: NsuTypes.CLEAR_NSU, });
  }

  function procurarPorTermo(e) {
    e.preventDefault();
    const params = {
      filters: filters.map(x => x.value),
      priceTo: precos?.valueTo,
      priceFrom: precos?.valueFrom,
      categories: category.map(c => c.id),
      term: isTermo,
      orderBy: orderBy?.split('/')[0],
      orderDirection: orderBy?.split('/')[1],
    };

    dispatch({
      type: ShowcaseTypes.GET_SHOWCASE,
      payload: {
        filter: params,
      },
    });
    setValidTerm(isTermo);
  }

  useEffect(() => {
    const getSizes = async () => {
      const response = await SettingsService.getSizes();
      if (response) setSizes(response.data);
    };

    const getColors = async () => {
      const response = await SettingsService.getColors();
      if (response) setColors(response.data);
    };

    const getCategories = async () => {
      const response = await SettingsService.getCategories();
      if (response) setCategories(response.data);
    };

    getColors();
    getSizes();
    getCategories();
  }, []);

  useEffect(() => {
    if (ShowcaseData.pager) {
      setTotalPages(ShowcaseData.pager.totalPages);
      setPageIndex(ShowcaseData.pager.currentPage);
      setTotalRows(ShowcaseData.pager.totalRows);
    }
  }, [ShowcaseData.pager]);

  const handlePrices = item => {
    if (precos === null) {
      setPrecos(item);
      setFilter(oldFilter => [...oldFilter, item]);
    } else if (precos.id !== item.id) {
      setFilter(oldFilter => [
        ...oldFilter.filter(f => f.id !== precos.id),
        item,
      ]);
      setPrecos(item);
    } else {
      const filteredFilter = filter.filter(f => f.id !== item.id);
      setFilter(filteredFilter);
      setPrecos(null);
    }
  };

  const handleTagClose = item => {
    const filteredFilter = filter.filter(f => f.value !== item.value);
    setFilter(filteredFilter);

    if (item.typeId === 3) {
      handlePrices(item);
    }
  };

  function limpaFiltro(e) {
    setTermo('');
    procurarPorTermo(e);
  }

  return (
    <>
      <StyledContainer>
        <StyledMobileInputPage open={closeMInput}>
          <div style={{ display: 'flex', backgroundColor: '#FFFFFF', height: '15%', }}>
            <div style={{ position: 'absolute', right: 15, top: 15, }}            >
              <Icon icon={close} width="15px" height="15px" pointer onClick={() => setCloseMInput(false)} />
            </div>
            <form
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'flex-end',
                justifyContent: 'space-evenly',
                paddingBottom: '5px',
              }}
              onSubmit={e => procurarPorTermo(e)}>
              <Icon icon={search} marginLeft={10} width="20px" height="25px" />
              <div
                style={{
                  display: 'flex',
                  width: '55%',
                  borderBottom: '1px solid #8C8C8C',
                  boxSizing: 'border-box',
                  outline: 0,
                  padding: '2px',
                  margin: 0,
                }}>
                <Input
                  style={{ border: 'none' }}
                  height="100%"
                  width="95%"
                  Input="submit"
                  value={isTermo}
                  placeholder="O que está procurando?"
                  onChange={e => setTermo(e.target.value)}
                />
                <Icon
                  style={{ marginTop: '8px', marginRight: '8px' }}
                  icon={close}
                  width="10px"
                  height="15px"
                  pointer
                  onClick={e => limpaFiltro(e)}
                />
              </div>
              <StyledButton buttonColor={settings.buttonColor} width="25%" onClick={e => { procurarPorTermo(e); setCloseMInput(false); }}>
                BUSCAR
              </StyledButton>
            </form>
          </div>
        </StyledMobileInputPage>
        <StyledPage>
          <StyledBody>
            <StyledFilterContainer>
              <StyledExpandMobile>
                <Expand
                  title="FILTROS"
                  borderBottom
                  bColor
                  titleFontSize={theme.fontSize._14px}
                  titleColor={theme.colors.gray}
                  titleFontWeight="bold"
                  master
                  icon3={(
                    <Icon
                      icon={search}
                      width="17px"
                      height="17px"
                      mgRight="10px"
                      pointer
                      onClick={() => setCloseMInput(true)}
                    />
                  )}>
                  <br />
                  <Text fontSize={theme.fontSize._12px} color={theme.colors.gray} fontWeight="bold" textAlign="left">
                    CATEGORIAS
                  </Text>
                  <br />
                  <StyledCategoriesSelected>
                    <Expand bColor title="Todas as categorias " titleFontSize={theme.fontSize._14px} titleColor={theme.colors.gray} borderBottom titleMTop="10px" titleMBottom="10px" >
                      {categories &&
                        categories.map(item => (
                          <StyledText
                            key={item.id}
                            active={filter.some(spec => spec.id === item.id)}
                            onClick={() =>
                              filter.some(spec => spec.value === item.value)
                                ? handleTagClose(item)
                                : setFilter([
                                  ...filter,
                                  { ...item, typeId: 99 },
                                ])}>
                            {item.value.toLowerCase()}
                          </StyledText>
                        ))}
                    </Expand>
                  </StyledCategoriesSelected>
                  <Text fontSize={theme.fontSize._12px} color={theme.colors.gray} fontWeight="bold" textAlign="left" marginTop="8px" >
                    FILTRAR POR
                  </Text>
                  <br />
                  <Expand title="Cor" borderBottom bColor titleMTop="10px" titleMBottom="10px" titleFontSize={theme.fontSize._14px} titleColor={theme.colors.gray}>
                    <StyledColors>
                      {colors &&
                        colors.map(item => (
                          <StyledColorSelected
                            key={item.value}
                            id={item.value}
                            className="color"
                            backgroundColor={colorValues.find(x => { return (x.name.toLowerCase() === item.value.replace('/', '-').normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()); })?.value}
                            active={filter.some(spec => spec.value === item.value,)}
                            onClick={() => filter.some(spec => spec.value === item.value) ? handleTagClose(item) : setFilter([...filter, item])}
                          />
                        ))}
                    </StyledColors>
                  </Expand>
                  <Expand title="Tamanho" borderBottom bColor titleMTop="10px" titleMBottom="10px" titleFontSize={theme.fontSize._14px} titleColor={theme.colors.gray}>
                    <StyledSizes>
                      {sizes &&
                        sizes.map(item => (
                          <StyledSizeSelected
                            key={item.value}
                            id={item.value}
                            active={filter.some(spec => spec.value === item.value,)}
                            onClick={() => filter.some(spec => spec.value === item.value) ? handleTagClose(item) : setFilter([...filter, item])}
                          >
                            <span>{item.description ?? item.value}</span>
                          </StyledSizeSelected>
                        ))}
                    </StyledSizes>
                  </Expand>
                  <Expand
                    title="Faixa de preço"
                    borderBottom
                    bColor
                    titleMTop="10px"
                    titleMBottom="10px"
                    titleFontSize={theme.fontSize._16px}
                    titleColor={theme.colors.gray}
                  >
                    {prices &&
                      prices.map((item, index) => (
                        <StyledListPrices key={index}>
                          <Checkbox
                            checked={filter.some(
                              spec => spec.value === item.value,
                            )}
                            color="#27AE60"
                            name={item.value}
                            onClick={() => handlePrices(item)}
                          />
                          <Text
                            fontSize={theme.fontSize._14px}
                            color={theme.colors.black}
                          >
                            {item.value}
                          </Text>
                        </StyledListPrices>
                      ))}
                  </Expand>
                  <select
                    style={{
                      width: '100%',
                      height: '35px',
                      color: '#828282',
                      border: '1px solid #CECECE',
                      borderRadius: '4px',
                      zIndex: '980',
                    }}
                    onChange={e => setOrderBy(e.target.value)}
                  >
                    {orderBySelect?.map((item) => (
                      <option key={item.id} value={item.type}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </Expand>
              </StyledExpandMobile>

              <StyledExpandDesktop>
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <form
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      borderRadius: '4px',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      marginBottom: '15px',
                      border: '2px solid #cecece',
                      height: '35px',
                    }}
                    onSubmit={e => procurarPorTermo(e)}
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: '90%',
                        boxSizing: 'border-box',
                        outline: 0,
                        padding: '2px',
                        margin: 0,
                      }}
                    >
                      <Input
                        style={{ border: 'none' }}
                        height="100%"
                        width="95%"
                        Input="submit"
                        value={isTermo}
                        onChange={e => setTermo(e.target.value)}
                      />
                    </div>

                    <Icon
                      icon={search}
                      mgRight="10px"
                      width="15px"
                      height="15px"
                      pointer
                      onClick={e => procurarPorTermo(e)}
                    />
                  </form>
                </div>
                <Text
                  fontSize={theme.fontSize._14px}
                  color={theme.colors.black}
                  fontWeight="bold"
                  textAlign="left"
                >
                  CATEGORIAS
                </Text>
                <br />

                <StyledCategoriesSelected>
                  <Expand
                    title="Todas as categorias"
                    titleMTop="10px"
                    titleMBottom="10px"
                    titleFontSize={theme.fontSize._14px}
                    titleColor={theme.colors.black55}
                    marginBottom="10px"
                  // borderBottom
                  >
                    {categories &&
                      categories.map(item => (
                        <StyledText
                          key={item.id}
                          active={filter.some(spec => spec.id === item.id)}
                          onClick={() =>
                            filter.some(spec => spec.value === item.value)
                              ? handleTagClose(item)
                              : setFilter([...filter, { ...item, typeId: 99 }])}
                        >
                          {item.value.toLowerCase()}
                        </StyledText>
                      ))}
                  </Expand>
                </StyledCategoriesSelected>
                <Text
                  fontSize={theme.fontSize._14px}
                  color={theme.colors.black}
                  fontWeight="bold"
                  textAlign="left"
                  marginTop="8px"
                >
                  FILTRAR POR
                </Text>
                <br />
                <Expand
                  title="Cor"
                  titleMTop="10px"
                  titleMBottom="10px"
                  titleFontSize={theme.fontSize._14px}
                  titleColor={theme.colors.black55}
                  borderBottom
                >
                  <StyledColors>
                    {colors &&
                      colors.map(item => (
                        <StyledColorSelected
                          key={item.value}
                          className="color"
                          id={item.color}
                          backgroundColor={
                            colorValues.find(x => {
                              return (
                                x.name.toLowerCase() ===
                                item.value
                                  .replace('/', '-')
                                  .normalize('NFD')
                                  .replace(/[\u0300-\u036f]/g, '')
                                  .toLowerCase()
                              );
                            })?.value
                          }
                          active={filter.some(
                            spec => spec.value === item.value,
                          )}
                          onClick={() =>
                            filter.some(spec => spec.value === item.value)
                              ? handleTagClose(item)
                              : setFilter([...filter, item])}
                        />
                      ))}
                  </StyledColors>
                </Expand>
                <Expand
                  title="Tamanho"
                  borderBottom
                  titleMTop="10px"
                  titleMBottom="10px"
                  titleFontSize={theme.fontSize._14px}
                  titleColor={theme.colors.black55}>
                  <StyledSizes>
                    {sizes &&
                      sizes.map(item => (
                        <StyledSizeSelected
                          key={item.value}
                          id={item.value}
                          active={filter.some(
                            spec => spec.value === item.value,
                          )}
                          onClick={() =>
                            filter.some(spec => spec.value === item.value)
                              ? handleTagClose(item)
                              : setFilter([...filter, item])}
                        >
                          <span>{item.description ?? item.value}</span>
                        </StyledSizeSelected>
                      ))}
                  </StyledSizes>
                </Expand>
                <Expand
                  title="Faixa de Preço"
                  borderBottom
                  titleMTop="10px"
                  titleMBottom="10px"
                  titleFontSize={theme.fontSize._14px}
                  titleColor={theme.colors.black55}>
                  {prices &&
                    prices.map((item, index) => (
                      <StyledListPrices key={index}>
                        <Checkbox
                          checked={filter.some(
                            spec => spec.value === item.value,
                          )}
                          color="#27AE60"
                          name={item.value}
                          onClick={() => handlePrices(item)}
                        />
                        <Text
                          fontSize={theme.fontSize._14px}
                          color={theme.colors.black55}
                        >
                          {item.value}
                        </Text>
                      </StyledListPrices>
                    ))}
                </Expand>
              </StyledExpandDesktop>
            </StyledFilterContainer>
            <StyledAlignVertically>
              <StyledFilterMItens>
                <>
                  <StyledFilterM>
                    {ShowcaseData.loading ? (
                      validTerm && (
                        <StyledItens>
                          <Text
                            fontWeight="600"
                            textAlign="left"
                            fontSize={theme.fontSize._10px}
                          >
                            {`Procurando produtos na palavra ${validTerm}`}
                          </Text>
                        </StyledItens>
                      )
                    ) : (
                      <StyledItens>
                        <Text
                          fontWeight="600"
                          textAlign="left"
                          fontSize={theme.fontSize._10px}
                        >
                          {
                            validTerm
                              ? `${totalRows ?? 0
                              } produtos encontrados na palavra "${validTerm}"`
                              : `${totalRows ?? 0} Produtos encontrados`
                          }
                        </Text>
                      </StyledItens>
                    )}

                    {/* {isTermo} */}
                    {filter.length > 0 && (
                      <StyledFilterText>
                        <Text
                          fontSize={theme.fontSize._10px}
                          marginTop="5px"
                          fontWeight="600"
                        >
                          Filtros:
                        </Text>
                        {filter.map((item, index) => (
                          <StyledFilterMarck key={index}>
                            <div>
                              <GrFormClose
                                size={15}
                                onClick={() => handleTagClose(item)}
                                active={filter.some(
                                  spec => spec.value === item.value,
                                )}
                              />
                            </div>
                            <Text
                              color={theme.colors.black85}
                              fontWeight="600"
                              marginTop="3px"
                              fontSize={theme.fontSize._10px}
                            >
                              {item.category}
                            </Text>
                            <Text
                              color={theme.colors.black85}
                              fontWeight="bold"
                              marginTop="3px"
                              fontSize={theme.fontSize._10px}
                            >
                              {` ${item.description ?? item.value}`}
                            </Text>
                          </StyledFilterMarck>
                        ))}
                      </StyledFilterText>
                    )}
                  </StyledFilterM>
                </>
                <StyledExpandDesktop width="200px">
                  <div
                    style={{
                      width: '100%',
                    }}
                  >
                    <select
                      style={{
                        width: '100%',
                        height: '35px',
                        color: '#828282',
                        border: '1px solid #CECECE',
                        borderRadius: '4px',
                        zIndex: '980',
                      }}
                      onChange={e => setOrderBy(e.target.value)}
                    >
                      {orderBySelect?.map((item) => (
                        <option key={item.id} value={item.type}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </StyledExpandDesktop>
              </StyledFilterMItens>

              <StyledBodyCatalog>
                {!ShowcaseData.loading ? (
                  ShowcaseData &&
                  (ShowcaseData.data.length === 0 ? (
                    <StyledNotFoundProdText>
                      Os produtos não foram encontrados.
                    </StyledNotFoundProdText>
                  ) : (
                    ShowcaseData.data.map(item => {
                      if (item.variations.length) {
                        return (
                          <Card
                            key={item.productDto.id}
                            product={item}
                          />
                        );
                      }
                    })
                  ))
                ) : (
                  <ContainerLoader
                    style={{
                      marginLeft: '290px',
                      paddingTop: '150px',
                    }}
                  >
                    <Loader
                      type="Oval"
                      color={theme.colors.black55}
                      height={80}
                      width={80}
                      timeout={!ShowcaseData.loading}
                    />
                  </ContainerLoader>
                )}
              </StyledBodyCatalog>
              {!ShowcaseData.loading && ShowcaseData && (
                <div style={{ width: '100%' }}>
                  <PaginatorFooter
                    onPagerChange={e => setPageIndex(e)}
                    register={register}
                    totalPages={totalPages}
                    pageIndex={pageIndex}
                    totalRows={totalRows}
                  />
                </div>
              )}
            </StyledAlignVertically>
          </StyledBody>
        </StyledPage>
      </StyledContainer>
    </>
  );
}
export default Vitrine;
