import qs from 'qs';
import { call, put } from 'redux-saga/effects';

import api from '../../../services/api';
import { Types as ShowcaseTypes } from '../../ducks/Showcase';

export function* getShowcase(action) {
  try {
    const { filter } = action.payload;
    const response = yield call(api.get, 'catalog/showcase', {
      params: filter,
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });

    if (response.status === 200) {
      yield put({
        type: ShowcaseTypes.GET_SHOWCASE_SUCCESS,
        data: response.data.payload,
        pager: response.data.pager,
      });
    }
  } catch (error) {
    yield put({
      type: ShowcaseTypes.GET_SHOWCASE_ERROR,
      error,
    });
  }
}
