import styled from 'styled-components';

export const StyledText = styled.span`
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: ${props => props.marginBottom};
  margin-top: ${props => props.marginTop};

  text-align: ${props => props.textAlign};
  font-size: ${props => props.fontSize}rem;
  font-weight: ${props => props.fontWeight};
  color: ${props => props.color};
  cursor: ${props => props.cursor};
  width: auto;
`;
