import styled from 'styled-components';

export const StyledIcon = styled.a`
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 999;

  img {
    width: 50px;
    height: 50px;
  }
`;
