export const Types = {
  GET_CUSTOMER: '@Customer/GET_CUSTOMER',
  GET_CUSTOMER_SUCCESS: '@Customer/GET_CUSTOMER_SUCCESS',
  GET_CUSTOMER_ERROR: '@Customer/GET_CUSTOMER_ERROR',
};

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: '',
};

export default function customer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_CUSTOMER: {
      return { data: [], loading: true, error: '' };
    }
    case Types.GET_CUSTOMER_SUCCESS: {
      return {
        data: action.data,
        pager: action.pager,
        loading: false,
        error: '',
      };
    }
    case Types.GET_CUSTOMER_ERROR: {
      return {
        data: [],
        loading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
}
