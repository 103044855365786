import {
  Value,
  DetailThumb,
  StyledThumb,
  StyledTrack,
  StyledSlider,
  StyledContainer,
} from 'components/Forms/RangeSlider/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

function RangeSlider({
  name,
  register,

  initialValues,
  max,
  min,
  invert,

  width,
  resize,
  overflow,
}) {
  const [values, setValues] = useState(initialValues);

  const containerRef = useRef();
  const sliderRef = useRef();

  useEffect(() => {
    if (typeof ResizeObserver === 'undefined') {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      sliderRef.current.resize();
    });

    resizeObserver.observe(containerRef.current);

    // eslint-disable-next-line
    return () => {
      // eslint-disable-next-line
      resizeObserver.unobserve(containerRef.current);
    };
  });

  return (
    <>
      {(
        <input
          type="hidden"
          name="ageMin"
          key="ageMin"
          style={{ display: 'none' }}
          ref={register}
          value={values[0]}
        />
      )}
      {(
        <input
          type="hidden"
          name="ageMax"
          key="ageMax"
          style={{ display: 'none' }}
          ref={register}
          value={values[1]}
        />
      )}
      <StyledContainer
        ref={containerRef}
        width={width}
        resize={resize}
        overflow={overflow}
      >
        <StyledSlider
          ref={sliderRef}
          min={min}
          max={max}
          valueNow={values}
          defaultValue={initialValues}
          renderTrack={Track}
          renderThumb={Thumb}
          onChange={val => {
            setValues(val);
          }}
          invert={invert}
        />
      </StyledContainer>
    </>
  );
}

RangeSlider.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,

  initialValues: PropTypes.array.isRequired,
  width: PropTypes.string,
  overflow: PropTypes.string,
  resize: PropTypes.string,

  min: PropTypes.number,
  max: PropTypes.number,
  invert: PropTypes.bool,
};

RangeSlider.defaultProps = {
  width: '100%',
  overflow: 'unset',
  resize: 'unset',
  min: 0,
  max: 100,
  invert: false,
  valueNow: [],
};

const Thumb = (props, state) => {
  return (
    <StyledThumb {...props}>
      <DetailThumb />
      <Value>{state.valueNow}</Value>
    </StyledThumb>
  );
};

const Track = (props, state) => {
  return <StyledTrack index={state.index} {...props} />;
};

export default RangeSlider;
