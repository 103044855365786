import styled from 'styled-components';



export const StyledTableContainer = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  min-height: ${props => props.minHeight ?? '400px'};
  max-height: ${(props) => props.maxHeight};
  background-color: #FFF;
  
  box-shadow:  0px 0px 5px 0.1px rgba(0, 0, 0, 0.2);
  
  overflow-y: auto;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout:fixed;
  position: relative;
  /* height: 100%; */
  color: ${ props => props.theme.colors.black55};
  
  & tr {
    vertical-align: middle;
    background: #FFF;
  }
  
  & th, td {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 12px;
    
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    font-style: normal;
    font-weight: normal;
    font-size: ${props => props.theme.fontSize._12px}rem;
    color: ${props => props.theme.colors.black20};
  }

  & tbody td {
    font-size: ${props => props.theme.fontSize._12px}rem;
    color: ${props => props.theme.colors.black55}
  }
  
  & thead > th {
    text-align: center;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
`;
