import styled from 'styled-components';

export const StyledImg = styled.img`
  width: 8px;
  height: 8px;
`;

export const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: max-content;
`;

export const StyledContainer = styled.div`
  width: max-content;
  margin-left: 5px;
`; 