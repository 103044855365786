import Modal from 'components/Modals/Modal/index';
import FieldError from 'components/Forms/FieldError';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'react-grid-system';
import React, { useState, useEffect } from 'react';
import CatalogSendButton from 'pages/Customers/CreateCatalogModal/StyledCatalogSendButton';
import smartSalesSessionStorage, { storageKeys } from "storage/smart-sales-session-storage";
import { createCustomer } from 'services/customers-services';
import { cpf, cnpj } from 'cpf-cnpj-validator';
import { mask } from 'remask';
import {
  StyledFooterContainer,
  StyledTransparentButton,
  StyledButton,
  StyledFields,
  StyledType,
  StyledRow,
  StyledLabel,
  StyledExternalCircle,
  StyledInternalCircle,
  StyledInput
} from './styles';

const CreateCustomerModal = ({ onCloseCallback }) => {
  const [enabledSave, setEnabledSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [personType, setPersonType] = useState(0);
  const [phone, setPhone] = useState('');
  const [cpfField, setCpfField] = useState('');
  const [cnpjField, setCnpjField] = useState('');
  const [socialReason, setSocialReason] = useState('');
  const [name, setName] = useState();
  const [email, setEmail] = useState('');
  const seller = smartSalesSessionStorage.getItem(storageKeys.SELLER);
  const store = smartSalesSessionStorage.getItem(storageKeys.STORE);

  useEffect(() => {
  }, []);

  useEffect(() => {
    if (cpfField.length === 14 && !cpf.isValid(cpfField.replace('.', '').replace('.', '').replace('-', ''))) {
      setErrors({
        cpf: { message: 'CPF inválido' }
      });
    } else if (cnpjField.length === 18 && !cnpj.isValid(cnpjField.replace('.', '').replace('.', '').replace('/', '').replace('-', ''))) {
      setErrors({
        cnpj: { message: 'CNPJ inválido' }
      });
    } else {
      setErrors({});
    }
  }, [cpfField, cnpjField]);

  useEffect(() => {
    if (personType === 0) {
      setEnabledSave(cpfField.length === 14 && cpf.isValid(cpfField.replace('.', '').replace('.', '').replace('-', ''))
        && name && name != "" && RegExp(/[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{3,} [A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{2,}/).test(name)
        && phone.length === 16 && RegExp(/\(\d{2}\)( )?(9)?( )?\d{4}-\d{4}/).test(phone));

    } else {
      setEnabledSave(cnpjField.length === 18 && cnpj.isValid(cnpjField.replace('.', '').replace('.', '').replace('/', '').replace('-', ''))
        && socialReason && socialReason != "" && RegExp(/\w{3,} \w{3,}/).test(socialReason)
        && phone.length === 16 && RegExp(/\(\d{2}\)( )?(9)?( )?\d{4}-\d{4}/).test(phone));
    }
  }, [personType, cpfField, email, phone, socialReason, cnpjField, name])

  const handleCreateCustomer = async () => {
    setLoading(true);

    try {
      const response = await createCustomer({
        name: personType == 1 ? socialReason : name,
        document: personType == 1 ? cnpjField : cpfField,
        phone: phone,
        email: email,
        sellerId: seller?.id || null,
        storeCode: store?.storeCode,
      });

      setLoading(false);
      onCloseCallback();
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <Modal showCloseButton width="120vw" maxWidth="360px" height="max-content" maxHeight='90vh' padding="0px" overflow="initial" onCloseCallback={onCloseCallback} isVisible>
      <Container style={{ padding: '0px' }}>
        <StyledFields >
          <h4>Cadastro de Cliente</h4>
          <StyledRow style={{ paddingTop: '12px' }}>
            <StyledExternalCircle onClick={() => setPersonType(0)}>
              {personType === 0 && <StyledInternalCircle />}
            </StyledExternalCircle>
            <StyledType>Pessoa Física</StyledType>
            <StyledExternalCircle onClick={() => setPersonType(1)}>
              {personType === 1 && <StyledInternalCircle />}
            </StyledExternalCircle>
            <StyledType>Pessoa Jurídica</StyledType>
          </StyledRow>
          {personType === 0 ? (<>
            <StyledRow>
              <StyledLabel htmlFor="name">
                <p>Nome completo *</p>
                <StyledInput id="name" onChange={(e) => setName(e.target.value)} value={name} />
              </StyledLabel>
            </StyledRow>
            <StyledRow>
              <StyledLabel htmlFor="cpf">
                <p>CPF *</p>
                <StyledInput value={cpfField} onChange={(e) => setCpfField(mask(e.target.value, ['999.999.999-99']))} id="phone" />
                {errors.cpf && (
                  <FieldError>{errors.cpf.message}</FieldError>
                )}
              </StyledLabel>
            </StyledRow>
          </>
          ) : (
            <>
              <StyledRow>
                <StyledLabel htmlFor="socialReason">
                  <p>Razão Social *</p>
                  <StyledInput id="socialReason" onChange={(e) => setSocialReason(e.target.value)} value={socialReason} />
                </StyledLabel>
              </StyledRow>
              <StyledRow>
                <StyledLabel htmlFor="cnpj">
                  <p>CNPJ *</p>
                  <StyledInput value={cnpjField} onChange={(e) => setCnpjField(mask(e.target.value, ['99.999.999/9999-99']))} id="cnpj" />
                  {errors.cnpj && (
                    <FieldError>{errors.cnpj.message}</FieldError>
                  )}
                </StyledLabel>
              </StyledRow>
            </>
          )}
          <StyledRow>
            <StyledLabel htmlFor="phone">
              <p>Telefone *</p>
              <StyledInput value={phone} onChange={(e) => setPhone(mask(e.target.value, ['(99) 9 9999-9999']))} id="phone" />
            </StyledLabel>
          </StyledRow>
          <StyledRow>
            <StyledLabel htmlFor="email">
              <p>Email</p>
              <StyledInput type="email" id="email" onChange={(e) => setEmail(e.target.value)} value={email} />
            </StyledLabel>
          </StyledRow>
        </StyledFields>
        <StyledFooterContainer>
          <StyledTransparentButton onClick={onCloseCallback}>Cancelar</StyledTransparentButton>
          <CatalogSendButton onClick={() => handleCreateCustomer()} disabled={!enabledSave || loading} text={'SALVAR'} loading={loading} />
        </StyledFooterContainer>
      </Container>
    </Modal>
  );
};

CreateCustomerModal.propTypes = {
  onCloseCallback: PropTypes.func
};

CreateCustomerModal.defaultProps = {
  onCloseCallback: () => null
};

export default CreateCustomerModal;
