import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import { StyledPagination, StyledListItem } from './styles';

export default function Pagination({
  name,
  register,
  testId,
  maxPages,
  totalPages,
  pageIndex,
  onChange,
  ...rest
}) {
  const [page, setPage] = useState(pageIndex);

  useEffect(() => {
    setPage(pageIndex);
  }, [pageIndex]);

  useEffect(() => {
    onChange(page);
  }, [page]);

  const ItemList = () => {
    const items = [];

    for (let currentIndex = 0; currentIndex < 5; currentIndex += 1) {
      const number = page + currentIndex;

      items.push(
        <li key={currentIndex}>
          <StyledListItem
            onClick={() => setPage(number)}
            active={page === number}
          >
            {number}
          </StyledListItem>
        </li>,
      );

      if (number >= totalPages) {
        break;
      }
    }

    return items;
  };

  return (
    <>
      <input type="hidden" name={name} ref={register} value={page} />

      <StyledPagination dataTestId={testId} {...rest}>
        <li>
          <StyledListItem
            disable={page === 1}
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
          >
            Anterior
          </StyledListItem>
        </li>
        {ItemList()}
        <li>
          <StyledListItem
            disable={page < 1 || page === totalPages || totalPages === 0}
            onClick={() => {
              if (page < totalPages) {
                setPage(page + 1);
              }
            }}
          >
            Próximo
          </StyledListItem>
        </li>
      </StyledPagination>
    </>
  );
}

Pagination.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func,
  testId: PropTypes.string.isRequired,
  maxPages: PropTypes.number,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  onChange: PropTypes.func,
  pageIndex: PropTypes.number
};

Pagination.defaultProps = {
  currentPage: 1,
  totalPages: 1,
  maxPages: 5,
  onChange: () => {},
  register: () => {},
  pageIndex: 1
};
