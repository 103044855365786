export const Types = {
  GET_SHOWCASE: '@Showcase/GET_SHOWCASE',
  GET_SHOWCASE_SUCCESS: '@Showcase/GET_SHOWCASE_SUCCESS',
  GET_SHOWCASE_ERROR: '@Showcase/GET_SHOWCASE_ERROR',
};

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: '',
};

export default function showcase(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_SHOWCASE: {
      return { data: [], loading: true, error: '' };
    }
    case Types.GET_SHOWCASE_SUCCESS: {
      return {
        data: action.data,
        pager: action.pager,
        loading: false,
        error: '',
      };
    }
    case Types.GET_SHOWCASE_ERROR: {
      return {
        data: [],
        loading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
}
