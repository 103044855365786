import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';


function RedirectInternal() {
    const history = useHistory();
    
    useEffect(() => {
        history.push('/404');
        // eslint-disable-next-line
    },[])

    return <div />
}

export default RedirectInternal;