import React from 'react';
import Skeleton from 'react-loading-skeleton';


export const skeletonLoadTable = () => {
    const skeletonLoader = [];
    
    for (let i = 0; i < 10; i++) {
        skeletonLoader.push(
          <tr key={i}>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
            <td>
              <Skeleton />
            </td>
          </tr>,
        );
    }
    
    return skeletonLoader;
};
