import api from 'services/api';

export async function getContactInfo() {
  return api.get('company/contact-info').catch(e => console.error(e));
}

export async function postLogo(formData) {
  return api.post('company/logo/', formData);
}

export async function postBanner(formData) {
  return api.post('company/banner/', formData);
}

export async function postVisualIdentity(body) {
  return api.post('company/visual-identity/', body);
}

export async function getVisualIdentity(storePortal) {
  return api.get(`/company/visual-identity/${storePortal}`);
}

export async function existCompany(name) {
  return api.get(`company/${name}/exists`);
}

export async function salesConfig() {
  return api.get(`company/sales-config`);
}
