import styled, { keyframes } from 'styled-components';

const Animation = keyframes`
    0% {right: -27%; top: 0px; opacity: 0}
    100% {right: 0%; top: 0px; opacity: 1}
`;

export const StyleMarket = styled.div`
  z-index: 9999;
  background-color: white;
  overflow: auto;
  opacity: 1;
  position: fixed;
  top: 0;
  right: 0;
  float: right;
  height: 100%;
  animation: ${Animation};
  animation-duration: 1s;
  ::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: black;
  }
`;
export const StyledProduct = styled.div`
  display: flex;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  width: 361px;
  height: 100px;
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
`;

export const DivImg = styled.div`
  img {
    width: 58px;
    height: 81px;
    float: left;
    object-fit: cover;
  }
`;

export const DivColumnProductDescription = styled.div`
  padding: 10.19px;
  p:nth-child(-n + 2) {
    font-size: 13px;
  }
  p:nth-child(3) {
    font-size: 14px;
    color: #575757;
  }
  flex-grow: ${props => props.flexGrow};
`;
export const DivResult = styled.div`
  border-top: 1px dashed #000000;
  display: flex;
  margin: ${props => props.margin || 'auto'};
  margin-top: 5%;
  line-height: 30px;
`;

export const DivColumn = styled.div`
  padding: 10px;
  flex-grow: ${props => props.flexGrow};
  text-align: ${props => props.align};
`;
export const DivColumnTotal = styled.div`
  padding: 10px;
  flex-grow: ${props => props.flexGrow};
  text-align: ${props => props.align};
`;

export const DivHeader = styled.div`
  display: flex;

  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  color: black;
  font-size: 18px;
  padding: 26px 24px;
  border-left: solid 3px black;
  p {
    flex-grow: 1;
  }
`;
