import styled from 'styled-components';

export const StyledBackOfficeHeaderContainer = styled.div`
  height: 70px;
  box-sizing: border-box;
  display: flex;
  position: sticky;
  background-color: #fff;
  z-index: 1001;
  top: 0;
  padding: 7px 20px 7px 15px;
  justify-content: space-between;
  align-items: center;
  border-bottom: #ccc 1px solid;
`;

export const StyledTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 3px;
`;

export const StyledStore = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
`;

export const StyledBurguerButton = styled.button`
  width: 35px;
  height: 35px;
  background-color: transparent;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  margin-right: 15px;

  &:hover {
    background-color: #EBEBEB;
  }
`;
