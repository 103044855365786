import catalogIcon from 'assets/icons/catalogo.svg';
import config from 'assets/icons/config.svg';
import customer from 'assets/icons/customer.svg';
import insights from 'assets/icons/insights.svg';
import smartSalesSessionStorage, { storageKeys } from "storage/smart-sales-session-storage";
import iconePedidos from 'assets/icons/pedidos.svg';
import {
  StyledIcons,
  StyledNavLinkTo,
  StyledSideMark,
  StyledContainer,
  StyledTitle,
  StyledSidebarContainer
} from 'Layout/SideBar/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const SideBar = ({ openSidebar }) => {

  const seller = smartSalesSessionStorage.getItem(storageKeys.SELLER);

  const [open, setOpen] = useState(false);

  const routes = [
    { id: 0, img: catalogIcon, route: '/catalog', title: 'Catálogos' },
    { id: 1, img: customer, route: '/customers', title: 'Clientes' },
    { id: 2, img: iconePedidos, route: '/pedidos', title: 'Pedidos' },
    { id: 3, img: insights, route: '/insights', title: 'Insights' },
  ];

  const routesPrivate = [
    { id: 4, img: config, route: '/configuracoes', title: 'Configurações' }
  ];

  return (
    <StyledContainer appearance={open || openSidebar} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <StyledSidebarContainer>
        {routes.map((atributes, idx) =>
        (
          <StyledNavLinkTo key={idx} to={atributes.route} activeClassName="selected" >
            <StyledSideMark actived={atributes.actived} />
            <StyledIcons src={atributes.img} />
            <StyledTitle>{(open || openSidebar) && atributes.title}</StyledTitle>
          </StyledNavLinkTo>
        ))}
        {routesPrivate.map((atributes, idx) =>
        (
          seller?.typeId == 2 || seller?.typeId == 3 ? (
            <StyledNavLinkTo key={idx} to={atributes.route} activeClassName="selected" className={atributes.id === 4 ? 'isLast' : ''} >
              <StyledSideMark actived={atributes.actived} />
              <StyledIcons src={atributes.img} />
              <StyledTitle>{(open || openSidebar) && atributes.title}</StyledTitle>
            </StyledNavLinkTo>
          ) : (<></>)
        ))}
      </StyledSidebarContainer>
    </StyledContainer>
  );
};

SideBar.propTypes = {
  openSidebar: PropTypes.bool,
};

SideBar.defaultProps = {
  openSidebar: false,
};

export default SideBar;
