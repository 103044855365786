import React from 'react';

import { DivColumnTotal, DivResult } from './styles';

const ProductDescription = ({
  keyValue,
  value,
  margin,
  subTotal,
  descontos,
  totalValue,
}) => {
  return (
    <DivResult margin={margin}>
      <DivColumnTotal flexGrow="14">
        <p>Subtotal</p>
        <p>Descontos</p>
        <p>{keyValue}</p>
        <b>Total</b>
      </DivColumnTotal>
      <DivColumnTotal flexGrow="4" align="right">
        <p>
          {Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(subTotal)}
        </p>
        <p>
          {Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(descontos)}
        </p>
        <p>{value}</p>
        <b>
          {Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(totalValue)}
        </b>
      </DivColumnTotal>
    </DivResult>
  );
};

export default ProductDescription;
