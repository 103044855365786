import styled from 'styled-components';

export const CheckboxLabelComponent = styled.label`
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin: 0px;
  cursor: pointer;
  font-size: ${props => props.theme.fontSize._10px}rem;
  color: ${props => props.labelColor};

  &.settings-checkout {
    font-size: 12px;
  }
`;

export const CheckboxComponent = styled.input`
  display: none;

  & + ${CheckboxLabelComponent}:before {
    content: '';
    background: #fff;
    border-radius: 2px;
    border: 1px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -1px;
    width: 10px;
    height: 10px;
    padding: 1px;
    margin-right: 10px;
  }

  &:checked + ${CheckboxLabelComponent}:after {
    content: '';
    padding: 1px;
    position: absolute;
    width: 1px;
    height: 5px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    border-color: ${props =>
    props.invertColor ? props.color : props.theme.colors.white};

    transform: rotate(45deg);

    top: 1.5px;
    left: 4.8px;
  }

  &:checked + ${CheckboxLabelComponent}:before {
    background-color: ${props =>
    props.invertColor ? props.theme.colors.white : props.color};

    border: 1px solid
      ${props => (props.invertColor ? props.theme.colors.white : props.color)};
  }
`;
