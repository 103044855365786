import api from 'services/api';

export async function getCustomersWithLastSale(data) {
  let config = {};

  if (data) {
    const filteredData = Object.entries(data).reduce(
      (accumulator, [key, value]) =>
        value ? ((accumulator[key] = value), accumulator) : accumulator,
      {},
    );
    config = { params: { ...filteredData } };
  }

  return api.get('/customers', config);
}

export async function getStore() {
  return api.get('company/stores');
}

export async function listStore(cep) {
  return api.get(`company/stores/near-cep/${cep}`);
}

export async function createCustomer(body) {
  return api.post(`/customers`, body).catch(e => console.log(e));
};