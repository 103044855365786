import Checkbox from 'components/Forms/Checkbox/index';
import {
  StyledSelectAllBarContainer,
  StyledCenterLabel,
} from 'pages/Customers/SelectAllBar/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import theme from 'styles/theme';

const SelectAllBar = ({
  onClickCheckAll,
  checkBoxLabel,
  onClickCenterLabel,
  centerLabelText,
  actions,
}) => {
  return (
    <StyledSelectAllBarContainer>
      <Row align="center">
        <Col md={4}>
          <Checkbox
            value="all"
            name="all"
            onClick={onClickCheckAll}
            style={{ marginTop: '3px', marginRight: '5px' }}
            label={checkBoxLabel}
            labelColor={theme.colors.white}
            invertColor
          />
        </Col>
        <Col md={4} style={{ textAlign: 'center' }}>
          <StyledCenterLabel onClick={onClickCenterLabel}>
            {centerLabelText}
          </StyledCenterLabel>
        </Col>        
      </Row>
    </StyledSelectAllBarContainer>
  );
};

SelectAllBar.propTypes = {
  onClickCheckAll: PropTypes.func.isRequired,
  checkBoxLabel: PropTypes.string,
  onClickCenterLabel: PropTypes.func,
  centerLabelText: PropTypes.string,
  actions: PropTypes.node,
};

SelectAllBar.defaultProps = {
  checkBoxLabel: PropTypes.string,
  onClickCenterLabel: PropTypes.func,
  centerLabelText: PropTypes.string,
  actions: PropTypes.node,
};

export default SelectAllBar;
