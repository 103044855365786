import styled from 'styled-components';

export const StyledContainer = styled.div`
    width: 100%;
    height: 100vh;
    padding: 100px 30px;
`;

export const StyledContent = styled.div`
    max-width: 470px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        max-width: 470px;
    }

    p {
        margin-top: 40px;
        font-style: normal;
        font-size: 24px;
        line-height: 33px;
        text-align: center;
        color: #616161;
    }
`;