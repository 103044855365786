import PropTypes from 'prop-types';
import React from 'react';
import { StyledFlexContainer } from 'styles/global';

import { StyledMainContent, StyledTitle, StyledButton, StyledCommumText } from './styles';

function ResumeCard({ title, firstRow, secondRow, onClick, isEditable, color }) {
  return (
    <StyledMainContent>
      <StyledFlexContainer direction="column">
        <StyledTitle>
          {title}
        </StyledTitle>
        <StyledCommumText>
          {firstRow}
        </StyledCommumText>
        <StyledCommumText>
          {secondRow}
        </StyledCommumText>
      </StyledFlexContainer>
      {isEditable && (
        <StyledButton backgroundColor={color} type="button" onClick={() => onClick()}>
          ALTERAR
        </StyledButton>
      )}
    </StyledMainContent>
  );
};

ResumeCard.propTypes = {
  title: PropTypes.string,
  firstRow: PropTypes.string,
  secondRow: PropTypes.string,
  onClick: PropTypes.func,
  isEditable: PropTypes.bool,
  color: PropTypes.string,
};

ResumeCard.defaultProps = {
  title: '',
  firstRow: '',
  secondRow: '',
  onClick: () => { },
  isEditable: true,
  color: '#37D0B5'
};

export default ResumeCard;