import api from 'services/api';

export async function getProductSKU(id) {
  return api.get(`/product/${id}/sku`).catch(e => console.log(e));
}

export async function getProductById(id) {
  return api.get(`/product/${id}`).catch(e => console.log(e));
}

export async function getCatalogById(id, formData) {
  return api.get(`/catalog/${id}/product`, { params: formData }).catch(e => console.log(e));
}

export async function createCatalog(payload) {
  return api.post('/catalog', payload).catch(e => console.log(e));
}

export async function getProducts(term, page, categoryId) {
  return api.get(
    'product/search?hasNext=true', {
    params: {
      term,
      pageIndex: page,
      categoryId
    },
  });
}

export async function getCategories() {
  return api.get('catalog/categories').catch(e => console.log(e));
}

export async function getCatalogCustomers(catalogId) {
  return api.get(`catalog/${catalogId}/customer`).catch(e => console.log(e));
}

export async function getCatalogs(filter) {
  return api.get('/catalog', { params: filter }).catch(e => console.log(e));
}

export async function getCatalogsEnum(filter) {
  return api.get(`/catalog/enum`, { params: { store: filter.store} }).catch(e => console.log(e));
}

export async function getUpdatedCatalogCustomers(id,body) {
  return api.post(`/catalog/${id}/customers`, body).catch(e => console.log(e));
}

export async function UpdateCatalogCustomers(id,body){
  return api.post(`/catalog/${id}/customer`, body).catch(e => console.log(e));
}

export async function SaveCatalogMessage(id, body){
  return api.post(`/catalog/${id}/message`, body).catch(e => e.console.log(e))
}

export async function getSpecificCatalog(id) {
  return api.get(`/catalog/${id}/details`).catch(e => console.log(e));
}

export async function patchSpecificCatalog(id, body) {
  return api.post(`/catalog/${id}`, body).catch(e => console.log(e));
}

export async function getInstaList(id, body) {
  return api.get(`/catalog/${id}/instagram_list`, body).catch(e => console.log(e));
}

export async function postCustomerReceived(id, customerId) {
  return api.post(`/catalog/${id}/${customerId}`).catch(e => console.log(e));
}