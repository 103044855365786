import { call, put } from 'redux-saga/effects';

import api from '../../../services/api';
import { Types as CustomerTypes } from '../../ducks/Customer';

export function* getCustomer(action) {
  try {
    const { data } = action.payload;
    let config = {};

    if (data) {
      const filteredData = Object.entries(data).reduce(
        (accumulator, [key, value]) =>
          value ? ((accumulator[key] = value), accumulator) : accumulator,
        {},
      );
      config = { params: { ...filteredData } };
    }
    const response = yield call(api.get, '/customers', config);
    if (response.status === 200) {
      yield put({
        type: CustomerTypes.GET_CUSTOMER_SUCCESS,
        data: response.data.payload,
        pager: response.data.pager,
      });
    }
  } catch (error) {
    yield put({
      type: CustomerTypes.GET_CUSTOMER_ERROR,
      error,
    });
  }
}
