import {StyledPictureComponent} from 'components/Picture/styles';
import PropTypes from 'prop-types';
import React from 'react';
import {withTheme} from 'styled-components';


const Picture = ({
    src,
    width,
    height,
    alt,
    ...rest
}) => (
  <StyledPictureComponent
    src={src}
    alt={alt}
    width={width}
    height={height}
    {...rest}
  />
);

Picture.propTypes = {
  src   : PropTypes.string.isRequired,
  alt   : PropTypes.string.isRequired,
  width : PropTypes.string,
  height: PropTypes.string,
};

Picture.defaultProps = {
  width : '100%',
  height: '100%',
};

export default withTheme(Picture);
