import closeButtonIcon from 'assets/icons/close-button.svg';
import Icon from 'components/Icon';
import Text from 'components/Typografy/Text';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import { withTheme } from 'styled-components';
import theme from 'styles/theme';
import ModalContext from './context';
import * as Styled from './styles';

const Modal = ({
  onCloseCallback,
  children,
  isVisible,
  isLoading,
  showCloseButton,
  width,
  height,
  maxHeight,
  maxWidth,
  minWidth,
  padding,
  overflow
}) => {
  return ReactDOM.createPortal(
    isVisible ? (
      <ModalContext.Provider value={{}}>
        <Styled.OverlayStyledComponent>
          <Styled.ModalStyledContainer className='global-modal' width={width} maxWidth={maxWidth} maxHeight={maxHeight} height={height} padding={padding} minWidth={minWidth}>
            {showCloseButton ? (
              <Styled.CloseButtonComponent onClick={onCloseCallback}>
                <Icon icon={closeButtonIcon} width="15px" height="15px" hoverColor={theme.colors.black85} />
              </Styled.CloseButtonComponent>
            ) : null}
            <Styled.ModalStyledContainerMain className='global-modal' overflow={overflow}>
              {isLoading ? (
                <LoadingOverlay text="Processando" />
              ) : (
                children
              )}
            </Styled.ModalStyledContainerMain>
          </Styled.ModalStyledContainer>
        </Styled.OverlayStyledComponent>
      </ModalContext.Provider>
    ) : null,
    document.querySelector('#modal'),
  );
};

Modal.propTypes = {
  showCloseButton: PropTypes.bool,
  onCloseCallback: PropTypes.func,
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  maxHeight: PropTypes.string,
  maxWidth: PropTypes.string,
  padding: PropTypes.string,
  isLoading: PropTypes.bool,
  overflow: PropTypes.string
};

Modal.defaultProps = {
  showCloseButton: true,
  onCloseCallback: () => null,
  width: '300px',
  maxWidth: 'max-content',
  maxHeight: 'max-content',
  height: '233px',
  padding: '20px',
  overflow: 'scroll',
  isLoading: false
};

const LoadingOverlay = ({ text }) => {
  return (
    <Styled.LoadOverlayStyledComponent>
      <Styled.LoadBlockStyledComponent>
        <Styled.LoadStyledComponent>
          <div />
          <div />
          <div />
          <div />
        </Styled.LoadStyledComponent>
        <Text color={theme.colors.lightGreen}>{`${text}...`}</Text>
      </Styled.LoadBlockStyledComponent>
    </Styled.LoadOverlayStyledComponent>
  );
};

LoadingOverlay.propTypes = {
  text: PropTypes.string.isRequired,
};

export default withTheme(Modal);
