export const Types = {
  GET_PRODUCTS: '@Products/GET_PRODUCTS',
  GET_PRODUCTS_SUCCESS: '@Products/GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_ERROR: '@Products/GET_PRODUCTS_ERROR',
};

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: '',
};

export default function products(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_PRODUCTS: {
      return { data: [], loading: true, error: '' };
    }
    case Types.GET_PRODUCTS_SUCCESS: {
      return {
        data: action.data,
        pager: action.pager,
        loading: false,
        error: '',
      };
    }
    case Types.GET_PRODUCTS_ERROR: {
      return {
        data: [],
        loading: false,
        error: action.error,
      };
    }
    default:
      return state;
  }
}
