import Label from 'components/Forms/Label';
import Modal from 'components/Modals/Modal';
import Text from 'components/Typografy/Text';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import * as RequestContext from 'services/requests-services';
import { getSellers } from 'services/sellers-services';

import { StyledContainer, StyledButtonA, StyledSelectContainer } from './styles';

const SellerModal = ({
  onCloseCallback,
  onChange,
  requestData,
  sellerId
}) => {
  const [Seller, setSeller] = useState([]);
  const [prodSeller, setProdSeller] = useState();
  const [loading, setLoading] = useState(false);

  const [resultError, setResultError] = useState(true);
  const [resultErrorMsg, setresultErrorMsg] = useState("");

  const getSeller = async () => {
    const response = await getSellers();
    setSeller([{
      name: 'Nenhum',
      id: null
    }, ...response?.data?.payload]);
  };

  useEffect(() => {
    getSeller();
  }, []);

  const handleChangeSeller = async () => {
    const body = {
      orderId: requestData,
      newSellerId: prodSeller?.id,
    };
    
    setResultError(true);
    setLoading(true);
    
      const response = await RequestContext.getRequestsChangeSeller(body);
      
      if (response.data.isSuccess) {
        window.location.reload(false);
      } else {
        setLoading(false);
        setResultError(false);
        setresultErrorMsg("Erro ao realizar alteração de vendedor");
      }
  };

  return (
    <Modal showCloseButton width="90vw" maxWidth="340px" height="max-content" maxHeight='90vh' padding='10px' onCloseCallback={onCloseCallback} isVisible overflow='none'>
      <StyledContainer>
        <Text fontSize="18px">Alterar vendedor do pedido</Text>
        <StyledSelectContainer justify="space-between" direction="column">
          <Label htmlFor="customerSeller">Novo Vendedor</Label>
          <Select
            name="seller"
            inputId="seller"
            options={Seller}
            value={prodSeller}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            placeholder="Selecione"
            onChange={(value) => setProdSeller(value)}
          />
        </StyledSelectContainer>
        <StyledButtonA onClick={handleChangeSeller}>
          {!loading ? `APLICAR` : (<Loader type="Oval" color='#FFF' height={17} width={17} timeout={0} />)}
        </StyledButtonA>
        <StyledSelectContainer justify="space-between" direction="column">
          <Text hidden={resultError} textAlign="start" fontSize="15px" color='#de0b32' >Falha ao alterar vendedor:</Text>
          <Text hidden={resultError} textAlign="start" fontSize="15px" color='#de0b32' >{resultErrorMsg}</Text>
        </StyledSelectContainer>
      </StyledContainer>
    </Modal>
  );
};

SellerModal.propTypes = {
  onCloseCallback: PropTypes.func,
  requestData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func,
};

SellerModal.defaultProps = {
  onCloseCallback: () => null,
  requestData: '',
  onChange: () => null,
};

export default SellerModal;
