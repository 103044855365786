import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter, useLocation } from 'react-router-dom';

import Icon from '../../pages/Catalog/Icon';
import close from '../../pages/Catalog/IconsSvg/close.png';
import * as Reshop from '../../services/reshop-services';
import { Types as CartTypes } from '../../store/ducks/Checkout';
import { Types as NsuTypes } from '../../store/ducks/Nsu';
import theme from '../../styles/theme';
import Button from '../Button';
import ProductCard from '../ProductCard';
import ProductDescription from '../ProductDescription';
import Text from '../Typografy/Text';
import {
  StyledSuport,
  StyleMarket,
  DivHeader,
  AlignVertically,
  StyledCampain,
} from './styles';

function LittleCar({ onClose, match }) {
  const location = useLocation();
  const sellerId = new URLSearchParams(location.search).get('sellerId');
  const queries = location.pathname.split('/');
  const catalogId = (location.pathname.includes('catalogo/') ? location.pathname.split('/')[queries.length - 1] : new URLSearchParams(location.search).get('catalogId'));
  const settings = useSelector(state => state.Setting.data);
  const globalState = useSelector(state => state.Checkout);
  const nsuCampaign = useSelector(state => state.Nsu);
  const [descontos, setDescontos] = useState(0);
  const [campaign, setCampaign] = useState();
  const [nsu, setNsu] = useState(nsuCampaign ?? '');

  const total = useSelector(state =>
    state.Checkout.reduce((totalSum, item) => {
      return totalSum + item.price * item.amount;
    }, 0),
  );

  const dispatch = useDispatch();

  const handleRemove = id => {
    dispatch({
      type: CartTypes.REMOVE_FROM_CART,
      id,
    });
  };
  const history = useHistory();

  const buildQuery = () => {
    let nextUrl = '';
    if (sellerId && catalogId) {
      nextUrl = `?catalogId=${catalogId}&sellerId=${sellerId}`
    } else if (catalogId) {
      nextUrl = `?catalogId=${catalogId}`;
    } else if (sellerId) {
      nextUrl = `?sellerId=${sellerId}`
    }

    return nextUrl;
  }

  function handleClick() {
    onClose();
    history.push(`/${match.params.store}/checkout${buildQuery()}`);
  }

  return (
    <>
      <StyleMarket color={settings.MainColor}>
        <DivHeader>
          <p style={{ 'fontFamily': 'Open Sans' }}>Meu carrinho</p>
          <Icon icon={close} onClick={onClose} width="20px" height="23px" hoverColor="black" />
        </DivHeader>
        <AlignVertically>
          {globalState && (
            <StyledSuport>
              {globalState.map(product => (<ProductCard key={product.productId} product={product} onRemove={handleRemove} IconL />))}
            </StyledSuport>
          )}
          <>
            <>
              {campaign?.length > 0 && (
                <StyledCampain>
                  <h5>PROMOÇÃO APLICADA</h5>
                  {campaign?.map(item => (
                    <ul key={item.id} style={{ listStyle: 'none' }}>
                      <li>
                        <Text fontSize={theme.fontSize._12px} fontWeight="800" color={theme.colors.offWhite}>
                          {`${item.nomeCampanha}:`}
                        </Text>
                      </li>
                      <li>
                        <Text
                          fontSize={theme.fontSize._10px}
                          fontWeight="300"
                          color={theme.colors.offWhite}>
                          {item.descricaoCampanha}
                        </Text>
                      </li>
                    </ul>
                  ))}
                </StyledCampain>
              )}
            </>
          </>
        </AlignVertically>
        {globalState?.length > 0 ? (<div style={{ marginLeft: 10, marginRight: 14 }}>
          <ProductDescription subTotal={total} totalValue={total - descontos} descontos={descontos} />
        </div>
        ) : (<></>)}
        {globalState?.length > 0 ? (
          <div style={{ width: '90%', justifyContent: 'center', margin: '0 auto', padding: '30px 0', }} >
            <Button backgroundColor={settings.buttonColor || theme.colors.black} height="50" width="90%" margin="auto" text="FINALIZAR COMPRA" onClick={handleClick} />
            <br />
            <br />
            <Button backgroundColor={settings.secondaryButtonColor} onClick={onClose} height="50" width="90%" margin="auto"
              text="Continue comprando" />
          </div>
        ) : (
          <div style={{ width: '90%', justifyContent: 'center', margin: '0 auto', padding: '30px 0', }}>
            <Button backgroundColor={theme.colors.gray} onClick={onClose} height="50" width="90%" margin="auto"
              text="Seu carrinho está vazio"
            />
          </div>
        )}
      </StyleMarket>
    </>
  );
}

export default withRouter(LittleCar);
