import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';


function RedirectExternal({ match }) {
    const history = useHistory();
    
    useEffect(() => {
        history.push(`/${match.params.store}/404`);
        // eslint-disable-next-line
    },[])

    return <div />
}

export default RedirectExternal;