import Button from 'components/Forms/Button';
import styled, { css } from 'styled-components';
import { StyledFlexContainer } from 'styles/global';

import theme from '../../styles/theme';

export const StyledMainContent = styled.div`
  padding: 15px;
`;

export const StyledTitle = styled.div`
  font-size: 14px;
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};

  span {
    font-weight: 700;
  }
`;

export const StyledSubtitle = styled.p`
  color: #888888;
  font-size: 10px;
  margin-left: 31px;
`;

export const StyledInput = styled.input`
  background-color: #F2F2F2;
  border: 1px solid #CCCCCC;
  padding: 8px;
  height: 35px;
  border-radius: 4px;
  width: 100%;
`;

export const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  margin-left: 20px;
  
  @media screen and (max-width: 633px) {
    width: 100%;
  }
`;

export const StyledButton = styled.button`
  background-color: transparent;  
  border: 1px solid #828282;
  color: #828282;
  border-radius: 4px;
  height: 35px;
  margin: 0 0 15px 20px;  
  font-size: 12px;  
  padding: 8px;
  margin: ${(props) => props.margin};
  width: ${(props) => props.width};
  cursor: pointer;
  min-width: 135px;

  @media screen and (max-width: 633px) {
    width: 100%;
  }
`;

export const StyledForms = styled.form`
  border-bottom: 1px solid #EBEBEB;
  width: 100%;
`;

export const StyledCatalogList = styled.div`
  padding: 15px 0;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  min-height: 170px;
`;

export const StyledListItems = styled.ul`
  display: grid;
  align-items: center;
  overflow-y: scroll;
  grid-template-columns: repeat(3, 1fr);
  gap: 0px;
  overflow-x: hidden;

  @media screen and (max-width: 850px) {
    overflow-x: scroll;
  }

  .catalog-card {
    margin: 8px;
    min-width: 230px;

    h3 {
      width: 90%;
    }
  }
`;

export const StyledLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 30%;

  textarea {
    background-color: #FAFFFF;
    border: 1px solid #BFCCFB;
    line-height: 1.2;
    margin-bottom: 20px;
  }
`;

export const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  max-height: 50%;
  height: max-content;
  width: 100%;
  padding-left: 5px;
  margin-top: 5px;
`;

export const StyledCard = styled.div`
  display: flex;
  flex-direction: row wrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  width: 100%;
  height: 50px;
  border: 1px solid;
  border-color: ${props =>
    props.selected ? theme.colors.azure : theme.colors.green};
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  padding: 15px;
  margin-bottom: 10px;
  color: ${theme.colors.black55};
  ${props =>
    props.selected &&
    css`
      button {
        background-color: ${theme.colors.lightGreen} !important;
      }
    `};
`;

export const StyledWhatsappButton = styled(Button)`
  background-color: ${theme.colors.green};
  height: 21px;
  width: 95px;
  font-size: 13px;
  border: none;
  font-family: Open Sans;
  font-style: normal;

  :hover, :focus {
    opacity: 0.8;
    background: ${theme.colors.green};
  }
`;

export const StyledContactSubtitle = styled(StyledTitle)`
  margin: 18px 0 0 0;
  padding: 15px 0;
  border-top: 1px solid #EBEBEB;
`;

export const StyledBackButton = styled.button`
  background-color: transparent;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
  padding: 5px;
`;

export const StyledHeader = styled(StyledTitle)`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const Footer = styled.footer`
  background-color: ${theme.colors.azure};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px 5px 0px;
  width: 100%;
  flex-wrap: wrap;

  .social-media {
    display: flex;
    flex-direction: row;
    width: 150px;
    justify-content: space-between;
  }

  @media screen and (max-width: 750px) {
    justify-content: center;

    .social-media {
      margin-bottom: 15px;
      margin-top: 10px;
    }
  }
`;

export const StyledLink = styled.div`
  display: flex;
  align-items: flex;
  justify-content: center;
`;

export const StyledCSV = styled.button`
  border: none;
  font-size: 12px;
  width: max-content;
  padding: 4px 15px;
  background: linear-gradient(268.54deg, #E728A6 1.24%, #FA4242 51.02%, #FF7B2C 96.42%);
  height: 35px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:disabled {
    opacity: 0.8;
  }

  img {
    margin-right: 8px;    
  }

  a {
    text-decoration: none;
    color: white;
  }
`;

export const StyledCSVDescription = styled.div`
  font-size: 8px;
  margin-top: 7px;
  color: #CF2F5A;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  img {
    margin-right: 5px;
    width: 20px;
    height: 20px;
  }
`;

export const StyledRevenuesContainer = styled.div`
  
  color: white;
  display: flex;  
  padding: 10px;  
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .value {
    font-size: 14px;
    font-weight: 600;
  }

  .title {
    font-size: 11px;
    color: #B7E57B;
  }

  p {
    z-index: 2;
  }
`;

export const StyledRevenues = styled.img`
  position: absolute;
  width: 180px;
  height: 50px;  
`;

export const StyledEditContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-weight: 600;
    margin-right: 5px;
  }

  p {
    font-size: 12px;
    margin-top: 8px;
  }
`;

export const StyledFooterButtons = styled(StyledFlexContainer)`
  flex-wrap: wrap;
  width: max-content;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 560px) {
    width: 100%;
  }
`;

export const StyledCustomLink = styled.a`
  background-color: ${(props) => props.backgroundColor};
  padding: 0 15px;
  height: 31px;
  width: max-content;
  text-decoration: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 600;
  margin: 10px 30px;

  :hover, :focus {
    opacity: 0.8;
  }

  @media screen and (max-width: 560px) {
    width: 100%;
    margin: 5px 0;
  }
`;

export const StyledButtonCopy = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 50%;

  :hover {
    background-color:${theme.colors.lightGray}
  }
`;

export const StyledButtonModal = styled.button`
  cursor: pointer;  
  background-color: transparent;
  border: none;
  display: flex;
  align-items: right;
  justify-content: right;
  padding: 2px;
  border-radius: 50%;


  :hover {
    text-decoration: underline;
  }
`;

export const StyledCustomIcon = styled.img`
  width: 17px;
`;

export const StyledCheckIcon = styled.img`
  margin: 6px;
  width: 21px;
  height: 21px;
`;
