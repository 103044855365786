import styled from 'styled-components';
import { StyledFlexContainer } from 'styles/global';

export const StyledMainContainer = styled.div`
  padding: 15px;
  background-color: #FAFCFE;
  border-radius: 10px;
  border: 1px solid #E5F1FF;
  width: 100%;
  max-width: 400px;
  min-width: 350px;
  height: max-content;
  margin-top: 20px;
  position: sticky;
  top: 130px;

  @media (max-width: 900px) {
    max-width: unset;
    width: 100%;
    max-width: 850px;
  }
`;

export const StyledTitle = styled.h2`
  font-size: 16px;
  font-weight: 700;
  color: #5E636F;
  margin-bottom: 12px;
`;

export const StyledDiscoundContainer = styled.div`
  border-bottom: 1px solid #B0C6DA;
`;

export const StyledOperationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 100%;
`;

export const StyledNotApplied = styled.div`
  background-color: #FFE453;
  border: 1px solid #C79517;
  padding: 12px;
  color: #716525;
  font-size: 12px;
  border-radius: 4px;
  margin-bottom: 15px;
  font-weight: 600;
`;

export const StyledApplied = styled.div`
  background-color: #6C82D2;
  color: white;
  border-radius: 4px;
  padding: 12px;
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 12px;
`;

export const StyledTextApplied = styled(StyledFlexContainer)`
  margin-left: 15px;
  flex-direction: column;
  h3 {
    font-size: 11px;
    font-weight: 600;
  }

  p {
    font-weight: 300;
  }
`;

export const StyledRow = styled(StyledFlexContainer)`
  margin-top: 8px;
  justify-content: space-between;
  color: #636363;
  font-weight: ${(props) => props.fontWeight};
`;

export const StyledSum = styled(StyledFlexContainer)`
  flex-direction: column;
  border-bottom: 1px solid #B0C6DA;
  padding: 8px 0;
`;

export const StyledContinueButton = styled.button`
  background-color: ${(props) => props.backgroundcolor};
  color: white;
  border: 1px solid ${(props) => props.backgroundcolor};
  border-radius: 4px;
  width: 100%;
  height: 55px;
  margin-top: 15px;
  cursor: pointer;
  font-weight: 700;
  
  &:hover {
    opacity: 0.8
  }

  &:disabled {
    background-color: #CCCCCC;
    cursor: auto;
    border: none;

    &:hover {
      opacity: 1;
    }
  }
`;

export const StyledCustomLink = styled.a`
  background-color: ${(props) => props.color};
  padding: 0 15px;
  height: 55px;
  width: 365px;
  text-decoration: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-size: 13px;
  font-weight: 600;
  margin: 20px 0px 8px 0px;

  :hover, :focus {
    opacity: 0.8;
  }

  @media screen and (max-width: 560px) {
    width: 100%;
    margin: 5px 0;
  }
`;

export const StyledShowCase = styled.button`
  background-color: #4A588B;
  color: white;
  border: 1px solid #20966C;
  border-radius: 4px;
  width: 100%;
  height: 55px;
  margin-top: 25px;
  cursor: pointer;
  font-weight: 700;
  
  &:hover {
    opacity: 0.8
  }
`;

export const StyledLinePhases = styled.div`  
  width: 100%;
  border-bottom: 1px solid #D1D1D1;  
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: unset;  
`;

export const StyledDivPhases = styled.div`  
  width: 100%;  
  height: 100px;
`;

export const StyledContainerPhase = styled.div`
  position: relative;
  top: 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color};

  p {
    font-size: 13px;
  }
`;

export const StyledCirclePhase = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.color};
  border-radius: 100%;
  background-color: white;
`;