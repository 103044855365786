/* eslint-disable jsx-a11y/control-has-associated-label */
import ghostImage from 'assets/icons/ghost.svg';
import DataGrid from 'components/Datagrid';
import Button from 'components/Forms/Button';
import DatePicker from 'components/Forms/DatePicker';
import Input from 'components/Forms/Input';
import Label from 'components/Forms/Label';
import Pagination from 'components/Forms/newPagination';
import Modal from 'components/Modals/ModalLog';
import Text from 'components/Typografy/Text/index';
import moment from 'moment';
import ModalSendContact from 'pages/Customers/CreateCatalogModal';
import Ordenation from 'pages/SellerCatalog/Ordination';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { getLogs } from 'services/logs';
import theme from 'styles/theme';

import { skeletonLoadTable } from './skeleton';
import {
  StyledContainer,
  StyledTHead,
  StyledClickableTr,
  StyledTh,
  StyledSelectContainer,
  StyledRow,
  StyledFilters,
  StyledField,
  StyledLeftFilters,
  StyledJson,
  StyledGhostContainer
} from './styles';

const sizeOptions = [
  { value: 10, label: 'Mostrar 10 por vez' },
  { value: 20, label: 'Mostrar 20 por vez' },
  { value: 30, label: 'Mostrar 30 por vez' }
]

function SellerCatalog() {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openJson, setOpenJson] = useState(false);
  const [topic, setTopic] = useState('')
  const [json, setJson] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const { register, handleSubmit, getValues } = useForm();
  const [pageSize, setPageSize] = useState({ value: 10, label: 'Mostrar 10 por vez' });
  var paseSizeSelect = 10;

  const history = useHistory();

  const handleSetPageSize = (value) => {
    setPageSize(value);
    paseSizeSelect = value.value;
    onFormSubmit(getValues());
  };

  const handleSetPage = (page) => {
    paseSizeSelect = pageSize.value;
    onFormSubmit(getValues(), page);
  };

  const sortedItems = (key, direction) => {
    const sortableItems = [...logs];
    sortableItems.sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setLogs(sortableItems)
  };

  const setStates = (response) => {
    if (response?.data) {
      setLogs(response.data?.payload);

      if (response?.data?.pager) {
        setCurrentPage(response.data.pager?.currentPage);
        setTotalRows(response.data.pager?.totalRows);
        setTotalPages(response.data.pager?.totalPages);
      }

    } else {
      setLogs(null);
    }
    setLoading(false)
  }

  const onFormSubmit = async (filters, page = 1) => {
    const formData = {
      ...filters,
      topicId: topic?.id,
      pageSize: paseSizeSelect,
      pageIndex: page
    };
    setLoading(true);
    const response = await getLogs(formData);
    setStates(response);
  }

  useEffect(() => {
    onFormSubmit(getValues());
  }, []);

  const handleClick = (obj) => {
    setOpenJson(true);
    setJson(obj);
  }

  const tableBody = () => {
    if (logs?.length) {
      return logs?.map((log, index) => (
        <StyledClickableTr onClick={() => handleClick(log.messageJson)} key={`${log?.id}-${index}`}>
          <td>{log.topicId}</td>
          <td>{log.entityId}</td>
          <td>{moment(new Date(log?.createdAt)).format('DD/MM/YY, h:mm:ss a')}</td>
          <td>{log.referenceMessageId}</td>
          <td>{log.type}</td>
          <td>{log.message}</td>
        </StyledClickableTr>
      ))
    }
    return (
      (
        <StyledGhostContainer aria-colspan={5} className='ghost-image'>
          <img src={ghostImage} alt="Ilustração de fantasma" />
          <p>
            Não foram encontrados Logs para o filtro selecionado ou não há Logs disponíveis para este item. Tente mudar suas configurações de segmentação de consulta e filtrar novamente.
          </p>
        </StyledGhostContainer>
      )
    )
  }

  const handleJson = (obj) => {
    try {
      const isJson = JSON.parse(obj);
      return JSON.stringify(isJson, null, 4);
    } catch (e) {
      return 'JSON inválido.'
    }
  }

  const topicOptions = [
    { name: 'Todos', id: 'todos' },
    { name: 'Feedback', id: 'feedback' },
    { name: 'Fulfillment-status', id: 'fulfillment-status' },
    { name: 'Location', id: 'location' },
    { name: 'Order', id: 'order' },
    { name: 'Order-exception', id: 'order-exception' },
    { name: 'Price', id: 'price' },
    { name: 'Product', id: 'product' },
    { name: 'Sku', id: 'sku' },
    { name: 'Stock', id: 'stock' }
  ];

  return (
    <>
      <StyledContainer>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <StyledFilters>
            <StyledLeftFilters>
              <StyledField zIndex="1000" width="200px">
                <Label>Tópico</Label>
                <Select
                  name="topicId"
                  testId="topicId"
                  options={topicOptions}
                  value={topic}
                  register={register}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder="Selecione"
                  onChange={(value) => setTopic(value)}
                />
              </StyledField>
              <StyledField width="200px">
                <Label>Id Mensagem</Label>
                <Input
                  name="referenceMessageId"
                  testId="referenceMessageId"
                  placeholder="Pesquise..."
                  register={register}
                />
              </StyledField>
              <StyledField width="200px">
                <Label>Id Entidade</Label>
                <Input
                  name="entityId"
                  testId="entityId"
                  placeholder="Pesquise..."
                  register={register}
                />
              </StyledField>
              <StyledField zIndex="1000" width="250px">
                <Label>Data</Label>
                <DatePicker
                  name="beginsAt"
                  register={register}
                />
              </StyledField>
              <StyledField width="90px">
                <Button
                  type="button"
                  backgroundColor={theme.colors.black55}
                  outline
                  onClick={() => onFormSubmit(getValues())}
                >
                  FILTRAR
                </Button>
              </StyledField>
            </StyledLeftFilters>
          </StyledFilters>
        </form>
        <DataGrid minHeight="200px" className="catalog-table" width="100%" maxHeight="calc(100vh - 320px)" height="max-content" style={{ marginTop: '15px' }}>
          {logs?.length > 0 && (
            <colgroup>
              <col style={{ width: '10%', minWidth: '100px' }} />
              <col style={{ width: '10%', minWidth: '100px' }} />
              <col style={{ width: '20%', minWidth: '130px' }} />
              <col style={{ width: '20%', minWidth: '100px' }} />
              <col style={{ width: '10%', minWidth: '100px' }} />
              <col style={{ width: '30%', minWidth: '250px' }} />
            </colgroup>
          )}
          <StyledTHead>
            <tr>
              <StyledTh style={{ width: '10%' }} minWidth="100px">
                <p>TopicId</p>
                <Ordenation field="topicId" onClick={sortedItems} />
              </StyledTh>
              <StyledTh style={{ width: '10%' }} minWidth="100px">
                <p>EntityId</p>
                <Ordenation field="entityId" onClick={sortedItems} />
              </StyledTh>
              <StyledTh style={{ width: '20%' }} minWidth="100px">
                <p>Date</p>
                <Ordenation field="createdAt" onClick={sortedItems} />
              </StyledTh>
              <StyledTh style={{ width: '20%' }} minWidth="100px">
                <p>ReferenceMessageId</p>
                <Ordenation field="referenceMessageId" onClick={sortedItems} />
              </StyledTh>
              <StyledTh style={{ width: '10%' }} minWidth="100px">
                <p>Type</p>
                <Ordenation field="type" onClick={sortedItems} />
              </StyledTh>
              <StyledTh style={{ width: '30%' }} minWidth="250px">
                <p>Message</p>
                <Ordenation field="message" onClick={sortedItems} />
              </StyledTh>
            </tr>
          </StyledTHead>
          <tbody>
            {loading ? skeletonLoadTable() : tableBody()}          </tbody>
          {openJson && (
            <Modal
              showCloseButton
              minWidth="330px"
              width="100%"
              maxWidth="800px"
              height="500px"
              maxHeight="654px"
              padding={5}
              onCloseCallback={() => {
                setOpenJson(false)
                setJson()
              }}
              isVisible
              style={{ margin: '0px 20px' }}
              overflow="scroll"
            >
              <StyledJson>
                {handleJson(json)}
              </StyledJson>
            </Modal>
          )}
        </DataGrid>
        <StyledRow align="center">
          <Text>
            {`Mostrando ${totalRows >= (currentPage * (pageSize.value || 10)) ? (currentPage * (pageSize.value || 10)) : totalRows} de ${totalRows} resultados`}
          </Text>
          <Pagination totalCount={totalPages} pageSize={pageSize.value} onPageChange={(page) => handleSetPage(page)} currentPage={currentPage} />
          <StyledSelectContainer>
            <Select
              name="pageSize"
              inputId="pageSize"
              options={sizeOptions}
              value={pageSize}
              menuPlacement='auto'
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              onChange={(value) => handleSetPageSize(value)}
            />
          </StyledSelectContainer>
        </StyledRow>
        {openModal && (
          <ModalSendContact
            onCloseCallback={() => setOpenModal(!openModal)}
            isCreate
          />
        )}
      </StyledContainer>
    </>
  );
}

SellerCatalog.propTypes = {
};

SellerCatalog.defaultProps = {
};

export default SellerCatalog;
