import styled from 'styled-components';
import { Col, Container } from 'react-grid-system';

export const StyledStatus = styled.div`
  padding: 5px;
  font-size: 10px;
  min-width: 70px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledMainContent = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 12px;

  @media screen and (max-width: 775px) {
    flex-wrap: wrap;
  }
`;

export const StyledFilters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 12px;

  @media screen and (max-width: 1545px) {
    flex-wrap: wrap;
  }
`;

export const StyledLeftFilters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;

  @media screen and (max-width: 775px) {
    flex-wrap: wrap;
  }
`;


export const StyledField = styled.div`
  width: ${(props) => props.width};
  z-index: ${(props) => props.zIndex};
  margin-right: 20px;
  margin-bottom: 10px;

  @media screen and (max-width: 775px) {
    width: 100%;
    margin-right: 0;
    margin-top: ${(props) => props.marginTop};
  }
`;

export const StyledGhostContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
  margin-top: 80px;

  p {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: flex-end;
      text-align: center;
      color: #828282;
      width: 360px;
      margin: 15px;
    }
`;

export const StyledRow = styled.div`
  width: 100%;
  margin-top: 30px;
  padding-bottom: 30px;
  display: flex;
  align-items: center !important;
  justify-content: space-between !important;

  p {
    text-align: center;
  }

  @media screen and (max-width: 930px) {
    flex-direction: column;
  }
`;

export const StyledSelectContainer = styled.div`
  width: 220px;
  font-size: 12px;

  @media screen and (max-width: 930px) {
    width: 400px;
  }

  @media screen and (max-width: 775px) {
    width: 100%;
  }
`;

export const StyledContainer = styled.div`
  max-width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  a {
    color: unset;
  }
`;