import produce from 'immer';

export const Types = {
  ADD_SETTING: 'SETTING/ADD_SETTING'
};

const INITIAL_STATE = {
  data: {},
};

export default function Setting(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.ADD_SETTING: {
      return {
        data: action,
      };
    }
    default:
      return state;
  }
}