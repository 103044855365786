import React from 'react';

import ImageNotFound from '../../assets/images/404.svg'
import {
  StyledContainer,
  StyledContent,
  } from './styles';
  
function NotFound() {
    return (
      <StyledContainer>
        <StyledContent>
          <img src={ImageNotFound} alt='Página não encontrada' />
          <p>
            <b>Ops! </b>
            Parece que a página que você está tentando acessar não existe...
          </p>
        </StyledContent>
      </StyledContainer>
    );
}

export default NotFound;