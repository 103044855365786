const baseVersion = '0.1.0';

export const storageKeys = {
    STORE: 'store',
    SELLER: 'seller'
};

const smartSalesSessionStorage = {
    setItem: (key, value, version = baseVersion) => {
        sessionStorage.setItem(`@smartsales/v${version}/${key}`, JSON.stringify(value));
    },
    getItem: (key, version = baseVersion) => {
        const value = sessionStorage.getItem(`@smartsales/v${version}/${ key }`);
        if (value) {
            return JSON.parse(value);
        }
        return null;
    },
    clean: () => {
        sessionStorage.clear();
    }
};

export default smartSalesSessionStorage;