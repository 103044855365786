import styled from 'styled-components';
import { StyledFlexContainer } from 'styles/global';

export const StyledContainer = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const StyledButtonA = styled.button`
  width: 75px;
  height: 35px;
  background-color: #3D7981;
  border-radius: 4px;
  justify-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 3px 15px;
  cursor: pointer;
  align-self: flex-end;
  margin-top: 25px;
  color: white;
  font-weight: 600;
  font-size: 10px;
`;

export const StyledSelectContainer = styled(StyledFlexContainer)`
  width: 100%;
  margin-top: 15px;
  font-size: 12px;
`;
