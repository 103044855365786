import React from 'react';

import ImageRestrictedAccess from '../../assets/images/restrito.svg'
import {
  StyledContainer,
  StyledContent,
  } from './styles';
  
function NotFound() {
    return (
      <StyledContainer>
        <StyledContent>
          <img src={ImageRestrictedAccess} alt='Acesso Restrito' />
          <p>
            <b>Ops! </b>
            Parece que você não tem permissão para acessar está página...
          </p>
        </StyledContent>
      </StyledContainer>
    );
}

export default NotFound;