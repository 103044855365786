import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import LogModal from '../LogModal';
import DetailsModal from '../Modal/index';
import { StyledTr, StyledButton } from './styles';

const TableRow = ({
  children,
  pedido,
  resetFiltersCallback,
}) => {
  const [showModalState, setShowModalState] = useState(false);
  const [showModalLog, setShowModalLog] = useState(false);

  function resetShowModalStateHandler() {
    setShowModalState(false);
    setShowModalLog(false);
    resetFiltersCallback();
  }

  function onShowModalVisible() {
    setShowModalState(!showModalState);
  }

  const showModal = () => (
    <DetailsModal pedido={pedido} onCloseCallback={resetShowModalStateHandler} />
  );

  const showLog = () => (
    <LogModal pedido={pedido} onCloseCallback={resetShowModalStateHandler} />
  );

  const handleLog = (e) => {
    e.stopPropagation();
    setShowModalLog(true);
  }

  return (
    <>
      <StyledTr onClick={() => onShowModalVisible()}>
        {children}
        <td>
          <StyledButton type="button" onClick={(e) => handleLog(e)}>
            Ver log
          </StyledButton>
        </td>
      </StyledTr>
      {showModalState && showModal()}
      {showModalLog && showLog()}
    </>
  );
};

TableRow.propTypes = {
  children: PropTypes.any,
  resetFiltersCallback: PropTypes.func,
};

TableRow.defaultProps = {
  resetFiltersCallback: () => null,
  children: <p>a</p>,
};

export default withTheme(TableRow);
