import PropTypes from 'prop-types';
import React from 'react';
import theme from 'styles/theme';

import { StyledLabel } from './styles';

const Label = ({ children, fontSize, color, htmlFor, marginLeft, ...rest }) => (
  <StyledLabel
    fontSize={fontSize}
    color={color}
    htmlFor={htmlFor}
    marginLeft={marginLeft}
    {...rest}
  >
    {children}
  </StyledLabel>
);

Label.propTypes = {
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string,
  fontSize: PropTypes.number,
  color: PropTypes.string,
};

Label.defaultProps = {
  fontSize: theme.fontSize._12px,
  color: theme.colors.black70,
  marginLeft: '0px',
  htmlFor: '',
};

export default Label;
