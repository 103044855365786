import PropTypes from 'prop-types';
import React from 'react';

import { StyledOptionContainer, StyledRadioContainer, StyledExternalCircle, StyledInternCircle, StyledImage, StyledTitle, StyledSubtitle, StyledDeadLine, StyledMainContent } from './styles';

function DeliveryOption({ selected, available, internalInfos, handleChange }) {

  const handleClick = () => {
    handleChange(internalInfos);
  }

  return (
    <StyledOptionContainer disabled={!available} type="button" onClick={() => handleClick()}>
      <StyledRadioContainer>
        <StyledExternalCircle>
          {selected && <StyledInternCircle />}
        </StyledExternalCircle>
      </StyledRadioContainer>
      <StyledMainContent>
        <StyledImage src={internalInfos.image} alt="ícone do delivery" />
        <StyledTitle>
          {internalInfos.title}
        </StyledTitle>
        <StyledSubtitle>
          {internalInfos.price === 0 || internalInfos.price == undefined ? 'GRÁTIS' : (
            Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(internalInfos.price)
          )}
        </StyledSubtitle>
        <StyledDeadLine>
          {internalInfos.deadline}
        </StyledDeadLine>
      </StyledMainContent>
    </StyledOptionContainer>
  );
};

DeliveryOption.propTypes = {
  selected: PropTypes.bool,
  available: PropTypes.bool,
  internalInfos: PropTypes.shape({
    id: PropTypes.number,
    image: PropTypes.string,
    price: PropTypes.string,
    title: PropTypes.string,
    deadline: PropTypes.string
  }),
  handleChange: PropTypes.func
};

DeliveryOption.defaultProps = {
  selected: false,
  available: false,
  internalInfos: PropTypes.shape({
    id: '0',
    image: '',
    price: 'R$ 00,00',
    title: 'Tipo da entrega',
    deadline: 'Até dia 00 do mês 0'
  }),
  handleChange: () => { }
};

export default DeliveryOption;
