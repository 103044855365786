// import { debounce } from '@redux-saga/core/effects';
import { all, takeLatest, debounce } from 'redux-saga/effects';

import { Types as ProductsTypes } from '../ducks/Catalog/Products';
import { Types as CepTypes } from '../ducks/Cep';
import { Types as CustomerTypes } from '../ducks/Customer';
import { Types as ShowcaseTypes } from '../ducks/Showcase';
import { Types as StoreAddressTypes } from '../ducks/StoreAddress';
import { getProducts } from './Catalog/Products';
import { getCep } from './Cep';
import { getCustomer } from './Customer';
import { getShowcase } from './Showcase';
import { getStoreAddress } from './StoreAddress';


export default function* rootSaga() {
  yield takeLatest(ShowcaseTypes.GET_SHOWCASE, getShowcase);
  yield takeLatest(CustomerTypes.GET_CUSTOMER, getCustomer);
  yield takeLatest(CepTypes.GET_CEP, getCep);
  yield takeLatest(StoreAddressTypes.GET_STORE_ADDRESS, getStoreAddress);
  yield debounce(2000, ProductsTypes.GET_PRODUCTS, getProducts);
}
