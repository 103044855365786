import styled from 'styled-components';

export const StyledCardContainer = styled.button`
  margin-right: 15px;
  border: 1px solid ${(props) => props.selected ? props.color : '#CCCCCC'};
  border-radius: 4px;
  width: 165px;
  height: 65px;
  color: ${(props) => props.selected ? props.color : '#828282'};
  font-weight: 600;
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: transparent;
  cursor: pointer;
  position: relative;

  img {
    margin-top: 5px;
  }

  &.pix {
    img {
      width: 20px;
    }
  }
`;

export const StyledSelected = styled.img`
  position: absolute;
  top: -10px;
  right: -10px;  
  color: ${(props) => props.color};
`;