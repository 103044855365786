import Button from 'components/Forms/Button';
import DatePicker from 'components/Forms/DatePicker';
import Label from 'components/Forms/Label';
import Loader from 'react-loader-spinner';
import React, { useMemo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getInsights } from 'services/insights';
import { StyledFlexContainer } from 'styles/global';
import theme from 'styles/theme';
import VerticalChartBar from './ChartBarStacked';
import HorizontalChartBar from './HorinzontalBar';
import StackedChart from './StackedBar';
import {
  StyledMainContainer,
  StyledLabel,
  StyledValue,
  StyledChartLabel,
  StyledFilterDate,
  StyledNoChartContainer,
  StyledLoadingContainer,
  StyledGenericBoard,
  StyledBoardFull,
  StyledFilters,
  StyledLeftFilters,
  StyledField,
} from './styles';

let colorIndex = -1;

function Insights() {
  const { register, handleSubmit, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const [insights, setInsights] = useState([]);

  const setStates = (response) => {
    if (response?.data) {
      setInsights(response.data?.payload);
    }
  }

  const onFormSubmit = async (filters) => {
    const formData = { ...filters, };
    setLoading(false);
    const response = await getInsights(formData);
    setStates(response);
    setLoading(true);
  }

  useEffect(() => {
    onFormSubmit(getValues());
  }, []);

  const formatData = (unformatedData) => {
    return (
      [{
        label: unformatedData.unitRight,
        year: '2021',
        data: unformatedData.data,
        color: '#ffff4d'
      }]
    )
  }

  const paymentColors = [
    '#26CEC2',
    '#3D7981',
    '#ED5A63'
  ]

  const colors = [
    '#3D7981',
    '#ED5A63',
    '#76A739',
    '#411E5A',
    '#FFA41B'
  ];

  const formatPaymentData = (unformatedData) => {
    return unformatedData.map((payment, indexColor) => ({
      ...payment,
      color: paymentColors[indexColor]
    }))
  }

  return (
    (
      <>
        <StyledFilters>
          <StyledLeftFilters>
            <StyledField zIndex="1000" width="250px">
              <Label>Selecione o período de datas</Label>
              <DatePicker name="beginsAt" register={register} />
            </StyledField>
            <StyledField width="90px">
              <Button type="button" backgroundColor={theme.colors.black55} outline onClick={() => onFormSubmit(getValues())}>
                FILTRAR
              </Button>
            </StyledField>
          </StyledLeftFilters>
        </StyledFilters>
        {loading ? (
          <StyledMainContainer>
            {insights?.map((item, index) => {
              if (item?.type === 'value') {
                if (index === 0) {
                  return (
                    <StyledNoChartContainer className='revenues'>
                      <StyledBoardFull>
                        <div>
                          <StyledLabel color="#FFF">{item?.title}</StyledLabel>
                          <StyledValue color="#FFF">{item?.value}</StyledValue>
                        </div>
                        <StyledFilterDate />
                      </StyledBoardFull>
                    </StyledNoChartContainer>
                  );
                }
                return (
                  <StyledNoChartContainer>
                    <StyledBoardFull>
                      <div>
                        <StyledLabel>{item?.title}</StyledLabel>
                        <StyledValue>{item?.value}</StyledValue>
                        <StyledLabel>
                          De um total de
                          {' '}
                          <span>{item?.totalValue}</span>
                        </StyledLabel>
                      </div>
                      <StyledFilterDate />
                    </StyledBoardFull>
                  </StyledNoChartContainer>
                );
              }

              if (item?.type === 'bar') {
                if (colorIndex === colors.length) {
                  colorIndex = -1;
                }
                colorIndex += 1;
                return (
                  <StyledGenericBoard key={index}>
                    <StyledFlexContainer direction="column">
                      <StyledLabel>{item?.title}</StyledLabel>
                      <StyledValue>{item?.value}</StyledValue>
                      <StyledChartLabel>ÚLTIMOS 12 MESES</StyledChartLabel>
                    </StyledFlexContainer>
                    <VerticalChartBar data={formatData(item)} defaultColors={[colors[colorIndex]]} />
                    <StyledFilterDate />
                  </StyledGenericBoard>
                );
              }
              if (item?.type === 'sector') {
                return (
                  <StyledGenericBoard>
                    <StyledLabel>{item?.title}</StyledLabel>
                    <StackedChart data={formatPaymentData(item?.data)} />
                    <StyledFilterDate />
                  </StyledGenericBoard>
                );
              }
              if (item?.type === 'rank') {
                return (
                  <StyledGenericBoard key={index}>
                    <StyledLabel>{item?.title}</StyledLabel>
                    <HorizontalChartBar data={item?.data} total="100" />
                    <StyledFilterDate />
                  </StyledGenericBoard>
                );
              }
              return null;
            })}
          </StyledMainContainer>) :
          (
            <StyledLoadingContainer>
              <Loader type="Oval" color={theme.colors.lightGreen} height={40} width={40} timeout={0} />
            </StyledLoadingContainer>
          )}
      </>
    )
  );
}

Insights.propTypes = {
};

Insights.defaultProps = {
};

export default Insights;
