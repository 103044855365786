import styled from 'styled-components';

export const StyledLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: auto;
  margin-left: ${prop => prop.marginLeft};
  margin-bottom: 3px;
  width: 100%;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  color: ${props => props.color};

  font-size: ${props => props.fontSize}rem;
  font-weight: 600;

  .asterisk {
    color: red;
    margin-left: 5px;
  }
`;
