import Text from 'components/Typografy/Text/index';
import SelectStoreModal from 'Layout/SelectStoreModal/index';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTheme } from 'styled-components';

const SelectStoreButton = ({
  label
}) => {
  const [showModalState, setShowModalState] = useState(false);

  function resetShowModalStateHandler() {
    setShowModalState(false);
  }

  function onShowModalVisible() {
    setShowModalState(!showModalState);
  }

  const showModal = () => (
    <SelectStoreModal onCloseCallback={resetShowModalStateHandler} />
  );

  return (
    <>
      {showModalState && showModal()}
      <Text color="#3D7981" onClick={() => onShowModalVisible()} textAlign="center" fontWeight="600" fontSize="0.7" cursor="pointer" >
        {label}
      </Text>
    </>
  );
};

SelectStoreButton.propTypes = {
  label: PropTypes.string,
};

SelectStoreButton.defaultProps = {
  label: ''
};

export default withTheme(SelectStoreButton);
