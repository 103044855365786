import PropTypes from 'prop-types';
import React from 'react';

import { StyledInputComponent } from './styles';

function Input({
  name,
  height,
  register,
  required,
  testId,
  width,
  type,
  onChange,
  placeholder,
  ...rest
}) {
  return (
    <StyledInputComponent
      type={type}
      required={required}
      placeholder={placeholder}
      name={name}
      ref={register}
      dataTestId={testId}
      width={width}
      height={height}
      onChange={onChange}
      {...rest}
    />
  );
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  register: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  width: PropTypes.string,
};

Input.defaultProps = {
  width: '100%',
  required: false,
};

export default Input;
