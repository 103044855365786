import postmon from './cep-postmon';
import viacep from './cep-viacep';

async function getCepPostmon(cep) {
  const response = await postmon.get(`${cep}`);
  return {
    data: {
      district: response.data.bairro,
      street: response.data.logradouro,
      city: response.data.cidade,
      state: response.data.estado
    }
  }
}

async function getCepViaCep(cep) {
  const response = await viacep.get(`${cep}/json`)
  return {
    data: {
      district: response.data.bairro,
      street: response.data.logradouro,
      city: response.data.localidade,
      state: response.data.uf
    }
  }
}

export async function getCep(cep) {
  // return getPostmonViaCep(cep);
  return getCepViaCep(cep);
}
