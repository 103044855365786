import PropTypes from 'prop-types';
import React from 'react';
import { withTheme } from 'styled-components';

import theme from 'styles/theme';
import { IconStyledComponent } from 'components/Icon/style';

const Icon = ({
  color,
  icon,
  hoverColor,
  width,
  height,
  onClick,
  pointer,
  mgRight,
  ...rest
}) => {
  return (
    <IconStyledComponent
      icon={icon}
      pointer={pointer}
      color={color}
      hoverColor={hoverColor}
      width={width}
      height={height}
      mgRight={mgRight}
      onClick={onClick}
      {...rest}
    />
  );
};

Icon.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

Icon.defaultProps = {
  onClick: () => {},
  color: theme.colors.black55,
  hoverColor: theme.colors.black85,
  width: '100%',
  height: '100%',
};

export default withTheme(Icon);
