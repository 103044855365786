import Footer from 'components/Footer';
import Header from 'components/Header';
import RedirectExternal from 'components/RedirectNotFound/redirectExternal';
import RedirectInternal from 'components/RedirectNotFound/redirectInternal';
import WhatsAppIcon from 'components/WhatsAppIcon';
import BackOfficeHeader from 'Layout/BackOfficeHeader';
import SideBar from 'Layout/SideBar';
import Catalog from 'pages/Catalog';
import Customers from 'pages/Customers';
import Details from 'pages/Details';
import Insights from 'pages/Insights';
import Login from 'pages/Login';
import Logs from 'pages/Logs';
import NewCheckout from 'pages/NewCheckout';
import NotFound from 'pages/NotFound';
import Requests from 'pages/Orders';
import RestrictedAccess from 'pages/RestrictedAccess';
import SellerCatalog from 'pages/SellerCatalog';
import Settings from 'pages/Settings';
import Showcase from 'pages/Showcase';
import SpecificSellerCatalog from 'pages/SpecificSellerCatalog';
import WhatsChat from 'pages/WhatsChat';
import React, { useEffect, useState } from 'react';
import { Route, Router, Switch, useRouteMatch, useParams } from 'react-router-dom';
import api, { getContentUrl } from 'services/api';
import { getVisualIdentity } from 'services/contact-service';
import history from 'services/history-services';
import { Types as SettingTypes } from '../store/ducks/Setting';
import { useDispatch } from 'react-redux';

function Routes() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path={['/']} component={BackofficeLayout} />
        <Route path={['/login', '/customers', '/404', '/pedidos', '/configuracoes', '/catalog', '/insights']} component={BackofficeLayout} />
        <Route path="/restrito" component={RestrictedAccess} />
        <Route path={['/:store']} component={ExternalLayout} />
        <Route path="*" component={RedirectInternal} />
      </Switch>
    </Router>
  );
}

const BackOfficeHeaderComponent = (path, openSidebar, setOpenSidebar) => {
  return (
    <BackOfficeHeader title={`${path}`} handleClick={() => setOpenSidebar(!openSidebar)} />
  );
};

const BackofficeLayout = () => {
  const [openSidebar, setOpenSidebar] = useState(false);

  return (
    <>
      <Switch>
        <Route exact path="/" component={() => BackOfficeHeaderComponent('', openSidebar, setOpenSidebar)} />
        <Route path="/login" component={() => BackOfficeHeaderComponent('Login', openSidebar, setOpenSidebar)} />
        <Route path="/customers" component={() => BackOfficeHeaderComponent('Clientes', openSidebar, setOpenSidebar)} />
        <Route path="/configuracoes/logs" component={() => BackOfficeHeaderComponent('Configurações / Logs', openSidebar, setOpenSidebar)} />
        <Route path="/configuracoes" component={() => BackOfficeHeaderComponent('Configurações', openSidebar, setOpenSidebar)} />
        <Route path="/404" component={() => BackOfficeHeaderComponent('404', openSidebar, setOpenSidebar)} />
        <Route path="/pedidos" component={() => BackOfficeHeaderComponent('Pedidos', openSidebar, setOpenSidebar)} />
        <Route path="/insights" component={() => BackOfficeHeaderComponent('Insights', openSidebar, setOpenSidebar)} />
        <Route exact path="/catalog" component={() => BackOfficeHeaderComponent('Catálogos', openSidebar, setOpenSidebar)} />
        <Route path="/catalog/:id" component={(props) => BackOfficeHeaderComponent('Catálogo #' + props.match.params.id, openSidebar, setOpenSidebar)} />
      </Switch>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ height: '100%' }}>
          <SideBar openSidebar={openSidebar} />
        </div>
        <div style={{ width: '100%', paddingLeft: '60px', }}        >
          <Switch>
            <Route exact path="/" component={() => <SellerCatalog />} />
            <Route path="/login" component={() => <Login />} />
            <Route path="/customers" component={() => <Customers />} />
            <Route path="/configuracoes/logs" component={() => <Logs />} />
            <Route path="/configuracoes" component={() => <Settings />} />
            <Route path="/404" component={() => <NotFound />} />
            <Route path="/pedidos" component={() => <Requests />} />
            <Route path="/insights" component={() => <Insights />} />
            <Route exact path="/catalog" component={() => <SellerCatalog />} />
            <Route path="/catalog/:id" component={() => <SpecificSellerCatalog />} />
          </Switch>
        </div>
      </div>
    </>
  );
};

const ExternalLayout = () => {
  const [settings, setSettings] = useState({});
  const [logo, setLogo] = useState({});
  const { store } = useParams();

  const getLogo = async () => {
    try {
      const logoResp = await getContentUrl(store, 'logo');
      setLogo(logoResp);
    } catch (error) {
      setLogo(null);
    }
  }

  const dispatch = useDispatch();

  const getVisual = async () => {
    const visualIdentity = await getVisualIdentity(store);
    setSettings({ ...visualIdentity.data });
    dispatch({ type: SettingTypes.ADD_SETTING, ...visualIdentity.data });
  }

  useEffect(() => {
    getVisual();
    getLogo();
  }, []);

  return (
    <>
      <Header backgroundColor={settings?.mainColor} logo={logo} bagColor={settings?.bagColor} ButtonColor={settings?.buttonColor} />
      <Switch>
        <Route path="/:store" component={() => SalesLayout(settings)} />
      </Switch>
      <Footer backgroundColor={settings?.secondaryColor} logo={logo} bagColor={settings?.bagColor} footerMessage={settings?.footerMessage} />
    </>
  );
};

const SalesLayout = () => {
  const { path, params } = useRouteMatch();

  api.defaults.headers.store = params.store;

  return (
    <>
      <WhatsAppIcon />
      <div style={{ minHeight: 'calc(100vh - 134px)', }}>
        <Switch>
          <Route path={`${path}/checkout`} component={NewCheckout} />
          <Route path={`${path}/catalogo/:id`} component={Catalog} />
          <Route path={`${path}/detalhes/:id`} component={Details} />
          <Route path={`${path}/vitrine`} component={Showcase} />
          <Route path={`${path}/404`} component={NotFound} />
          <Route path={`${path}/*`} component={RedirectExternal} />
        </Switch>
      </div>
    </>
  );
};

export default Routes;