import styled from 'styled-components';

export const StyledMainBar = styled.div`
  width: 100%;
  height: 17px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content:space-between;
  flex-direction: row;
  margin: 23px 0;
`;

export const StyledPercentage = styled.div`
  width: ${(props) => props.width};
  background-color: ${(props) => props.color};
  height: 17px;
  border-radius: ${(props) => props.radius};
  filter: ${(props) => props.focus ? 'brightness(70%)' : ''} ;

  :hover {
    filter: brightness(75%); 
  }
`;

export const StyledLabel = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  background-color: ${(props) => props.focus ? '#e9ecec' : ''} ;
  border-radius: 50px;
  padding: 5px;
  margin: 5px 0;
`;

export const StyledColor = styled.div`
  background-color: ${(props) => props.color};
  height: 14px;
  width: 14px;
  border-radius: 100%;
`;

export const StyledText = styled.p`
  color: #666666;
  margin-left: 15px;
  font-weight: ${(props) => props.weight};
`; 