import { combineReducers } from 'redux';

import Products from './Catalog/Products';
import CatalogId from './CatalogId';
import Cep from './Cep';
import Checkout from './Checkout';
import Customer from './Customer';
import Nsu from './Nsu';
import Setting from './Setting';
import Showcase from './Showcase';
import StoreAddress from './StoreAddress';

export default combineReducers({
  Nsu,
  Setting,
  Checkout,
  CatalogId,
  Showcase,
  Customer,
  Products,
  Cep,
  StoreAddress
});
