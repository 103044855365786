import styled from 'styled-components';

export const IconStyledComponent = styled.div`
    display: inline-block;
    width: ${props => props.width};
    height: ${props => props.height};
    transition: background 0.25s;
    
    background: ${props => props.color};
    
    mask-image: url(${props => props.icon});
    mask-repeat: no-repeat;
    mask-position: 0 0;
    mask-size: contain;

    cursor: pointer;
    
    &:hover, &:focus {
        background: ${props => props.hoverColor};
    }
`;