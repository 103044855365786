import ghostImage from 'assets/icons/ghost.svg';
import DataGrid from 'components/Datagrid';
import Button from 'components/Forms/Button';
import DatePicker from 'components/Forms/DatePicker';
import Label from 'components/Forms/Label';
import Pagination from 'components/Forms/newPagination';
import Text from 'components/Typografy/Text/index';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import * as RequestContext from 'services/requests-services';
import * as SellersServices from 'services/sellers-services';
import theme from 'styles/theme';
import { formatDate } from 'utils/util-date';

import { skeletonLoadTable, handleStatus, handleStatusB } from './funcs';
import { StyledStatus, StyledField, StyledRow, StyledSelectContainer, StyledContainer, StyledFilters, StyledGhostContainer, StyledLeftFilters } from './styles';
import TableRow from './TableRow';

const sizeOptions = [
  { value: 10, label: 'Mostrar 10 por vez' },
  { value: 20, label: 'Mostrar 20 por vez' },
  { value: 30, label: 'Mostrar 30 por vez' }
]

const Requests = () => {

  const [dateRange, setDateRange] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [seller, setSeller] = useState();
  const [sellersOptions, setSellersOptions] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusOptions, setStatusOptions] = useState([]);
  const [status, setStatus] = useState();
  const [modalityOptions, setModalityOptions] = useState([]);
  const [modality, setModality] = useState();
  const [pageSize, setPageSize] = useState({ value: 10, label: 'Mostrar 10 por vez' });
  var paseSizeSelect = 10;

  const handleSetPageSize = (value) => {
    setPageSize(value);
    paseSizeSelect = value.value;
    submit();
  };

  const handleSetPage = (page) => {
    paseSizeSelect = pageSize.value;
    submit(page);
  };

  const getStatus = async () => {
    const response = await RequestContext.getRequestStatus();
    setStatusOptions([{ name: 'Todos', id: '0' }, ...response.data.payload]);
  };

  const getShipping = async () => {
    const response = await RequestContext.getRequestsShipping();
    setModalityOptions([{ name: 'Todos', id: '0' }, ...response.data.payload]);
  };

  useEffect(() => {
    getStatus();
    getShipping();
    getSellersData();
  }, []);

  useEffect(() => {
    submit();
  }, []);

  const getSellersData = async () => {
    const response = await SellersServices.getSellers();
    setSellersOptions([{
      name: 'Todos',
      id: ''
    }, ...response?.data?.payload]);
  }

  useMemo(() => {
    if (!status || !modality || !dateRange) {
      setCurrentPage(1);
    }
  }, [status, modality, dateRange]);

  const submit = async (page) => {
    setLoading(true);

    const filters = {
      status: status && Number(status?.id),
      shippingType: modality && Number(modality?.id),
      orderDateFrom: dateRange?.startDate,
      orderDateTo: dateRange?.endDate,
      seller: seller?.id,
      pageSize: paseSizeSelect,
      pageIndex: page
    };

    const response = await RequestContext.getRequestsAll(filters);

    if (response.data.payload) {
      setProducts(response.data.payload);
      setTotalPages(response.data.pager.totalPages);
      setCurrentPage(response.data.pager.currentPage);
      setTotalRows(response.data.pager.totalRows);
    } else {
      setTotalPages(0);
      setCurrentPage(1);
      setProducts([]);
    }
    setLoading(false);
  };

  function onFormSubmit(e) {
    e.preventDefault();
    submit();
  }

  return (
    <>
      <StyledContainer fluid xs sm md lg xl>
        <form>
          <StyledFilters>
            <StyledLeftFilters>
              <StyledField width="200px">
                <Label htmlFor="orderStatus">Selecione o status</Label>
                <Select
                  name="order-status"
                  inputId="orderStatus"
                  options={statusOptions}
                  value={status}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder="Selecione"
                  onChange={(value) => setStatus(value)}
                />
              </StyledField>
              <StyledField width="200px">
                <Label htmlFor="orderModality">Tipo do pedido</Label>
                <Select
                  name="order-modality"
                  inputId="orderModality"
                  options={modalityOptions}
                  value={modality}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder="Selecione"
                  onChange={(value) => setModality(value)}
                />
              </StyledField>
              <StyledField width="250px">
                <Label>Período do pedido</Label>
                <DatePicker onChange={setDateRange} name="buyPeriod" height="35px" />
              </StyledField>
              <StyledField width="200px" >
                <Label htmlFor="customerSeller">Vendedor</Label>
                <Select
                  name="customer-seller"
                  inputId="customerSeller"
                  options={sellersOptions}
                  value={seller}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder="Selecione"
                  onChange={(value) => setSeller(value)}
                />
              </StyledField>
              <StyledField width="90px" marginTop="20px">
                <Button type="button" backgroundColor={theme.colors.black55} outline onClick={e => onFormSubmit(e)}>
                  FILTRAR
                </Button>
              </StyledField>
            </StyledLeftFilters>
          </StyledFilters>
        </form>
        <DataGrid width="100%" height="unset" maxHeight="calc(100vh - 300px)" style={{ marginTop: '15px', marginBottom: '0px' }} >
          {products.length > 0 && (
            <>
              <colgroup>
                <col style={{ width: '5%', minWidth: '15px' }} />
                <col style={{ width: '20%', minWidth: '230px' }} />
                <col style={{ width: '10%', minWidth: '100px' }} />
                <col style={{ width: '7%', minWidth: '20px' }} />
                <col style={{ width: '20%', minWidth: '100px' }} />
                <col style={{ width: '10%', minWidth: '100px' }} />
                <col style={{ width: '10%', minWidth: '100px' }} />
                <col style={{ width: '10%', minWidth: '130px' }} />
                <col style={{ width: '10%', minWidth: '100px' }} />
              </colgroup>
              <thead style={{ textAlign: 'left', position: 'sticky', top: '0' }}>
                <tr>
                  <th>Número</th>
                  <th>Pedido</th>
                  <th>Vendedor</th>
                  <th>Qtde. Itens</th>
                  <th>Endereço de Entrega/Retirada</th>
                  <th>Data</th>
                  <th>Valor Total</th>
                  <th>Status</th>
                  <th>Integração</th>
                </tr>
              </thead>
            </>
          )}
          <tbody>
            {loading ? skeletonLoadTable() : products.length > 0 ? (
              products.map((item, index) => (
                <TableRow key={`${item?.id}-${index}`} pedido={item}>
                  <td>{item.id}</td>
                  <td>
                    {`Pedido ${item.reference}`}
                    {item.CustomerName}
                    <br />
                    {item.email}
                  </td>
                  <td>{item.sellerName}</td>
                  <td>{item.units}</td>
                  <td>
                    {item.address}
                    <br />
                    {`CEP ${item.cep} ${item.city}/${item.state}`}
                  </td>
                  <td>{formatDate(item.date)}</td>
                  <td>
                    {item &&
                      Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(item.netValue || 0)}
                  </td>
                  <td>
                    <StyledStatus style={{ color: handleStatus(item.status), backgroundColor: handleStatusB(item.status), }}>
                      {item.status}
                    </StyledStatus>
                  </td>
                </TableRow>
              ))
            ) : (
              <tr>
                <StyledGhostContainer className='ghost-image'>
                  <img src={ghostImage} alt="Ilustração de fantasma" />
                  <p>Não foram encontrados pedidos para o filtro selecionado</p>
                </StyledGhostContainer>
              </tr>
            )}
          </tbody>
        </DataGrid>
        <StyledRow align="center">
          <Text>
            {`Mostrando ${totalRows >= (currentPage * (pageSize.value || 10)) ? (currentPage * (pageSize.value || 10)) : totalRows} de ${totalRows} resultados`}
          </Text>
          <Pagination totalCount={totalPages} pageSize={pageSize} onPageChange={(page) => handleSetPage(page)} currentPage={currentPage} />
          <StyledSelectContainer>
            <Select
              name="pageSize"
              inputId="pageSize"
              req
              options={sizeOptions}
              value={pageSize}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              menuPlacement='auto'
              onChange={(value) => handleSetPageSize(value)}
            />
          </StyledSelectContainer>
        </StyledRow>
      </StyledContainer>
    </>
  );
};

Requests.propTypes = {
};

Requests.defaultProps = {
};

export default Requests;
