import SmartLogo from 'assets/icons/linx.svg';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';

import bag from '../../assets/IconsSvg/bag.svg';
import Icon from '../Icon';
import LittleCard from '../LittleCar';
import {
  HeaderStyledComponent,
  StyledIcons,
  StyledBag,
  StyledCount,
  HeaderStyleBody,
  StyledLogo
} from './styles';

const Header = ({ width, height, backgroundColor, logo, bagColor, ButtonColor, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);
  const CartSizeTotal = useSelector(state => state.Checkout.reduce((sumAmount, item) => sumAmount + item.amount, 0));
  const { params } = useRouteMatch();
  const back = useHistory();

  const location = useLocation();
  const sellerId = new URLSearchParams(location.search).get('sellerId');

  return (
    <HeaderStyleBody width={width} height={height} backgroundColor={backgroundColor} bagColor={bagColor} {...rest}>
      <HeaderStyledComponent>
        {<StyledLogo role="button" onClick={() => back.push(`/${params.store}/vitrine?sellerId=${sellerId}`)} alt="logo" src={logo || SmartLogo} />}
        {isOpen && (
          <OutsideClickHandler onOutsideClick={() => { setIsOpen(false); }}>
            <LittleCard onClose={() => setIsOpen(!isOpen)} />
          </OutsideClickHandler>
        )}
        <StyledIcons>
          <StyledBag color={bagColor || "black"} onClick={() => setIsOpen(!isOpen)}>
            <Icon icon={bag} hoverColor={bagColor || "black"} color={bagColor || "black"} width="40px" height="30px" />
            {CartSizeTotal > 0 ? (<StyledCount backgroundColor={ButtonColor}><span>{CartSizeTotal}</span></StyledCount>) : (<></>)}
          </StyledBag>
        </StyledIcons>
      </HeaderStyledComponent>
    </HeaderStyleBody>
  );
};

Header.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  backgroundColor: PropTypes.string,
  logo: PropTypes.string,
  bagColor: PropTypes.string,
  ButtonColor: PropTypes.string,
};

Header.defaultProps = {
  width: '100%',
  height: '50px',
  backgroundColor: 'white',
  logo: '',
  ButtonColor: '#00cd98'
};

export default Header;
