import styled from 'styled-components';

export const StyledMainContent = styled.div`
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  color: #828282;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-right: 15px;
`;

export const StyledTitle = styled.h3`
  font-weight: 600;
  font-size: 11px;
  margin-bottom: 5px;
`;

export const StyledCommumText = styled.p`
  font-size: 11px;
`;

export const StyledButton = styled.button`
  color: ${props => props.backgroundColor || '#37D0B5'};
  background-color: transparent;
  padding: 10px;
  border: none;
  cursor: pointer;
  font-size: 12px;
`;