import styled, {css} from 'styled-components';

export const StyledContainer = styled.div`
    width: 100%;
    height: 90.5vh;
    /*background-color: black;*/
    display: flex;
    flex-direction: column;
`;

export const StyledTitle = styled.h4`
    ${({theme}) => css `
        color: ${theme.colors.black70};
        margin-bottom: -5px;
        font-size: 14px;
    `}
    
`;
export const StyledHeader = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #F4F4F4;  
`;
export const StyledNumberConversation = styled.div`
    ${({theme}) => css `
        padding: 15px;
        width: 229px;
        border-right: solid 1px #E8E8E8};
            span {
                margin-top: 5px;
                font-size: 10px;
                color: ${theme.colors.black70};
            }
    `}
      
`;

export const StyledInfoContact = styled.div`
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    > img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
`;
export const StyledDescripitionContact = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
`;
export const StyledName = styled.span`
    ${({theme}) => css `
        color: ${theme.colors.black85};
        font-size: 13px;
        font-weight: 600;
    `}  
`;
export const StyledNumberContact = styled.span`
    ${({theme}) => css `
        color: ${theme.colors.black20};
        font-size: 10px;
    `}
`;


export const StyledArticle = styled.div`
    width: 100%;
    height: 100%;
    max-height: 88%;
    /*background-color: green;*/
    display: flex;
    flex-direction: row;
`;

export const StyledSection = styled.div`
    width: 230px;
    min-width: 230px;
    height: 100%;
    max-height: 100%;
    overflow: auto;
    /*background-color: red;*/
`;

export const StyledAside = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    /*background-color: blue;*/
`;
export const StyledInput = styled.input`
    ${({theme}) => css `
        margin: 10px;
        border-radius: 60px;
        width: 100%;
        height: 100%;
        border-style: none;
        background-color: ${theme.colors.white};
        padding-left: 20px;
    `}
`;
export const StyledAsideFooter = styled.div`
    ${({theme}) => css `
        align-items: center; 
        height: 60px;
        background-color: ${theme.colors.ice};
        padding: 10px;
        bottom: 0;
        width: 100%;
        position: absolute;

        svg {
            margin: 20px;
        }

    `}
`;

export const StyledFooterContent = styled.div`
    margin: 0 auto;
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;