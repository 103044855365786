import styled, {css} from 'styled-components';

export const StyledContainer = styled.div`
    ${({theme}) => css `
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: row;
    padding: 17px;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px ${theme.colors.lightGray};
    `}
`;

export const StyledInfoContact = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    > img {
        margin-left: 5px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
`;

export const StyledDescripitionContact = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 10px;
`;
export const StyledName = styled.span`
    ${({theme}) => css `
    color: ${theme.colors.black85};
    font-size: 13px;
    font-weight: 600;
    `}
    
`;
export const StyledNumberContact = styled.span`
    ${({theme}) => css `
    color: ${theme.colors.black20};
    font-size: 10px;
    `}
    
`;
export const StyledMessegeCount = styled.div`
    ${({theme}) => css `
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: ${theme.colors.lightGreen};
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    span {
    color: ${theme.colors.offWhite};
    font-size: 10px;
    justify-content: center;
    align-items: center;
    
    }
    `}
    
`;