import styled from 'styled-components';

export const FooterStyledComponent = styled.footer`
  width: 100%;  
  padding: 0px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;  

  background-color: ${props => props.backgroundColor};

  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
  
  @media screen and (max-width: 601px) {
    padding: 20px 30px;
  }

  .social-media {
    display: flex;
    flex-direction: row;   
    justify-content: space-between;
  }  
`;

export const StyledImage = styled.img`
  width: 50px;
  height: 60px;  
  margin: 2px 5px 2px 2px;
  object-fit: scale-down;  
`;

export const StyledImageLinx = styled.img`
  width: 50px;
  height: 45px;  
  margin: 2px 5px 2px 2px;
  object-fit: scale-down;
  cursor: pointer;
`;

export const StyledImageStone = styled.img`
  width: 55px;
  height: 60px;  
  margin: 2px 5px 2px 2px;
  object-fit: scale-down;
  cursor: pointer;
`;

export const StyledImagePagarme = styled.img`
  width: 60px;
  height: 60px;  
  margin: 2px 5px 2px 2px;
  object-fit: scale-down;
  cursor: pointer;
`;

export const StyledContent = styled.div`
  width: 70%;
  @media screen and (max-width: 700px) {
    width: 80%;
    text-align: center;
  }
`;

export const StyledIcon = styled.a`
  width: 15%;
  @media screen and (max-width: 700px) {
    margin-top: 0px;
    width: 80%;
    text-align: center;
  }
`;
