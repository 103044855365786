import descendingIcon from 'assets/icons/page-down.svg';
import ascendingIcon from 'assets/icons/page-up.svg';
import PropTypes from 'prop-types';
import React from 'react';

import { StyledButton, StyledImg, StyledContainer } from './styles';

const Ordenation = ({ field, onClick }) => {

  const orderSort = (order) => {
    onClick(field, order);
  }

  return (
    <StyledContainer>
      <StyledButton onClick={() => orderSort('ascending')} type="button">
        <StyledImg src={ascendingIcon} alt="order-icon" />
      </StyledButton>
      <StyledButton onClick={() => orderSort('descending')} type="button">
        <StyledImg src={descendingIcon} alt="order-icon" />
      </StyledButton>
    </StyledContainer>
  );
}

export default Ordenation;

Ordenation.propTypes = {
  field: PropTypes.string,
  onClick: PropTypes.func
};

Ordenation.defaultProps = {
  field: '',
  onClick: () => {}
};
