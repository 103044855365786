import ResizableBox from "components/ResizableBox";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { StyledFlexContainer } from 'styles/global';

import { StyledMainBar, StyledPercentage, StyledColor, StyledLabel, StyledText } from './styles';

function StackedBar({ data }) {
  const [total, setTotal] = useState(0);
  const [focusedElem, setFocusedElem] = useState('');

  useEffect(() => {
    const sum = data.reduce((acc, obj) => (acc + obj.value), 0);
    setTotal(sum);
  }, [data]);

  const getBorderRadius = (index) => {
    if (index === data.length - 1) {
      return '0px 3pxpx 3px 0px';
    }
    if (index === 0) {
      return '3px 0px 0px 3px';
    }
    return '';
  }

  const style = {
    display: 'flex',
    'justifyContent': 'space-between',
    'flexDirection': 'column'
  }

  return (
    <ResizableBox height={243} style={style}>
      <StyledMainBar>
        {data.map((item, index) => (
          <StyledPercentage
            onMouseEnter={() => setFocusedElem(item.label)}
            onMouseLeave={() => setFocusedElem('')}
            key={index}
            focus={focusedElem === item.label}
            color={item.color}
            id={item.label}
            value={item.value}
            width={`${(item.value / total) * 100}%`}
            radius={getBorderRadius(index)}
          />
        ))}
      </StyledMainBar>
      <StyledFlexContainer justify="space-between" direction="column">
        {data.map((item, index) => (
          <StyledLabel onMouseEnter={() => setFocusedElem(item.label)} onMouseLeave={() => setFocusedElem('')} key={index} focus={focusedElem === item.label}>
            <StyledFlexContainer align="center" direction="row">
              <StyledColor color={item.color} />
              <StyledText>{item.label}</StyledText>
            </StyledFlexContainer>
            <StyledText weight="600">{`R$ ${item.value}`}</StyledText>
          </StyledLabel>
        ))}
      </StyledFlexContainer>
    </ResizableBox>
  );
}

StackedBar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  })).isRequired,
};

export default StackedBar;

