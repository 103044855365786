import styled, { css } from 'styled-components';

export const Section = styled.section`
  width: 100%;
  height: auto;

  display: flex;
  align-content: center;
  justify-content: center;

  @media (max-width: 601px) {
    margin-top: 5px;
    marginBottom: 5px;
  }
`;

export const Icons = styled.div`
  width: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Promotion = styled.div`
  width: 100%;
  height: 27px;
  background-color: #000;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PruductDetails = styled.div`
  width: 1450px;
  height: auto;
  margin-top: 40px;
  margin-bottom: 10px;

  display: flex;

  @media (max-width: 1001px) {
    width: 90%;
    margin-top: 0px;
    margin-button: 10px;
    flex-direction: column;
  }
`;

export const ProductSize = styled.button`
  ${({ theme }) => css`
    width: 30px;
    height: 30px;
    background: #f7f5f5;
    border: 1px solid
      ${props => (props.active ? theme.colors.lightGreen : theme.colors.white)};
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 7px;
    margin: 3px;
  `}
`;

export const ListSize = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 15px;
`;

export const Images = styled.div`
  flex-basis: 60%;
  height: 500px;

  display: flex;
  flex-direction: row;
  grid-template-rows: 121px;

  img {
    width: 140px;
    height: 140px;
    margin-bottom: 0px;
    object-fit: contain;
    border: solid 1px #DDDDDD;

    @media (max-width: 1001px) {
      width: 90px;
      height: 90px;
      object-fit: contain;
    }
  }

  @media (max-width: 1001px) {
    width: 100%;
    height: auto;

    flex-direction: column-reverse;
  }
`;

export const ListImages = styled.div`
  flex-basis: 30%;
  overflow: auto;
  padding: 2px 0px;
  display: flex;
  flex-direction: column;  

  @media (max-width: 1001px) {
    width: 100%;
    flex-direction: row;
  }
`;

export const ImageSelected = styled.div`
  flex-basis: 80%;  

  .imageSelected {
    width: 100%;
    height: 100%;
    border: none;
    object-fit: contain;

    @media (max-width: 1001px) {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
`;



export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;

  .imageSelected {
    width: 100%;
    height: 100%;
    object-fit: contain;

    @media (max-width: 1001px) {
      width: 100%;
      height: 350px;
      object-fit: contain;
    }
  }

  @media (max-width: 1001px) {
    width: 100%;
    height: 350px;
    object-fit: contain;
  }
`;

export const ProductSpec = styled.div`
  flex-basis: 40%;
  width: 40%;
  height: 100%;
  padding: 0px 15px;

  h4 {
    width: 48px;
    height: 33px;
    margin-right: 10px;
    margin-left: 10px;

    background: #ffffff;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    border-radius: 3px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: ${props => props.theme.fontSize._10px}rem;

    text-align: center;
    padding: 10px 15px;
  }

  .productAmount {
    margin-top: 20px;
    margin-bottom: 25px;
    width: 100%;
    height: auto;

    display: flex;
    align-items: center;
  }

  @media (max-width: 1001px) {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 10px;
  }
`;

export const ProductColor = styled.div`
  width: 100%;
  height: auto;
  margin-top: 15px;
  display: block;
`;

export const Colors = styled.button`
  ${({ theme }) => css`
    width: 30px;
    height: 30px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
    border: 1px solid #ffffff;
    border-radius: 4px;
    margin: 3px;
    border: 1px solid
      ${props => (props.active ? theme.colors.lightGreen : theme.colors.white)};
    box-sizing: border-box;
    cursor: pointer;
  `}
`;
