import styled from 'styled-components';
import theme from '../../styles/theme';

export const StyledFilters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 12px;

  @media screen and (max-width: 1535px) {
    flex-wrap: wrap;
  }
`;

export const StyledField = styled.div`
  width: ${(props) => props.width};
  z-index: ${(props) => props.zIndex};
  margin-right: 20px;
  margin-bottom: 10px;

  @media screen and (max-width: 775px) {
    width: 100%;
    margin-right: 0;
    margin-top: ${(props) => props.marginTop};
  }
`;

export const StyledContainer = styled.div`
  max-width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  a {
    color: unset;
  }
`;

export const StyledCreateCatalog = styled.button`
  background-color: ${theme.colors.lightGreen};
  color: white;
  border-radius: 4px;
  font-size: 11px;
  border: none;
  cursor: pointer;
  font-family: Open Sans;
  font-style: normal;
  height: 35px;
  min-width: 170px;

  @media screen and (max-width: 1310px) {
    width: 100%;
  }
`;

export const StyledCreateCustomer = styled.button`
  background-color: transparent;
  border: 1px solid ${theme.colors.lightGreen};
  color: ${theme.colors.lightGreen};  
  border-radius: 4px;  
  font-size: 11px;  
  cursor: pointer;
  font-family: Open Sans;
  font-style: normal;  
  height: 35px;
  min-width: 170px;

  @media screen and (max-width: 1310px) {
    width: 100%;
  }
`;

export const StyledLeftFilters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;

  @media screen and (max-width: 775px) {
    flex-wrap: wrap;
  }
`;


export const StyledRow = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-self: flex-end;
  align-items: center !important;
  justify-content: space-between !important;

  p {
    text-align: center;
  }

  @media screen and (max-width: 930px) {
    flex-direction: column;
  }
`;

export const StyledSelectContainer = styled.div`
  width: 220px;
  font-size: 12px;

  @media screen and (max-width: 930px) {
    width: 400px;
  }

  @media screen and (max-width: 775px) {
    width: 100%;
  }
`;

export const StyledMainContent = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 12px;

  @media screen and (max-width: 775px) {
    flex-wrap: wrap;
  }
`;

export const StyledGhostContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: auto;
  margin-top: 80px;

  p {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: flex-end;
      text-align: center;
      color: #828282;
      width: 360px;
      margin: 15px;
    }
`;

export const StyledWppButton = styled.button`
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  
  &:disabled {
    opacity: 0.7;
    cursor: unset;
  }
`;
