import Button from 'components/Forms/Button';
import styled from 'styled-components';
import { StyledFlexContainer } from 'styles/global';
import theme from '../../styles/theme';

export const StyledPage = styled.form`
  padding: 20px 20px 20px 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: max-content;
  font-family: Open Sans;

  @media (max-width: 930px) {
    flex-wrap: wrap;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledList = styled.div`
  max-height: 350px;
  width: 100%;
  overflow-y: scroll;
`;

export const StyledLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1024px;
  padding-right: 15px;

  @media (max-width: 1024px) {
    padding-right: 0;
  }

  h2 {
    font-size: 16px;
    color: #5E636F;
    margin: 10px 0;
    width: max-content;

    &.delivery {
      margin-bottom: 0;
    }
  }
`;

export const StyledRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  margin-bottom: 40px;
  height: 100%;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const StyledInputContainer = styled.div`
  max-width: 340px;
`;

export const StyledCEP = styled.button`
  color: #37D0B5;
  background-color: transparent;
  width: max-content;
  border: none;
  cursor: pointer;
  font-size: 12px;
  margin-top: -12px;
`;

export const StyledDeliveryContainer = styled.div`
  width: 100%;
  max-width: 750px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  flex-wrap: wrap;
`;

export const StyledFields = styled.div`
  width: 100%;
  max-width: 750px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const StyledLabel = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-right: 15px;

  &.no-margin {
    margin-right: 0;
  }

  p {
    color: #828282;
    margin-bottom: 5px;
  }
`;

export const StyledRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;

  &.wrap {
    @media (max-width: 500px) {
      flex-direction: column;
      .last-field {
        margin-right: 0;
      }
    }
  }

  .no-wrap {
    width: 100%;;
  }
`;

export const StyledInput = styled.input`
  padding: 10px;
  width: 100%;
  border: 1px solid #CCCCCC;
  border-radius: 4px;
`;

export const StyledDescr = styled.p`
  color: #828282;
  font-size: 12px;
  margin-bottom: 10px;
`;

export const StyledListStores = styled.div`
  overflow-y: scroll;
  max-height: 260px;
`;

export const StyledExternalCircle = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid #CACACA;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 7px;
  cursor: pointer;
`;

export const StyledInternalCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #37D0B5;
`;

export const StyledType = styled.p`
  color: #828282;
  margin-right: 15px;
  font-size: 13px;
`;

export const StyledCardContainer = styled.div`
  border: 1px solid #F5F5F5;
  border-radius: 4px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

export const StyledFormCardContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const StyledCardImageContainer = styled.img`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-left: 15px;
`;

export const StyledContainer = styled(StyledFlexContainer)`
  width: 100%;
  flex-direction: row;
`;

export const StyledRowContent = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: #828282;

  @media (max-width: 770px) {
    font-size: 12px;
    padding: 5px 5px 0 5px;
  }

  &.pix-container {
    padding-top: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    img {
      width: 180px;
      height: 180px;
      object-fit: cover;
    }
  }

  .header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .pix-code {
    display: flex;
    align-items: center;
    font-size: 13px;
    flex-direction: column;

    img {
      margin: 0;
    }
  }

  img {
    margin-right: 20px;
  }

  h3 {
    font-size: 16px;
  }

  p {
    font-size: 14px;
    text-align: justify;
  }

  ol {
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    li {
      margin: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: justify;
    }
  }

  .pix-icon {
    width: 60px;
    height: 60px;
  }
`;

export const StyledPixContent = styled.div`
  color: #828282;
  border: 1px solid #F5F5F5;
  border-radius: 7px;
  max-width: 700px;
`;

export const ModalContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  font-size: 14px;
  padding: 20px;
  text-align: center;
`;

export const ModalContainerPix = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  font-size: 14px;
  padding: 20px;
  text-align: center;
`;

export const SuccessContainer = styled.div`
  border: 3px solid #6FCF97;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 60px;
  width: 60px;
  border-radius: 100%;
`;

export const ProcessingContainer = styled.p`
  font-size: 10px;
`;

export const StyledButton = styled(Button)`
  height: ${props => props.height || '50px'};
  width: ${props => props.width || '100%'};
  color: ${props => props.color};
  cursor: ${props => props.cursor};
  background-color: ${props => props.bgcolor};
  border: ${props => props.border};
  &:hover {
    background-color: #361b56;
  };
  &:focus {
    background-color: ${props => props.bgcolor};
  }
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 16px;
  flex-wrap: wrap;

  @media (max-width: 430px) {
    justify-content: center;
  }

  button {
    padding: 10px;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    border-radius: 5px;
    font-weight: 600;
    margin-top: 10px;
  }

  .abort-button {
    color: #8A8A8A;
    background-color: #E7E7E7;
  }

  .continue-button {
    color: ${theme.colors.white};
    background-color: ${(props) => props.background};
  }
`;

export const StyledCopyButton = styled.button`
  background-color: ${(props) => props.background};
  color: ${theme.colors.white};
  border-radius: 4px;
  padding: 10px 14px;
  border: 1px solid #4A588B;
  margin-left: -8px;
  font-size: 12px;
  cursor: pointer;
`;

export const StyledLinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 20px;

  p {
    width: 180px;
    padding: 8px 10px;
    border: 1px solid #CCCCCC;
    background-color: #F2F2F2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const StyledOrderContainer = styled.div`
  padding: 20px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #6C82D2;
  color: ${theme.colors.white};
  margin: 0 10px;
`;

export const StyledAlert = styled.div`
  background-color: ${(props) => props.background};
  display: flex;
  justify-content: center;
  align-items: center;
  color: #A04747;
  padding: 10px;
  height: 40px;
  width: 100%;
  margin: 15px 0;
`;

export const StyledLoaderContainer = styled(StyledFlexContainer)` 
  margin-top: 20px;
  width: 100%;
`;

export const StyledButtonStore = styled.button`
  background-color: ${(props) => props.background || '#4A588B'};
  border-radius: 4px;
  width: 120px;
  color: white;
  cursor: pointer;
  margin-top: 5px;
  padding: 10px 2px;
  border: none;

  &:disabled {
    opacity: 0.7;
    cursor: unset;
  }
`;
