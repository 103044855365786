import { Carousel } from 'react-responsive-carousel';
import styled, { css } from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { darken } from 'polished';

export const StyledProduct = styled.li`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 4px;
  align-items: center;\
`;

export const StyledPicture = styled.picture`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    height: 400px;
    object-fit: contain;
  }
  .carousel-root {
    width: auto;
    height: auto;
    object-fit: cover;
  }
`;

export const StyledCarousel = styled(Carousel)`
  ${({ theme }) => css`
    .carousel-status {
      display: block;
    }

    .carousel .slide {
      background: unset;
    }

    .carousel .control-prev.control-arrow:before {
      border-right: 8px solid #000000;
    }
    
    .carousel .control-next.control-arrow:before{
      border-left: 8px solid #000000;
    }
    
    #NewButton {
      border: none;
      background: none;
      cursor: pointer;
      padding: 0;
      outline: inherit;
    }
  `}
`;

export const StyledProductColor = styled.div`
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 45px;
  box-sizing: border-box;
  /* height: ${({ value }) => (value > 45 ? 60 : 40)}px; */
  max-width: 192px;
  /* overflow-x: ${({ value }) => (value > 16 ? 'auto' : 'hidden')}; */
  bottom: ${({ value }) => (value > 8 ? 50 : 39)}px;
  /* bottom: ${({ value }) => (value > 30 ? 100 : 70)}px; */
  /* @media (max-width: 300px) {
    max-width: 80%;
    bottom: ${({ value }) => (value > 15 ? 100 : 70)}px;
    height: ${({ value }) => (value > 15 ? 60 : 40)}px;
  } */
`;

export const StyledColors = styled.button`
  ${({ theme }) => css`
    margin: 0 3px;
    min-width: 18px;
    height: 18px;
    border: 1px solid
      ${props => (props.active ? theme.colors.lightGreen : theme.colors.white)};
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 3px;

    cursor: pointer;
  `}
`;

export const StyledDiscountOff = styled.div`
  margin-top: 0px;
  position: absolute;
  top: -10px;
  left: -15px;
  z-index: 10;
  width: 57px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #f30f46;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 8px;
    line-height: 8px;
    color: #ffffff;
  }
`;

export const StyledListSize = styled.div`
  justify-content: center;
  align-items: center;
  margin-top: -28px;
  margin-bottom: 8px;
  max-width: 190px;
`;

export const StyledProductSize = styled.button`
  ${({ theme }) => css`
    width: 20px;
    height: 20px;
    background: #f7f5f5;
    border: 1px solid
      ${props => (props.active ? theme.colors.lightGreen : theme.colors.white)};
    border-radius: 3px;
    font-size: 7px;
    margin: 3px;
    cursor: pointer;
  `}
`;

export const StyledTitle = styled.span`
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  text-align: center;
`;

export const StyledPrice = styled.span`
  font-size: 18px;
  font-weight: 300;
  padding-top: 10px;
  color: #ff4040;
`;

export const StyledPriceCondition = styled.span`
  font-size: 0.6rem;
  font-weight: 300;
  margin-bottom: 20px;
`;

export const StyledToolbar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
`;

export const StyledButton = styled.button`
  ${({ theme }) => css`
    border: 0;
    border-radius: 4px;
    height: 35px;
    overflow: hidden;
    margin-top: auto;
    background-color: ${props => props.backgroundColor || '#37D0B5'};
    color: white;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    width: 100%;
    transition: background 0.2s;

    &:hover {
      background: ${props => darken(0.08, props.backgroundColor || theme.colors.cyan)};
    }

    &:active {
      background: ${props => darken(0.10, props.backgroundColor || theme.colors.cyan)};
    }

    &:disabled {
      background-color: #BDBDBD;
      cursor: unset;
    }

    .little-car {
      display: flex;
      align-items: center;
      padding: 20px 11px;
      background: rgba(0, 0, 0, 0.1);
      font-size: 0.7rem;
      svg {
        margin-right: 5px;
      }
    }

    span {
      flex: 1;
      text-align: center;
      font-weight: bold;
      font-size: 0.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `}
`;
