import styled from 'styled-components';

export const StyledText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  line-height: 10px;

  width: 100%;
  max-width: ${props => (props.width === 'auto' ? '100%' : props.width)};

  font-size: 10px;
  color: #bf1650;

  &::before {
    display: inline;
    content: '⚠ ';
  }
`;
