import styled from 'styled-components';

export const StyledMainContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 15px;
`;

export const StyledBar = styled.p`
  color: #666666;
  font-weight: 600;
  font-size: 12px;
`;

export const StyledProgressBarContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 10px;
  border-radius: 50px;
  margin: 12px 0;
`;

export const StyledMaxProgress = styled.div`
  background-color: #D9F2EC;
  width: 100%;
  height: 100%;
  border-radius: 50px;
`;

export const StyledCurrentProgress = styled.div`
  position: absolute;
  left: 0;
  background-color: #3D7981;
  width: ${(props) => props.width};
  height: 100%;  
  border-radius: 50px;
`;