import styled from 'styled-components';

export const StyledLabel = styled.label`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 10px;
  padding-bottom: 0;
  padding-left: 0;

  .label {
    color: #989898;
    padding-bottom: 3px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const StyledMainContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  width: 100%;
  border: 1px solid #D9D9D9;
  border-radius: 3px;

  p {
    background-color: #F3F3F3;
    padding: 8px 10px;
  }
`;

export const StyledLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledFlex = styled.div`
  display: flex;
  align-items: center;

  button {
    background-color: transparent;
    border: none;
    font-size: 8px;
    color: #3D7981;
    width: 90px;
    text-decoration: none;
    margin-right: 3px;
    cursor: pointer;
  }
`;

export const StyledInput = styled.input`
  padding: 8px 10px;
  border: none;
  width: 100%;
`

export const StyledColorButton = styled.button`
  background-color: ${(props) => props.background};
  width: 25px;
  height: 25px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  margin: 0 5px;
  border: 1px solid #616161;
`;

export const StyledColorPicker = styled.div`
  position: absolute;
  top: 35px;
  z-index: 2;
  width: 100%;

  .chrome-picker {
    width: calc(100% - 20px) !important;
    padding: 10px;

    > div:first-child {
      padding-bottom: 35% !important;
    }
  }
`;

export const StyledInvisibleContainer = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export const StyledUploadButton = styled.button`
  border: 1px solid #7689EB;
  border-radius: 3px;
  width: 100px;
  height: 35px;
  background-color: #7689EB;
  color: white;
  font-size: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledAlert = styled.p`
  color: #F2761D;
  font-size: 10px;
  position: sticky;
  top: 0;
`;

export const StyledIconContainer = styled.div`
  width: 13px;
  height: 13px;
  margin-right: 10px;
`;