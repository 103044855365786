import styled from 'styled-components';

import theme from '../../styles/theme';

export const StyledProduct = styled.div`
  display: flex;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  max-width: 361px;
  min-height: 100px;
  margin-bottom: 20px;
  padding: 0px 0px;
  box-sizing: border-box;
  align-items: center;
  background-color: ${theme.colors.white};
  width: 100%;
  margin-left: 2px;
`;

export const DivImg = styled.div`
  img {
    width: 70px;
    float: left;
    object-fit: cover;
  }
`;

export const DivColumnProductDescription = styled.div`
  padding: 10.19px;
  line-height: 22px;
  p:nth-child(-n + 2) {
    font-size: 12px;
  }
  p:nth-child(2) {
    font-size: 11px;
    color: #575757;
  }
  p:nth-child(3) {
    font-size: 11px;
    color: #575757;
  }
  p:nth-child(4) {
    font-size: 11px;
    color: #575757;
  }
  p:nth-child(5) {
    font-size: 11px;
    color: #575757;
  }

  p:nth-child(3) {
    font-size: 12px;
  }
  flex-grow: ${props => props.flexGrow};
`;

export const DivColumn = styled.div`
  padding: 10px;
  flex-grow: ${props => props.flexGrow};
  text-align: ${props => props.align};
`;
