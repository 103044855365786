import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import { Types as StoreAddressTypes } from '../../ducks/StoreAddress';

export function* getStoreAddress(action) {
    try {
        // const { store } = action.payload;
        const response = yield call(api.get, `company/store-addresses`);

        if (response.status === 200) {
            yield put({
                type: StoreAddressTypes.GET_STORE_ADDRESS_SUCCESS,
                data: response.data,
            });
        }
    } catch (error) {
        yield put({
            type: StoreAddressTypes.GET_STORE_ADDRESS_ERROR,
            error,
        });
    }
}
