import styled from 'styled-components';

export const StyledMainContent = styled.div` 
  padding: 40px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: flex-start;
  height: 100%;
  flex-direction: column;
`;

export const StyledRow = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-self: flex-end;
  align-items: center !important;
  justify-content: space-between !important;

  p {
    text-align: center;
  }

  @media screen and (max-width: 930px) {
    flex-direction: column;
  }
`;

export const StyledSelectContainer = styled.div`
  width: 220px;
  font-size: 12px;

  @media screen and (max-width: 930px) {
    width: 400px;
  }

  @media screen and (max-width: 775px) {
    width: 100%;
  }
`;

export const StyledJson = styled.p`
  padding: 40px;
  word-break: break-all;
`;

export const StyledGhostContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 50px auto;

  p {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: flex-end;
      text-align: center;
      color: #828282;
      width: 360px;
      margin: 15px;
    }
`;