import styled from 'styled-components';
import theme from 'styles/theme';
import Picture from 'components/Picture/index';
import Icon from 'components/Icon/index';

export const StyledCatalogItemCard = styled.div`
  margin: 5px;
  width: 97%;
  border: solid 1px ${theme.colors.lightGray};
  background: ${props => props.theme.colors.white};
  border-radius: 8px;
  display: inherit;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  box-sizing: border-box;

  h3 {
    padding: 0 3px;
    white-space: nowrap;    
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 12px;
    font-weight: 400;
    max-width: 285px;
  }
`;

export const StyledButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  border: none;
  width: max-content;
  height: max-content;

  img {
    width: 20px;
    height: 20px;
  }
`;

export const StyledProductImage = styled(Picture)`
  width: 50px;
  height: 50px;
  padding: 5px;
  object-fit: contain; 
`;

export const StyledIcon = styled(Icon)`
  min-width: 13px;
`;