import styled, { keyframes } from 'styled-components';

import theme from '../../styles/theme';

const Animation = keyframes`
    0% {right: -27%; top: 0px; opacity: 0}
    100% {right: 0%; top: 0px; opacity: 1}
`;

export const StyleMarket = styled.div`
  z-index: 9999;
  background-color: white;
  overflow: auto;
  opacity: 1;
  position: fixed;
  top: 0;
  right: 0;
  float: right;
  height: 100%;
  width: 415px;
  animation: ${Animation};
  animation-duration: 0.4s;
  ::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${props => props.color};
  }

  @media (max-width: 411px) {
    width: 100%;
  }
`;

export const StyledProduct = styled.div`
  display: flex;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  width: 361px;
  height: 100px;
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  margin: auto;
  margin-top: 5%;
`;

export const DivImg = styled.div`
  img {
    width: 58px;
    height: 81px;
    float: left;
    object-fit: cover;
  }
`;

export const DivColumnProductDescription = styled.div`
  padding: 10.19px;
  p:nth-child(-n + 2) {
    font-size: ${props => props.theme.fontSize._12px}rem;
  }
  p:nth-child(3) {
    font-size: ${props => props.theme.fontSize._14px}rem;
    color: #575757;
  }
  flex-grow: ${props => props.flexGrow};
`;

export const DivResult = styled.div`
  border-top: 1px dashed #000000;
  display: flex;
  width: 358px;
  margin: auto;
  margin-top: 10%;
  line-height: 30px;
`;

export const DivColumn = styled.div`
  padding: 10px;
  flex-grow: ${props => props.flexGrow};
  text-align: ${props => props.align};
`;

export const DivColumnTotal = styled.div`
  padding: 10px;
  flex-grow: ${props => props.flexGrow};
  text-align: ${props => props.align};
`;

export const DivHeader = styled.div`
  display: flex;
  background-color: #f2f1f1;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  color: black;
  font-size: ${props => props.theme.fontSize._18px}rem;
  padding: 26px 24px;
  border-left: solid 10px black;
  p {
    flex-grow: 1;
  }
`;

export const AlignVertically = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
`;

export const StyledSuport = styled.div`
  margin-bottom: 10px;
  max-height: 320px;
  overflow: auto;
  overflow-y: unset;
  width: 372px;
`;

export const StyledCampain = styled.div`
  width: 86%;
  background-color: #205ab0;
  border: 1px solid #d6d6d6;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 12px;
  color: white;
  h5 {
    font-style: normal;
    font-weight: bold;
    line-height: 12px;
    font-size: ${theme.fontSize._14px};
  }
  ul {
    margin-top: 6px;
    li span {
      font-style: normal;
      font-weight: lighter;
      font-size: 14px;
      line-height: 16px;
    }
  }

  div span {
    font-style: normal;
    font-weight: lighter;
    font-size: 14px;
    line-height: 16px;
  }
`;
