import PropTypes from 'prop-types';
import React from 'react';
import { withTheme } from 'styled-components';
import theme from 'styles/theme';

import { StyledButton } from './styles';

const Button = ({
  name,
  register,
  testId,
  icon,
  backgroundColor,
  border,
  color,
  height,
  width,
  outline,
  onSubmit,
  children,
  margin,
  ...rest
}) => (
  <StyledButton
    onSubmit={onSubmit}
    name={name}
    ref={register}
    data-testid={testId}
    border={border}
    color={color}
    backgroundColor={backgroundColor}
    height={height}
    width={width}
    margin={margin}
    outline={outline}
    {...rest}
  >
    {icon} {children}
  </StyledButton>
);

Button.propTypes = {
  name: PropTypes.string,
  register: PropTypes.func,
  testId: PropTypes.string,
  backgroundColor: PropTypes.string,
  border: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  outline: PropTypes.bool,
  icon: PropTypes.node,
  margin: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  name: '',
  register: () => {},
  backgroundColor: theme.colors.principal,
  color: '#fff',
  border: 'none',
  width: '100%',
  height: '35px',
  outline: false,
  margin: '0px',
  testId: ''
};

export default withTheme(Button);
