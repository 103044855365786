import styled from 'styled-components';

export const StyledOptionContainer = styled.button`
  width: 180px;
  height: 180px;
  border: 1px solid #CCCCCC;
  margin: 15px 15px 0px 0px;
  border-radius: 4px;
  background-color: transparent;
  padding: 16px 12px 0px 12px;
  font-size: 11px;
  font-family: Open Sans;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:disabled {
    opacity: 0.7;
    cursor: unset;
  }

  @media (max-width: 430px) {
    width: 120px;
    height: 150px;
    font-size: 10px;
  }
`;

export const StyledRadioContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
`;


export const StyledImage = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 20px;

  @media (max-width: 430px) {
    width: 45px;
    height: 45px;
    margin-bottom: 12px;
  }
`;

export const StyledTitle = styled.h3`
  font-weight: 600;
`;

export const StyledSubtitle = styled.p`
  font-weight: 500;
`;

export const StyledMainContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 15px;
`;