import styled from 'styled-components';


export const StyledInputText = styled.input`
   width: 100%;
   padding: 10px 20px;
   padding-right: 25px;
   border: none;
   border-radius: 60px;
   color: #7D94A5;
   
   &::placeholder {
     color: ${ props => props.theme.colors.black20 };
     opacity: 1;
   }
`;

export const StyledImage = styled.img`
  margin-left: -25px;
  width: 15px;
  height: 15px;
`;

