import styled, { css } from 'styled-components';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

export const StyledAramisIcon = styled.div`
  img {
    width: 158;
    height: 42px;
  }
`;

export const StyledLinkToShowcase = styled.div`
  width: 100%;
  height: 50px;
  margin-top: 30px;
  display: flex;
  align-content: center;
  justify-content: space-between;
  @media (max-width: 601px) {
    height: auto;
    flex-direction: column;
    text-align: left;
    justify-content: flex-start;
  }
`;

export const StyledContainerSku = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledCarousel = styled(Carousel)`
  ${({ theme }) => css`
    .carousel-status {
      display: block;
    }

    .carousel .slide {
      background: unset;
    }

    .carousel .control-prev.control-arrow:before {
      border-right: 8px solid #000000;
    }
    
    .carousel .control-next.control-arrow:before{
      border-left: 8px solid #000000;
    }

    #NewButton {
      border: none;
      background: none;
      cursor: pointer;
      padding: 0;
      outline: inherit;
    }
  `}
`;

export const StyledIcons = styled.div`
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledPromotion = styled.div`
  width: 100%;
  height: 27px;
  background-color: #000;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledVideoSection = styled.div`
  width: 100%;
  margin-left: 0em;
  margin-top: 0em;
  margin-right: 0em;
  margin-bottom: 0em; 
  @media (max-width: 601) {
    height: 150px;
  }
`;

export const StyledBanner = styled.img`  
  width: 100%;
  margin-left: 0em;
  margin-top: 0em;
  margin-right: 0em;
  margin-bottom: 0em;    
`;

export const StyledCatalogContainer = styled.section`
  display: flex;
  margin-top: 50px;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 80%;
  max-width: 1450px;
`;

export const StyledProductContainer = styled.div`
  @media (max-width: 1450px) {
    padding: 0 20px;
  }
`;

export const StyledProductList = styled.ul`
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  list-style: none;
  position: relative;
  height: auto;
  align-items: center;
  justify-content: center;

  @media (max-width: 1450px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 780px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledProduct = styled.li`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  padding: 40px;
  border-radius: 4px;
  align-items: center;
  margin-top: auto;
`;

export const StyledPicture = styled.picture`
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    height: 400px;
    object-fit: contain;
  }

  .carousel-root {
    width: auto;
    height: auto;
    object-fit: cover;
  }
`;

export const StyledTitle = styled.span`
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
`;

export const StyledPrice = styled.span`
  font-size: 18px;
  font-weight: 300;
  padding-top: 10px;
`;

export const StyledPriceCondition = styled.span`
  font-size: 0.7rem;
  font-weight: 300;
  margin-bottom: 20px;
`;

export const StyledListSize = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const StyledProductSize = styled.button`
  ${({ theme }) => css`
    width: 20px;
    height: 20px;
    background: #f7f5f5;
    border: 1px solid ${theme.colors.white};
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 7px;
    margin: 3px;
    &:focus-within {
      border: solid 1px ${theme.colors.lightGreen};
    }
  `}
`;

export const StyledToolbar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
`;

export const StyledButton = styled.button`
  border: none;
  border-radius: 4px;
  height: ${props => props.height || 35}px;
  background-color: ${props => props.backgroundColor || 'black'};
  color: white;
  text-transform: uppercase;
  padding: 0 20px;
  font-size: 0.6rem;
  font-weight: 600;
  cursor: ${props => (props.cursor ? 'unset' : 'pointer')};
  white-space: nowrap;
  width: 100%;
`;

export const StyledPagination = styled.div`
  width: 100%;
  height: 50px;

  display: flex;
  align-content: center;
  justify-content: center;
`;

export const StyledProductColor = styled.div`
  height: auto;
  justify-content: center;
  display: flex;
  flex-direction: row;
  position: relative;
  bottom: 35px;
`;

export const StyledColors = styled.button`
  width: 17px;
  height: 17px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  cursor: pointer;
`;

export const StyledPaginator = styled.div`
  padding-top: 50px;
`;
