import PropTypes from 'prop-types';
import React from 'react';

import StyledTextArea from './styled';


const TextArea = ({
  name,
  register,
  testId,
  id,
  onChange,
  value,
  width,
  height,
  margin,
  ...rest
}) => {
    
    return (
      <StyledTextArea
        name={name}
        ref={register}
        dataTestId={testId}
        id={id}
        margin={margin}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        width={width}
        height={height}    
        {...rest}
      />
    );
};

TextArea.propTypes = {
    name    : PropTypes.string,
    register: PropTypes.func,
    testId  : PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    value   : PropTypes.string,
    width   : PropTypes.string,
    height  : PropTypes.string,
    margin  : PropTypes.string
};

TextArea.defaultProps = {
    onChange: () => {},
    value   : '',
    testId : '',
    register: () => {},
    name: '',
    id: '',
    width   : '100%',
    height  : '100px',
    margin: '5px 0'
};

export default TextArea;