import qs from 'qs';
import api from 'services/api';

export async function getRequestStatus() {
  return api.get('order/status-types');
}

export async function getRequestsShipping() {
  return api.get('order/shipping-types');
}

export async function getRequestsProducts(id) {
  return api.get(`order/${id}/products`);
}

export async function getRequestsAll(filters) {
  return await api.get('order', {
    params: filters,
    paramsSerializer: params => {
      return qs.stringify(params, { arrayFormat: 'repeat' });
    },
  });
}

export async function getCardBrand(bin) {
  const response = await api.get(`order/card-brand/${bin}`);
  return response ? response.data.brandName : 'Unknown';
}

export async function getRequestsPayments() {
  return api.get('order/payment-type');
}

export async function getInstallments() {
  return api.get('order/installments');
}

export async function getStatusId(id) {
  return api.get(`order/${id}?orderId=${id}`);
}

export async function getRequestsChangeStatus(body) {
  return await api.post('order/change-orders-status', {
    OrderIds: [body.OrderIds],
    NewStatus: body.NewStatus,
  });
}

export async function getRequestsChangeSeller(body) {
  return api.post('order/change-seller', body);
}

export async function setUserData({ name, email, stockKeepingUnit }) {
  return api
    .post('marketing/notify-when-product-arrive', {
      CustomerName: name,
      CustomerEmail: email,
      StockKeepingUnit: stockKeepingUnit,
    })
    .then(result => {
      return result.data;
    });
}
