import styled, {keyframes} from 'styled-components';

import theme from 'styles/theme';

export const OverlayStyledComponent = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 9999;
`;

export const ModalStyledContainer = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: ${ props => props.padding };
  
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    
    width: ${ props => props.width };
    min-width: ${ props => props.minWidth };
    max-width: ${ props => props.maxWidth };
    max-height: ${ props => props.maxHeight };
    height: ${ props => props.height };
    
    z-index: 9999;

    overflow: ${ props => props.overflow };
    margin-left: 0px;
    margin-right: 0px;
    box-sizing: border-box;
`;

export const CloseButtonComponent = styled.div`
    position:absolute;
    top: 20px;
    right: 20px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    z-index: 2;
`;

export const LoadOverlayStyledComponent = styled.div`
    width: 100%;
    height: 100%;
    
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(255, 255, 255, 0.95);
    border-radius: 4px;
`;

export const LoadBlockStyledComponent = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    
    width: 100px;
    height: 60px;
    
`;

const keyFramesLoading = keyframes`{
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const LoadStyledComponent = styled.div`
    position: relative;
    width: 30px;
    height: 30px;
    margin: 0 auto;
    
    & div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        border: 4px solid #fff;
        
        border-radius: 100%;
        
        animation: ${ keyFramesLoading } 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: ${ theme.colors.lightGreen } transparent transparent transparent;
    }
    & div:nth-child(1) {
      animation-delay: -0.45s;
    }
    & div:nth-child(2) {
      animation-delay: -0.30s;
    }
    & div:nth-child(3) {
      animation-delay: -0.15s;
    }
`;
