import styled from 'styled-components';
import { StyledFlexContainer } from 'styles/global';

export const StyledExternalContainer = styled.div`
  border-radius: 4px;
  border: 1px solid #EDEDED;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 7px 0;
  overflow-x: hidden;
`;

export const StyledMainContainer = styled.div`
  padding: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  font-size: 12px;
  width: 100%;
  height: 100px;
  font-weight: 600;
  font-family: Open Sans;
  font-style: normal;

  @media (max-width: 500px) {
    font-size: 10px;
  }
`;

export const StyledImage = styled.img`
  object-fit: contain;
  width: 85px;
  height: 90px;
  margin-right: 13px;

  @media (max-width: 500px) {
    width: 60px;
    height: 68px;
  }
`;

export const StyledPrice = styled.span`
  color: #575757;
  font-weight: 300;
`;

export const StyledPriceTotal = styled.span`
  margin: 10px;
`;

export const StyledCounterButton = styled.button`
  padding: 7px;
  width: 30px;
  height: 25px;
  border-radius: 0 0 0 0;
  background-color: #4A588B;
  color: white;
  text-align: center;
  border: none;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 570px) {
    background-color: #BED9F5;
    width: 25px;
    height: 20px;
    margin: 5px 0;
  }
`;

export const StyledCounterDisplay = styled.div`
  width: 50px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCounterContainer = styled(StyledFlexContainer)`
  border: 1px solid #A7B9EA;
  border-radius: 3px;
  color: #585858;
`;

export const StyledText = styled(StyledFlexContainer)`
  p {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: initial;
  }
`;

export const StyledRemoveButton = styled.button`
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: none;
  background-color: white;
  margin-left: 15px;

  @media (max-width: 570px) {
    margin: 0;
  }
`;

export const StyledContainerSmall = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #F5FAFF;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 100px;
`;

export const StyledArrow = styled.img` 
  width: 10px;
  height: 10px;
`;