export const Types = {
  ADD_CATALOGID: '@CATALOGID/ADD_CATALOGID',
};

const INITIAL_STATE = {
  data: null,
};

export default function nsu(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.ADD_CATALOGID: {
      return {
        data: action.data,
      };
    }
    default:
      return state;
  }
}
