import Default from 'assets/images/default-product.png';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types'
import React from 'react';

import TrashIcon from '../../assets/icons/trash-icon.svg';
import Icon from '../../pages/Catalog/Icon/index';
import Counter from '../Counter';
import {
  DivColumnProductDescription,
  DivImg,
  DivColumn,
  StyledProduct,
} from './styles';

const ProductCard = ({ product, onRemove, sumir, IconL }) => {

  let checkIcon = true;

  if (IconL === null) {
    checkIcon = true;
  }
  else {
    checkIcon = IconL;
  }

  const Teste = () => {
    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    };
    return (
      <motion.div
        animate={{ scale: 0.5 }}
        initial="hidden"
        variants={variants}
      />
    );
  };
  return (
    <StyledProduct>
      <Teste />
      <DivImg>
        {product?.image?.urlImage ? (<img alt={product.name} src={product?.image?.urlImage} />) :
          (
            <img src={Default} alt="Imagem do produto" />
          )}
      </DivImg>
      <DivColumnProductDescription flexGrow="8">
        <p>{product.name}</p>        
        <p>{`Tamanho: ${product.size} - Cor: ${product.color}`}</p>
        <p>{`Quantidade: ${product.amount}`}</p>
        <p>{`Preço: ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', }).format(product.price)}`}</p>
        <p>{`Total: ${Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', }).format(product.price * product.amount)}`}</p>
        <Counter sumir={sumir} productId={product.id} />
      </DivColumnProductDescription>
      <DivColumn>
        {checkIcon && (<Icon onClick={() => onRemove(product.id)} icon={TrashIcon} width="17px" height="18px" />)}
      </DivColumn>
    </StyledProduct>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object,
  onRemove: PropTypes.func,
  sumir: PropTypes.bool,
  IconL: PropTypes.string
};

ProductCard.defaultProps = {
  product: {
    name: '',
    urlImage: '',
    size: '',
    color: '',
    price: '',
    id: ''
  },
  onRemove: () => { },
  sumir: true,
  IconL: ''
};

export default ProductCard;
