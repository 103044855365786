import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import './style.css';

const Toast = ({ type, message, position, onClick, onClose }) => {
  useEffect(() => {
    let functionToast = toast.success;

    switch (type) {
      case 'success':
        functionToast = toast.success;
        break;
      case 'error':
        functionToast = toast.error;
        break;
      case 'info':
        functionToast = toast.info;
        break;
      case 'warning':
        functionToast = toast.warning;
        break;
      case 'dark':
        functionToast = toast.dark;
        break;
      default:
        functionToast = toast.default;
        break;
    }

    functionToast(message, {
      position,
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose
    });
  }, []);

  return (
    <>
      <ToastContainer onClick={onClick} position={position} autoClose={4000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </>
  );
};

export default Toast;
