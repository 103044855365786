import styled from 'styled-components';

export const StyledInputComponent = styled.input`
  width: ${props => props.width};
  height: ${props => props.height || 35}px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 5px;
  color: ${props => props.theme.colors.black55};

  &::placeholder {
    color: #999999;
    font-weight: 600;
    font-size: ${props => props.theme.fontSize._12px}rem;
  }

  &:focus {
    border: 1px solid ${props => props.theme.colors.focus};
  }

  &label {
    font-weight: bold;
  }
  &label:after {
      color: #e32;
      content: ' *';
      display:inline;
  }
`;
