import { darken } from 'polished';
import styled, { css } from 'styled-components';

export const StyledButton = styled.button`
  width: ${props => props.width};
  height: ${props => props.height};
  margin: ${props => props.margin};
  border-radius: 4px;
  font-weight: 600;
  font-size: ${props => props.theme.fontSize._12px};
  cursor: pointer;
  outline: none;
  transition: color 0.25s, border-color 0.25s, background-color 0.25s;
  
  ${props =>
    props.outline ?
        css`
          background: ${props.color};
          color: ${props.backgroundColor};
          border: 1px solid ${props.backgroundColor};
          &:hover, &:focus {
            color: ${darken(0.15, props.backgroundColor)};
            border-color: ${darken(0.15, props.backgroundColor)}
          }
        ` :
        css`
          background: ${props.backgroundColor};
          border: ${props.border};
          color: ${props.color};
          &:hover, &:focus {
            background: ${darken(0.15, props.backgroundColor)}
          }
        `
}`;
