import styled from 'styled-components';


export const StyledCatalogSendButton = styled.button`
 width: 120px;
 padding: 7px;
 height: 32px;
 border: none;
 border-radius: 60px;
 background-color: ${ props => props.theme.colors.lightGreen };
 cursor: pointer;
 margin-left: 25px;
 
 font-style: normal;
 font-weight: bold;
 font-size: ${ props => props.theme.fontSize._8px }rem;
 color: ${ props => props.theme.colors.white };

 &:disabled {
  opacity: 0.5;
  cursor: unset;
 }
`;