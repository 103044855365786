import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import ptbrLocale from 'moment/locale/pt-br';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { DateRangePicker } from 'react-dates';
import { StyledWrapper } from './styles';

const DatePicker = ({
  name,
  register,
  height,
  onChange,
  maximumDays,
  onSelectedStartDate,
  onSelectedEndDate,
  ...rest
}) => {
  moment.updateLocale('pt-br', {
    weekdaysMin: 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_'),
  });
  moment.locale('pt-br', ptbrLocale);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState(null);

  const startDateRef = useRef();
  const endDateRef = useRef();

  const handleDatesChange = params => {
    setStartDate(params.startDate);
    setEndDate(params.endDate);
  };

  useEffect(() => {
    onChange({
      startDate: startDate?.format('YYYY-MM-DD'),
      endDate: endDate?.format('YYYY-MM-DD'),
    });
  }, [startDate, endDate]);

  const outsideRange = day => {
    if (maximumDays > 0) {
      if (
        day.isAfter(startDate.clone().add(maximumDays, 'days')) &&
        day.isBefore(endDate.clone().subtract(maximumDays, 'days'))
      ) {
        const endDT = startDate.clone().add(maximumDays, 'days');
        setEndDate(endDT);
      }
      return (
        day.isAfter(startDate.clone().add(maximumDays, 'days')) &&
        day.isBefore(endDate.clone().subtract(maximumDays, 'days'))
      );
    }
    return false;
  };

  return (
    <>
      <input type="hidden" name={`${name}InitialDate`} ref={register} value={startDate?.format('YYYY-MM-DD')} />
      <input type="hidden" name={`${name}FinalDate`} ref={register} value={endDate?.format('YYYY-MM-DD')} />
      <input style={{ display: 'none' }} ref={startDateRef} value={startDate?.format('YYYY-MM-DD')} />
      <input style={{ display: 'none' }} ref={endDateRef} value={endDate?.format('YYYY-MM-DD')} />

      <StyledWrapper height={height}>
        <DateRangePicker
          startDate={startDate}
          startDateId="tata-start-date"
          endDate={endDate}
          endDateId="tata-end-date"
          onDatesChange={handleDatesChange}
          focusedInput={focusedInput}
          onFocusChange={focInput => setFocusedInput(focInput)}
          displayFormat={() => 'DD/MM/YYYY'}          
          isOutsideRange={outsideRange}
          numberOfMonths={1}
          inputIconPosition="after"
          showDefaultInputIcon
          startDatePlaceholderText="Data inicial"
          endDatePlaceholderText="Data final"
          hideKeyboardShortcutsPanel
          {...rest}
        />
      </StyledWrapper>
    </>
  );
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  height: PropTypes.string,
  onSelectedStartDate: PropTypes.func,
  onSelectedEndDate: PropTypes.func,
  maximumDays: PropTypes.number,
  onChange: PropTypes.func,
};

DatePicker.defaultProps = {
  height: '35px',
  maximumDays: 0,
  onSelectedStartDate: () => { },
  onSelectedEndDate: () => { },
  onChange: () => { },
};

export default DatePicker;
