import PropTypes from 'prop-types';
import React from 'react';

import theme from '../../../styles/theme';
import Input from '../Input';
import Label from '../Label';
import { StyledAlign } from './styles';

function InputTemplate({
  mandatory,
  name,
  children,
  register,
  testId,
  width,
  type,
  placeholder,
  error,
  colorLabel,
  ...rest
}) {
  return (
    <StyledAlign>
      <div
        style={{
          width: 'auto',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
      >
        <Label color={colorLabel} background="yellow">
          {children}
          {mandatory && <p className='asterisk'>*</p>}
        </Label>
      </div>
      <Input
        type={type}
        required={mandatory}
        placeholder={placeholder}
        name={name}
        register={register}
        dataTestId={testId}
        width={width}
        {...rest}
      />
      {error}
    </StyledAlign>
  );
}

InputTemplate.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  testId: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  width: PropTypes.string,
  colorLabel: PropTypes.string,
  mandatory: PropTypes.bool,
};

InputTemplate.defaultProps = {
  width: '100%',
  colorLabel: theme.colors.black55,
  mandatory: false,
};

export default InputTemplate;
