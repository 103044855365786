import PropTypes from 'prop-types';
import React from 'react';
import SelectedIcon from 'assets/icons/selected-icon.svg';

import {
  StyledCardContainer,
  StyledSelected
} from './styles';

function PaymentCard({ selected, title, onClick, icon, className, buttonColor }) {
  return (
    <StyledCardContainer className={className} color={buttonColor} type='button' selected={selected} onClick={onClick}>
      {selected && <StyledSelected src={SelectedIcon} Color={buttonColor} alt="icone de selecionado" />}
      {title}
      <img src={icon} alt="ícone do pagamento" />
    </StyledCardContainer>
  );
};

PaymentCard.propTypes = {
  selected: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  buttonColor: PropTypes.string
};

PaymentCard.defaultProps = {
  selected: false,
  title: 'Tipo do pagamento',
  onClick: () => { },
  icon: '',
  buttonColor: '#1AD391'
};

export default PaymentCard;
