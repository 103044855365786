import styled from 'styled-components';
import { StyledFlexContainer } from 'styles/global';

export const StyledInput = styled.input`
  border-radius: 4px;
  padding: 8px;
  border: 1px solid #CCCCCC;
  width: 100%;
`;

export const StyledButton = styled.button`
  padding: 8px 17px;
  border-radius: 4px;
  background-color: #4A588B;
  color: white;
  border: none;
  margin-left: 15px;
  cursor: pointer;

  &:hover {
    opacity: 0.8
  }

  &:disabled {
    opacity: 0.8;
    cursor: unset;
  }
`;

export const StyledContainer = styled(StyledFlexContainer)`
  width: 100%;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 12px;

  label {
    color: #5C5C5C;
  }
`;