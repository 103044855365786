export const colors = [
  {
    id: 1,
    description: 'AZUL',
    color: '#191970',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 2,
    description: 'MARINHO',
    color: '#23238E',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 3,
    description: 'AZUL MEDIO',
    color: '#3232CD',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 4,
    description: 'CINZA CLARO',
    color: '#CDCDCD',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 5,
    description: 'Azul Jeans',
    color: '#425d8c',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 6,
    description: 'Azul Indigo',
    color: '#19424f',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 7,
    description: 'CORAL',
    color: '#ff7f50',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 8,
    description: 'Vinho',
    color: '#5e2129',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 9,
    description: 'Roxo',
    color: '#993399',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 10,
    description: 'MARROM CLARO',
    color: '#874b0f',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 11,
    description: 'Branco',
    color: '#fff',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 12,
    description: 'LARANJA CLARO',
    color: '#ff6738',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 13,
    description: 'Laranja',
    color: '#ff6738',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 14,
    description: 'LARANJA MEDIO',
    color: '#ff764b',
    category: 'Cor:',
  },
  {
    id: 15,
    description: 'VERMELHO',
    color: '#f44336',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 16,
    description: 'Verde Agua',
    color: '#03bb85',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 17,
    description: 'VERDE LIMÃO',
    color: '#32cd32',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 18,
    description: 'GOIABA',
    color: '#f5554a',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 19,
    description: 'AMARELO CLARO',
    color: '#ffed4e',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 20,
    description: 'PRETO/BRANCO',
    color: '#b2b2b2',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 21,
    description: 'Prata',
    color: '#cbcbcc',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 22,
    description: 'PRATA C/ VERMELHO',
    color: '#aa2e25',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 23,
    description: 'PRATA C/ AZUL',
    color: '#8b96d2',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 24,
    description: 'PRATA C/ VERDE',
    color: '#93cf96',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 25,
    description: 'OURO',
    color: '#ffcd38',
    typeId: 1,
    category: 'Cor:',
  },
  {
    id: 26,
    description: 'DOURADO',
    color: '#ffcd38',
    typeId: 1,
    category: 'Cor:',
  },
];

export const tamanhos = [
  {
    id: 27,
    description: '36',
    typeId: 2,
    category: 'Tamanho:',
  },
  {
    id: 28,
    description: '38',
    typeId: 2,
    category: 'Tamanho:',
  },
  {
    id: 29,
    description: '39',
    typeId: 2,
    category: 'Tamanho:',
  },
  {
    id: 30,
    description: '40',
    typeId: 2,
    category: 'Tamanho:',
  },
  {
    id: 31,
    description: '41',
    typeId: 2,
    category: 'Tamanho:',
  },
  {
    id: 32,
    description: '42',
    typeId: 2,
    category: 'Tamanho:',
  },
  {
    id: 33,
    description: '43',
    typeId: 2,
    category: 'Tamanho:',
  },
  {
    id: 34,
    description: '46',
    typeId: 2,
    category: 'Tamanho:',
  },
  {
    id: 35,
    description: '48',
    typeId: 2,
    category: 'Tamanho:',
  },
  {
    id: 36,
    description: '50',
    typeId: 2,
    category: 'Tamanho:',
  },
  {
    id: 37,
    description: '52',
    typeId: 2,
    category: 'Tamanho:',
  },
  {
    id: 38,
    description: '54',
    typeId: 2,
    category: 'Tamanho:',
  },
  {
    id: 39,
    description: 'PP',
    typeId: 2,
    category: 'Tamanho:',
  },
  {
    id: 40,
    description: 'P',
    typeId: 2,
    category: 'Tamanho:',
  },
  {
    id: 41,
    description: 'M',
    typeId: 2,
    category: 'Tamanho:',
  },
  {
    id: 42,
    description: 'G',
    typeId: 2,
    category: 'Tamanho:',
  },
  {
    id: 43,
    description: 'GG',
    typeId: 2,
    category: 'Tamanho:',
  },
  {
    id: 44,
    description: 'XGG',
    typeId: 2,
    category: 'Tamanho:',
  },
  {
    id: 45,
    description: 'XXG',
    typeId: 2,
    category: 'Tamanho:',
  },
  {
    id: 46,
    description: 'UN',
    typeId: 2,
    category: 'Tamanho:',
  },
];

export const categories = [
  {
    id: 47,
    description: 'Camisas',
    category: 'Categoria:',
  },
  {
    id: 48,
    description: 'Calçados',
    category: 'Categoria:',
  },
  {
    id: 49,
    description: 'Calças',
    category: 'Categoria:',
  },
  {
    id: 50,
    description: 'Bermudas',
    category: 'Categoria:',
  },
  {
    id: 51,
    description: 'Cintos',
    category: 'Categoria:',
  },
  {
    id: 52,
    description: 'Correntes',
    category: 'Categoria:',
  },
];

export const prices = [
  {
    typeId: 3,
    id: 56,
    value: 'de R$ 0,00 a R$ 50,00',
    valueTo: 50,
    valueFrom: 0,
    category: 'Preço:',
  },
  {
    typeId: 3,
    id: 57,
    value: 'de R$ 50,00 a R$ 150,00',
    valueTo: 150,
    valueFrom: 50,
    category: 'Preço:',
  },
  {
    typeId: 3,
    id: 58,
    value: 'de R$ 150,00 a R$ 350,00',
    valueTo: 350,
    valueFrom: 150,
    category: 'Preço:',
  },
  {
    typeId: 3,
    id: 59,
    value: 'acima de R$350,00',
    valueTo: 1000000,
    valueFrom: 350,
    category: 'Preço:',
  },
];
