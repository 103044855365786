import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledMainContent,
  StyledButton,
  StyledTitle,
  StyledInfo,
  StyledDescr
} from './styles';

function StoreOption({ info, onClick, selected, color }) {
  return (
    <StyledMainContent backgroundColor={color} selected={selected}>
      <StyledInfo>
        <StyledTitle>
          {info.storeName}
        </StyledTitle>
        <StyledDescr>
          {`${info.streetName}, ${info.streetNumber}  ${info?.reference ? ` - ${info?.reference}` : ''}`}
        </StyledDescr>
        <StyledDescr>
          {`${info.cityName} - ${info.stateName}`}
        </StyledDescr>
        <StyledDescr>
          8km de distância
        </StyledDescr>
      </StyledInfo>
      <StyledButton backgroundColor={color} type="button" onClick={() => onClick(info)}>
        SELECIONAR
      </StyledButton>
    </StyledMainContent>
  );
}

StoreOption.propTypes = {
  info: PropTypes.shape({
    storeName: PropTypes.string,
    streetName: PropTypes.string,
    streetNumber: PropTypes.string,
    reference: PropTypes.string,
    cityName: PropTypes.string,
    stateName: PropTypes.string,
  }),
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  color: PropTypes.string
}

StoreOption.defaultProps = {
  info: {
    storeName: '',
    streetName: '',
    streetNumber: '',
    reference: '',
    cityName: '',
    stateName: '',
  },
  onClick: () => { },
  selected: false,
  color: '37D0B5'
}

export default StoreOption;
