import styled from 'styled-components';
import theme from 'styles/theme';

export const StyledContainer = styled.div`
  width: 100%;
  padding: 0px 0px 8px 0px;
  margin-bottom: 5px;
  margin-top: 2px;
  border-bottom: 1px ${props => (props.marcation ? 'solid' : 'unset')};
  border-color: ${props =>
    props.bColor ? theme.colors.gray : theme.colors.black55};

  #react-collapsed-panel-2 {
    padding: 10px;
  }
`;

export const StyledExpand = styled.div`
  width: 100%;
  border-color: ${props => props.borderColor};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-between;
`;

export const StyledCategories = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  visibility: ${props => props.visibitity};
`;

export const StyledIcon = styled.img`
  position: relative;
  padding: 0px 5px;
`;

export const StyledButton = styled.button`
  width: 30px;
  height: 20px;
  text-align: left;
  border: none;
  background-color: #fff;
  cursor: pointer;
`;
