import styled from 'styled-components';
import Button from 'components/Forms/Button';
import theme from 'styles/theme';

export const StyledContainer = styled.div`
  max-width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const StyledTitle = styled.div`
  max-width: 100%;
  height: auto;
  padding: 15px 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const StyledFields = styled.div`
  max-width: 100%;
  height: auto;
  padding: 15px 20px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: left;
`;

export const StyledAlignDuo = styled.div`
  display: inline-grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 3px;
  width: 100%;
  @media (max-width: 800px) {
    grid-template-columns: 100%;
  }
`;

export const StyledDivButton = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-direction: row;
  justify-content: flex-end;
  padding: 0px 20px;
`;

export const StyledButton = styled(Button)`
  height: 50px;
  width: 100%;
  color: ${theme.colors.white};
  cursor: pointer;
  background-color: ${props => props.bgcolor};
  &:hover,
  &:focus {
    background-color: ${props => props.bgcolor};
  }
`;

export const StyledTable = styled.table`
  width: 95%;
  margin-top: 10px;
  margin-left: 14px;
  /* border-bottom: 1px dashed #000; */
`;

export const StyledThead = styled.thead`
  padding: 10px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 17px;
  text-align: left;
  color: #b9b9b9;
  background-color: #fbfbfb;
`;

export const StyledTableBody = styled.tbody`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 12px;
  color: #929292;
  tr {
    padding: 10px;

    td img {
      width: 110px;
      height: 110px;
      object-fit: cover;
      border-radius: 4px;
    }
  }
`;
