import Text from 'components/Typografy/Text';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { withTheme } from 'styled-components';
import SellerModal from '../SellerModal';
import StatusModal from '../StatusModal';

const ModalButton = ({
  onChange,
  requestData,
  label,
  type,
}) => {
  const [showModalState, setShowModalState] = useState(false);

  function resetShowModalStateHandler() {
    setShowModalState(false);
  }

  function onShowModalVisible() {
    setShowModalState(!showModalState);
  }

  const showModal = () => {
    if (type === 'status') {
      return (
        <StatusModal
          onChange={onChange}
          requestData={requestData}
          onCloseCallback={resetShowModalStateHandler}
        />
      )
    }
    return (
      <SellerModal
        onChange={onChange}
        requestData={requestData}
        onCloseCallback={resetShowModalStateHandler}
      />
    )
  };

  return (
    <>
      {showModalState && showModal()}
      <Text
        color="#3D7981"
        onClick={() => onShowModalVisible()}
        fontSize={({ theme }) => theme.fontSize._10px}
        textAlign="center"
        fontWeight={600}
        cursor="pointer"
        style={{ minWidth: '60px', textAlign: 'center', alignSelf: 'center' }}
      >
        {label}
      </Text>
    </>
  );
};

ModalButton.propTypes = {
  label: PropTypes.string,
  requestData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func,
  type: PropTypes.string
};

ModalButton.defaultProps = {
  label: '',
  requestData: '',
  onChange: () => null,
  type: 'status'
};

export default withTheme(ModalButton);
