import PropTypes from 'prop-types';
import React from 'react';

import {
  StyledPaginationContainer,
  StyledPaginationItem
} from './styles';
import { usePagination, DOTS } from './usePagination';

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount,
  currentPage,
  pageSize,
}) => {

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange?.length - 1];
  return (
    <StyledPaginationContainer>
      <StyledPaginationItem
        className={`${currentPage === 1 && 'disabled'}`}
        onClick={onPrevious}
      >
        <p>Anterior</p>
      </StyledPaginationItem>
      {paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <li key={pageNumber} className="pagination-item dots">&#8230;</li>;
        }

        return (
          <StyledPaginationItem
            key={pageNumber}
            className={`${pageNumber === currentPage && 'selected'}`}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </StyledPaginationItem>
        );
      })}
      <StyledPaginationItem
        className={`${currentPage === lastPage && 'disabled'}`}
        onClick={onNext}
      >
        <p>Próximo</p>
      </StyledPaginationItem>
    </StyledPaginationContainer>
  );
};

Pagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
};

Pagination.defaultProps = {
  siblingCount: 1,
};

export default Pagination;