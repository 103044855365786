import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { StyledOptionContainer, StyledImage, StyledTitle, StyledSubtitle, StyledDeadLine, StyledMainContent } from './styles';

function ButtonOption({ title, description, image, handleChange }) {

  const handleClick = () => {
    handleChange();
  }

  return (
    <StyledOptionContainer type="button" onClick={() => handleClick()}>
      <StyledMainContent>
        <StyledImage src={image} alt="ícone do delivery" />
        <StyledTitle>
          {title}
        </StyledTitle>
        <StyledSubtitle>
          {description}
        </StyledSubtitle>
      </StyledMainContent>
    </StyledOptionContainer>
  );
};

ButtonOption.propTypes = {
  title: "Titulo",
  description: "Descricao",
  image: "1",
  handleChange: PropTypes.func
};

ButtonOption.defaultProps = {
  title: "",
  description: "",
  icon: "",
  handleChange: () => { }
};

export default ButtonOption;