import styled from 'styled-components';

export const StyledPaginationContainer = styled.ul`
  display: flex;
  list-style-type: none;
  align-items: center;
  justify-content: center;
  margin: 17px 0;

  p {
    font-size: 10px;
    font-weight: 600;
  }
`;

export const StyledPaginationItem = styled.li`
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin-right: 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 4px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;

  &.dots:hover {
    background-color: transparent;
    cursor: default;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }

  &.selected {
    border: 1px solid #3D7981;
    color: #3D7981;
  }

  .arrow {
    &::before {
      position: relative;
      /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
      content: '';
      /* By using an em scale, the arrows will size with the font */
      display: inline-block;
      width: 0.4em;
      height: 0.4em;
      border-right: 0.12em solid rgba(0, 0, 0, 0.87);
      border-top: 0.12em solid rgba(0, 0, 0, 0.87);
    }

    &.left {
      transform: rotate(-135deg) translate(-50%);
    }

    &.right {
      transform: rotate(45deg);
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;

    .arrow::before {
      border-right: 0.12em solid rgba(0, 0, 0, 0.43);
      border-top: 0.12em solid rgba(0, 0, 0, 0.43);
    }

    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }
`;