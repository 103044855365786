import styled from 'styled-components';

export const StyledSelectAllBarContainer = styled.div`
  width: 100%;
  display: 0 0 100% flex;
  padding: 10px;
  background-color: ${props => props.theme.colors.lightGreen};
  position: absolute;
  top: 0px;
  z-index: 3;
  overflow-x: hidden;
`;

export const StyledCenterLabel = styled.span`
  color: ${props => props.theme.colors.white};
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
