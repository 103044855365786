import Pagination from 'components/Forms/newPagination';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import trashIcon from 'assets/icons/trash-icon.svg';
import { useDispatch } from 'react-redux';
import { useParams, withRouter, useLocation, useHistory } from 'react-router-dom';
import { getContentUrl } from 'services/api';
import { Types as CartTypes } from 'store/ducks/Checkout';
import { Types as NsuTypes } from 'store/ducks/Nsu';

import Text from '../../components/Typografy/Text';
import * as CatologContext from '../../services/catalog-services';
import { Types as CatalogIdTypes } from '../../store/ducks/CatalogId';
import theme from '../../styles/theme';
import Card from './Card';
import {
  StyledVideoSection,
  StyledCatalogContainer,
  StyledProductList,
  StyledLinkToShowcase,
  StyledBanner,
  StyledPaginator
} from './styles';

function Catalog({ match }) {
  const dispatch = useDispatch();
  const { register, handleSubmit, getValues } = useForm();
  const history = useHistory();
  const [catalog, setCatalog] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState();

  const location = useLocation();
  const sellerId = new URLSearchParams(location.search).get('sellerId');
  const { id, store } = useParams();

  useEffect(() => {
    dispatch({ type: CatalogIdTypes.ADD_CATALOGID, data: id, });
    getData();
    //cleanUpCart();
    onFormSubmit(getValues());
  }, []);

  const cleanUpCart = () => {
    dispatch({ type: CartTypes.CLEAR_CART, });
    dispatch({ type: NsuTypes.CLEAR_NSU, });
  }

  const onFormSubmit = async (formData, page = 1) => {
    setLoading(false);
    formData = {
      ...formData,
      pageSize,
      pageIndex: page
    };
    const response = await CatologContext.getCatalogById(id, formData);
    if (response?.data?.payload?.length > 0) {
      setTotalRows(response.data?.pager?.totalRows);
      setCatalog(response.data.payload);
      setTotalPages(response.data?.pager?.totalPages);
      setCurrentPage(response.data?.pager?.currentPage);
      setPageSize(response.data?.pager?.pageSize);
      setLoading(true);
    } else {
      history.push(`/${match.params.store}/vitrine?sellerId=${sellerId}`);
    }
  };

  const handleLinkToShowcase = () => {
    history.push(`/${match.params.store}/vitrine?sellerId=${sellerId}`);
  };

  const getData = async () => {
    try {
      const bannerResp = await getContentUrl(store, 'banner');
      setBanner(bannerResp);
    } catch {
      setBanner(null);
    }
  }

  return (
    <>
      <StyledCatalogContainer>
        <StyledVideoSection>
          <StyledBanner src={banner} alt="Banner da loja" />
          <StyledLinkToShowcase>
            <Text fontSize="18px" lineHeight="10px">
              Catálogo criado especialmente para você!
            </Text>
            <Text fontSize="18px" lineHeight="10px" color="#3D7981" className="link" cursor="pointer" onClick={handleLinkToShowcase}>
              Ver vitrine completa
            </Text>
          </StyledLinkToShowcase>
        </StyledVideoSection>
        {loading ? (
          <StyledProductList onSubmit={handleSubmit(onFormSubmit)}>
            {catalog &&
              catalog.map(item => (
                <Card key={item.product.id} product={item} />
              ))}
          </StyledProductList>
        ) : (
          <div style={{ height: '150px', paddingTop: '50px', }}>
            <Loader type="Oval" color={theme.colors.black55} height={80} width={80} timeout={loading} />
          </div>
        )}
        <StyledPaginator>
          <Pagination totalCount={totalPages} pageSize={pageSize} onPageChange={(page) => onFormSubmit(getValues(), page)} currentPage={currentPage} />
        </StyledPaginator>
      </StyledCatalogContainer>
    </>
  );
}

export default withRouter(Catalog);
