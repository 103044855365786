import api from 'services/api';

export async function setConsultaCampanha(body) {
  const response = await api.post(
    'ConsultaCampanha',
    {
      // codigoLoja: body.codigoLoja,
      qtdeTotal: 1,
      valorBruto: body.valorBruto,
      qtdeParcelas: body.parcela,
      valorLiquido: body.valorLiquido,
      transacaoAssociada: body.transacaoAssociada,
      categoriaVenda: '05',
      itens: body.items,
      promocodes: [],
      somenteRemarcacao: false,
      itensDevolvidos: [],
      pagamentos: [],
      campanhasOpcionais: [],
      campanhasAtivadas: [],
    },
    // header,
  );
  return response;
}

export async function setProcessaOperacao(body) {
  const response = await api.post(
    'ProcessaOperacao',
    {
      codigoLoja: '000005',
      catalogId: body.catalogId,
      qtdeTotal: body.qtdeTotal,
      valorBruto: body.value,
      valorDescontos: body.desconto,
      valorLiquido: body.value,
      items: body.item,
      pagamentos: body.pagamentos,
      result: true,
      message: '',
      isException: true,
      offline: true,
    },
    // header,
  );

  return response;
}

export async function setConfirmaOperacao(NSU) {
  const response = await api.get(
    'ConfimaOperacao',
    {
      codigoLoja: '801108',
      nsu: NSU,
      confirma: true,
      saleNumber: '',
    },
    // header,
  );

  return response;
}
