import logo from 'assets/images/whatsapp-logo.png';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StyledIcon } from './styles';

function WhatsAppIcon() {
  const { store } = useParams();
  const settings = useSelector(state => state.Setting.data);

  useEffect(() => {
  }, [store]);

  return (
    <>{
      settings?.whatsApp != "" && (
        <StyledIcon target="_blank" href={`https://wa.me/55${settings?.whatsApp?.replace(/[-() ]/g, '') ?? ""}?text=Olá, tudo bem? Acessei a vitrine smart da loja e gostaria de saber mais sobre alguns produtos.`}>
          <img alt="WhatsApp Logo" src={logo} />
        </StyledIcon>)
    }
    </>
  );
}

export default WhatsAppIcon;
