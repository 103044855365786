import Label from 'components/Forms/Label';
import Modal from 'components/Modals/Modal';
import Text from 'components/Typografy/Text';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import * as RequestContext from 'services/requests-services';

import { StyledContainer, StyledButtonA, StyledSelectContainer } from './styles';

const StatusModal = ({
  onCloseCallback,
  onChange,
  requestData,
}) => {
  const [status, setStatus] = useState([]);
  const [prodStatus, setProdStatus] = useState();
  const [loading, setLoading] = useState(false);

  const [resultError, setResultError] = useState(true);
  const [resultErrorMsg, setresultErrorMsg] = useState("");

  const getStatus = async () => {
    const response = await RequestContext.getRequestStatus();
    setStatus(response.data.payload);
  };

  useEffect(() => {
    getStatus();
  }, []);

  const handleChangeStatus = async () => {
    const body = {
      OrderIds: requestData,
      NewStatus: prodStatus?.id
    };

    setResultError(true);
    setLoading(true);

    const response = await RequestContext.getRequestsChangeStatus(body);

    if (response.data.isSuccess) {
      window.location.reload(false);
    } else {
      setLoading(false);
      setResultError(false);
      setresultErrorMsg(response.data.msg);
    }
  };

  return (
    <Modal showCloseButton width="90vw" maxWidth="340px" height="max-content" maxHeight='90vh' padding='10px' onCloseCallback={onCloseCallback} isVisible overflow='none'    >
      <StyledContainer>
        <Text fontSize="18px">Alterar status do pedido</Text>
        <StyledSelectContainer justify="space-between" direction="column">
          <Label htmlFor="customerSeller">Status do Pedido</Label>
          <Select
            name="customer-seller"
            inputId="customerSeller"
            options={status}
            value={prodStatus}
            getOptionLabel={(option) => option?.name}
            getOptionValue={(option) => option?.id}
            placeholder="Selecione"
            onChange={(value) => {
              setProdStatus(value)
            }}
          />
        </StyledSelectContainer>
        <StyledButtonA disabled={loading} onClick={handleChangeStatus}>
          {!loading ? `APLICAR` : (
            <Loader type="Oval" color='#FFF' height={17} width={17} timeout={0} />
          )}
        </StyledButtonA>
        <StyledSelectContainer justify="space-between" direction="column">
          <Text hidden={resultError} textAlign="start" fontSize="15px" color='#de0b32' >Falha ao alterar o pedido:</Text>
          <Text hidden={resultError} textAlign="start" fontSize="15px" color='#de0b32' >{resultErrorMsg}</Text>
        </StyledSelectContainer>
      </StyledContainer>
    </Modal>
  );
};

StatusModal.propTypes = {
  onCloseCallback: PropTypes.func,
  requestData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func,
};

StatusModal.defaultProps = {
  onCloseCallback: () => null,
  requestData: '',
  onChange: () => null,
};

export default StatusModal;
