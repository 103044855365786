import DataGrid from 'components/Datagrid';
import Pagination from 'components/Forms/newPagination';
import Modal from 'components/Modals/Modal';
import Text from 'components/Typografy/Text/index';
import moment from 'moment';
import PropTypes from 'prop-types';
import ghostImage from 'assets/icons/ghost.svg';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { getLogs } from 'services/logs';

import { skeletonLoadTable } from './Skeleton';
import { StyledMainContent, StyledField, StyledFilter, StyledRow, StyledJson, StyledGhostContainer } from './styles';

const sizeOptions = [
  { value: 10, label: 'Mostrar 10 por vez' },
  { value: 20, label: 'Mostrar 20 por vez' },
  { value: 30, label: 'Mostrar 30 por vez' }
];

function LogModal({ onCloseCallback, pedido }) {
  const { register, handleSubmit, getValues } = useForm();
  const [logs, setLogs] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [openJson, setOpenJson] = useState(false);
  const [json, setJson] = useState();
  const [pageSize, setPageSize] = useState({ value: 10, label: 'Mostrar 10 por vez' });
  var paseSizeSelect = 10;

  const handleSetPageSize = (value) => {
    setPageSize(value);
    paseSizeSelect = value.value;
    onFormSubmit(getValues());
  };

  const handleSetPage = (page) => {
    paseSizeSelect = pageSize.value;
    onFormSubmit(getValues(), page);
  };

  const setStates = (response) => {
    if (response?.data) {
      setLogs(response.data?.payload);

      if (response?.data?.pager) {
        setCurrentPage(response.data.pager?.currentPage);
        setTotalRows(response.data.pager?.totalRows);
        setTotalPages(response.data.pager?.totalPages);
      }

      setLoading(false)
    }
  }

  const onFormSubmit = async (filters, page = 1) => {
    const formData = {
      ...filters,
      topicId: 'order',
      entityId: pedido.id,
      pageIndex: page,
      pageSize: paseSizeSelect
    };
    setLoading(true);
    const response = await getLogs(formData);
    setStates(response);
  }

  useEffect(() => {
    onFormSubmit(getValues());
  }, []);

  const tableBody = () => {
    // if there is element, render
    if (logs.length) {
      return logs?.map((log, index) => (
        <tr key={`${log?.id}-${index}`}>
          <td>{log.type}</td>
          <td>{moment(new Date(log?.createdAt)).format('DD/MM/YY, h:mm:ss a')}</td>
          <td>{log.referenceMessageId}</td>
          <td>{log.message}</td>
        </tr>
      ))
    }
    return (
      (
        <StyledGhostContainer className='ghost-image'>
          <img src={ghostImage} alt="Ilustração de fantasma" />
          <p>
            Não foram encontrados Logs para o filtro selecionado ou não há Logs disponíveis para este item. Tente mudar suas configurações de segmentação de consulta e filtrar novamente.
          </p>
        </StyledGhostContainer>
      )
    )
  }

  return (
    <Modal
      showCloseButton
      minWidth="330px"
      width="100%"
      maxWidth="800px"
      height="500px"
      maxHeight="654px"
      padding={5}
      onCloseCallback={onCloseCallback}
      isVisible
      style={{ margin: '0px 20px' }}
      overflow="hidden"
    >
      <StyledMainContent>
        <DataGrid
          width="100%"
          height="unset"
          minHeight="200px"
          maxHeight="400px"
          style={{ marginTop: '15px' }}
        >
          {logs.length > 0 && (
            <>
              <colgroup>
                <col style={{ width: '20%', minWidth: '50px' }} />
                <col style={{ width: '20%', minWidth: '80px' }} />
                <col style={{ width: '20%', minWidth: '100px' }} />
                <col style={{ width: '40%', minWidth: '100px' }} />
              </colgroup>
              <thead style={{ textAlign: 'left', position: 'sticky', backgroundColor: '#FFF', top: '0', zIndex: 2 }}>
                <tr>
                  <th>Type</th>
                  <th>Date</th>
                  <th>ReferenceMessageId</th>
                  <th>Message</th>
                </tr>
              </thead>
            </>
          )}

          <tbody>
            {loading
              ? skeletonLoadTable()
              : tableBody()}
          </tbody>
        </DataGrid>
        <StyledRow align="center">
          <Text>
            {`Mostrando ${totalRows >= (currentPage * (pageSize.value || 10)) ? (currentPage * (pageSize.value || 10)) : totalRows} de ${totalRows} resultados`}
          </Text>
          <Pagination totalCount={totalPages} pageSize={pageSize} onPageChange={(page) => handleSetPage(page)} currentPage={currentPage} />
        </StyledRow>
      </StyledMainContent>
      {openJson && (
        <Modal
          showCloseButton
          minWidth="330px"
          width="100%"
          maxWidth="800px"
          height="500px"
          maxHeight="654px"
          padding={5}
          onCloseCallback={() => {
            setOpenJson(false)
            setJson()
          }}
          isVisible
          style={{ margin: '0px 20px' }}
          overflow="scroll"
        >
          <StyledJson>
            <pre>
              {JSON.stringify(JSON.parse(json), null, 4)}
            </pre>
          </StyledJson>
        </Modal>
      )}
    </Modal>
  );
}

LogModal.propTypes = {
  onCloseCallback: PropTypes.func
};

LogModal.defaultProps = {
  onCloseCallback: () => { }
};

export default LogModal;
