import ghostImage from 'assets/icons/ghost.svg';
import DataGrid from 'components/Datagrid';
import Button from 'components/Forms/Button';
import DatePicker from 'components/Forms/DatePicker';
import Input from 'components/Forms/Input';
import Label from 'components/Forms/Label';
import Pagination from 'components/Forms/newPagination';
import Text from 'components/Typografy/Text/index';
import moment from 'moment';
import ModalSendContact from 'pages/Customers/CreateCatalogModal';
import { skeletonLoadTable } from 'pages/SellerCatalog/skeleton';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { getCatalogs } from 'services/catalog-services';
import { getSellers } from 'services/sellers-services';
import theme from 'styles/theme';
import smartSalesSessionStorage, { storageKeys } from "storage/smart-sales-session-storage";
import catalogoShare from 'assets/icons/bag-csv.svg';
import {
  StyledContainer,
  StyledStatus,
  StyledCreateCatalog,
  StyledViewVitrine,
  StyledTd,
  StyledClickableTr,
  StyledSelectContainer,
  StyledRow,
  StyledFilters,
  StyledField,
  StyledLeftFilters,
  StyledGhostContainer,
  StyledButton
} from './styles';

const sizeOptions = [
  { value: 10, label: 'Mostrar 10 por vez' },
  { value: 20, label: 'Mostrar 20 por vez' },
  { value: 30, label: 'Mostrar 30 por vez' }
]

function SellerCatalog() {

  var paseSizeSelect = 10;

  const sellerId = smartSalesSessionStorage.getItem(storageKeys.SELLER);
  const store = smartSalesSessionStorage.getItem(storageKeys.STORE);
  const baseurl = window.location.origin;
  const [catalogs, setCatalogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [seller, setSeller] = useState([]);
  const [sellersOptions, setSellersOptions] = useState([]);
  const { register, handleSubmit, getValues } = useForm();
  const [pageSize, setPageSize] = useState({ value: 10, label: 'Mostrar 10 por vez' });
  const history = useHistory();

  const sortedItems = (key, direction) => {
    const sortableItems = [...catalogs];
    sortableItems.sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setCatalogs(sortableItems)
  };

  const setStates = (response) => {
    if (response?.data) {
      setCatalogs(response.data?.payload);

      if (response?.data?.pager) {
        setCurrentPage(response.data.pager?.currentPage);
        setTotalRows(response.data.pager?.totalRows);
        setTotalPages(response.data.pager?.totalPages);
      }

      setLoading(false)
    }
  }

  const handleSetPageSize = (value) => {
    setPageSize(value);
    paseSizeSelect = value.value;
    onFormSubmit(getValues());
  };

  const handleSetPage = (page) => {
    paseSizeSelect = pageSize.value;
    onFormSubmit(getValues(), page);
  };

  const onFormSubmit = async (filters, page = 1) => {
    const formData = {
      ...filters,
      status: status?.name === 'Todos' ? '0' : status?.name === 'Expirado' ? '2' : '1',
      sellerId: seller?.id,
      pageSize: paseSizeSelect,
      pageIndex: page
    };
    setLoading(true);
    const response = await getCatalogs(formData);
    setStates(response);
  }

  useEffect(() => {
    onFormSubmit(getValues());
  }, []);

  const CloseModalSendContact = (status) => {
    setOpenModal(!openModal)
    onFormSubmit(getValues())
  }

  const handleClick = (e, id) => {
    if (e.target.id != "ViewCatalogo")
      history.push(`/catalog/${id}`);
  }

  const openViewVitrine = async () => {
    const uri = `${baseurl}/${store?.portalUrl}/vitrine?sellerId=${sellerId?.id}`;
    window.open(uri, '_blank');
  }

  const openViewCatalog = async (id) => {
    const uri = `${baseurl}/${store?.portalUrl}/catalogo/${id}?sellerId=${sellerId?.id}`;
    window.open(uri, '_blank');
  }

  const statusOptions = [
    { name: 'Todos', id: 3 },
    { name: 'Ativo', id: 1 },
    { name: 'Expirado', id: 2 }
  ];

  const tagColor = colors => {
    switch (colors) {
      case 'Ativo':
        return '#00CD98';
      default:
        return '#E12121';
    }
  };

  const tagBackground = background => {
    switch (background) {
      case 'Ativo':
        return '#D9F2EC';
      default:
        return '#FFE3E3';
    }
  };

  const getSellersData = async () => {
    const response = await getSellers();
    setSellersOptions([{
      name: 'Todos',
      id: ''
    }, ...response?.data?.payload]);
  };

  useEffect(() => {
    getSellersData();
  }, []);

  const tableBody = () => {
    return catalogs.length > 0 ? (
      catalogs?.map((catalog, index) => (
        <StyledClickableTr key={`${catalog?.id}-${index}`} onClick={(e) => handleClick(e, catalog?.id)}>
          <StyledTd>
            {catalog?.name}
          </StyledTd>
          <td>{catalog.sellerName}</td>
          <td>{catalog.numOfPieces}</td>
          <td>{catalog.sentContacts}</td>
          <td>{moment(new Date(catalog?.beginsAt)).format('DD/MM/YYYY')}</td>
          <td>{catalog?.expiresAt ? moment(new Date(catalog?.expiresAt)).format('DD/MM/YYYY') : 'Em Aberto'}</td>
          <td>{catalog.numOfSales}</td>
          <td>
            {Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(catalog.revenues || 0)}
          </td>
          <td>
            <StyledStatus style={{ color: tagColor(catalog.status), backgroundColor: tagBackground(catalog.status), }}>
              {catalog.status}
            </StyledStatus>
          </td>
          <td>
            <StyledButton id="ViewCatalogo" onClick={() => openViewCatalog(catalog?.id)} alt="Visualizar Catálogo" src={catalogoShare} />
          </td>
        </StyledClickableTr>
      ))
    ) : (
      <StyledGhostContainer className='ghost-image'>
        <img src={ghostImage} alt="Ilustração de fantasma" />
        <p>Não foram encontrados catálogos para o filtro selecionado</p>
      </StyledGhostContainer>
    )
  }

  return (
    <>
      <StyledContainer>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <StyledFilters>
            <StyledLeftFilters>
              <StyledField zIndex="1" width="200px">
                <Label>Título do catálogo</Label>
                <Input type="text" maxLength='50' name="name" testId="name" placeholder="Pesquise..." register={register} />
              </StyledField>
              <StyledField zIndex="1000" width="250px">
                <Label>Data de Início</Label>
                <DatePicker name="beginsAt" register={register} />
              </StyledField>
              <StyledField zIndex="900" width="200px">
                <Label htmlFor="customerSeller">Status</Label>
                <Select
                  name="status"
                  inputId="status"
                  options={statusOptions}
                  value={status}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder="Selecione"
                  onChange={(value) => setStatus(value)}
                />
              </StyledField>
              <StyledField zIndex='800' width="200px">
                <Label htmlFor="customerSeller">Vendedor</Label>
                <Select
                  name="customer-seller"
                  inputId="customerSeller"
                  options={sellersOptions}
                  value={seller}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  placeholder="Selecione"
                  onChange={(value) => setSeller(value)}
                />
              </StyledField>
              <StyledField width="90px">
                <Button type="button" backgroundColor={theme.colors.black55} outline onClick={() => onFormSubmit(getValues())}>
                  FILTRAR
                </Button>
              </StyledField>
            </StyledLeftFilters>
            <StyledField width="200px">
              <StyledViewVitrine type="button" outline onClick={() => openViewVitrine()} >
                VITRINE
              </StyledViewVitrine>
            </StyledField>
            <StyledField width="200px">
              <StyledCreateCatalog type="button" onClick={() => setOpenModal(!openModal)}>
                NOVA AÇÃO
              </StyledCreateCatalog>
            </StyledField>
          </StyledFilters>
        </form>
        <DataGrid className="catalog-table" width="100%" height="unset" maxHeight="calc(100vh - 320px)" style={{ marginTop: '15px' }}>
          {catalogs.length > 0 && (
            <>
              <colgroup>
                <col style={{ width: '20%', minWidth: '100px' }} />
                <col style={{ width: '15%', minWidth: '100px' }} />
                <col style={{ width: '10%', minWidth: '100px' }} />
                <col style={{ width: '10%', minWidth: '100px' }} />
                <col style={{ width: '10%', minWidth: '100px' }} />
                <col style={{ width: '10%', minWidth: '100px' }} />
                <col style={{ width: '5%', minWidth: '100px' }} />
                <col style={{ width: '10%', minWidth: '100px' }} />
                <col style={{ width: '5%', minWidth: '100px' }} />
                <col style={{ width: '5%', minWidth: '100px' }} />
              </colgroup>
              <thead style={{ textAlign: 'left', position: 'sticky', backgroundColor: '#FFF', top: '0' }}>
                <tr>
                  <th>Título</th>
                  <th>Vendedor </th>
                  <th>Nº de Peças</th>
                  <th>Envios/Contatos </th>
                  <th>Data Início</th>
                  <th>Data Expiração</th>
                  <th>Vendas</th>
                  <th>Faturamento</th>
                  <th>Status</th>
                  <th>Catálogo</th>
                </tr>
              </thead>
            </>
          )}
          <tbody>
            {loading ? skeletonLoadTable() : tableBody()}
          </tbody>
        </DataGrid>
        <StyledRow align="center">
          <Text>
            {`Mostrando ${totalRows >= (currentPage * (pageSize.value || 10)) ? (currentPage * (pageSize.value || 10)) : totalRows} de ${totalRows} resultados`}
          </Text>
          <Pagination totalCount={totalPages} pageSize={pageSize.value} onPageChange={(page) => handleSetPage(page)} currentPage={currentPage} />
          <StyledSelectContainer>
            <Select
              name="pageSize"
              inputId="pageSize"
              options={sizeOptions}
              value={pageSize}
              menuPlacement='auto'
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              onChange={(value) => handleSetPageSize(value)}
            />
          </StyledSelectContainer>
        </StyledRow>
        {openModal && (<ModalSendContact onCloseCallback={() => CloseModalSendContact(!openModal)} isCreate />)}
      </StyledContainer>
    </>
  );
}

SellerCatalog.propTypes = {};

SellerCatalog.defaultProps = {};

export default SellerCatalog;
