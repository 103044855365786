import ModalSendContact from 'components/Modals/Avise-me';
import _ from 'lodash';
import Toast from 'components/Toast';
import React, { useState, useEffect, useMemo, memo } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import Default from 'assets/images/default-product.png';
import Button from '../../components/Forms/Button';
import Text from '../../components/Typografy/Text';
import api from '../../services/api';
import * as CatalogContext from '../../services/catalog-services';
import { Types as CartTypes } from '../../store/ducks/Checkout';
import { colorValues } from '../../styles/colors';
import theme from '../../styles/theme';
import Icon from '../../components/Icon';
import returIcon from 'assets/IconsSvg/return.svg';
import * as Styled from './styles';
import useWindowDimensions from 'utils/hooks/getScreenSize';

function Details({ match }) {
  const dispatch = useDispatch();
  const [produto, setProduto] = useState();
  const [filters, setFilters] = useState([]);
  const [variation, setVariation] = useState();
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const [image, setImage] = useState(null);
  const [sku, setSku] = useState();
  const [units, setUnits] = useState(0);
  const [toastInfo, setToastInfo] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [showModalState, setShowModalState] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const sellerId = new URLSearchParams(location.search).get('sellerId');
  const settings = useSelector(state => state.Setting.data);
  const windowDimensions = useWindowDimensions();

  useEffect(() => {
    setLoading(false);
    const mount = async () => {
      const response = await CatalogContext.getProductById(id);
      setSku(response?.data?.payload?.variations[0]?.stockKeepingUnit.replace(/-/, '').replace(/-/, ''),);
      setProduto(response?.data.payload);
      await requestStock();
      setImage(response?.data?.payload?.variations[0]?.images[0]?.urlImage);
      setLoading(true);
    };
    mount();
    document.documentElement.scrollTop = 0;
  }, []);

  useMemo(() => {
    const filteredVariations = produto?.variations.filter(v => {
      for (let i = 0; i < filters.length; i++) {
        const filter = filters[i];
        const condition = v.specifications.some(spec => {
          return spec.typeId === filter.typeId && spec.value === filter.value;
        });
        if (!condition) return false;
      }
      return true;
    });

    if (produto) {
      setSku(filteredVariations[0]?.stockKeepingUnit.replace(/-/, '').replace(/-/, ''),);
      setVariation(filteredVariations[0]);
    }
  }, [filters, produto]);

  useMemo(() => {
    if (variation) {
      setSizes(produto.specifications.filter(itemSpec => itemSpec.typeId === 1),);
      setColors(produto.specifications.filter(itemSpec => itemSpec.typeId === 2),);
      setImage(variation?.images[0]?.urlImage);
    }
  }, [variation]);

  const handleFilter = (typeId, value) => {
    setFilters(oldFilters => [
      ...oldFilters.filter(filter => filter.typeId !== typeId),
      { typeId, value },
    ]);
  };

  const handleAddToCart = itemCart => {
    dispatch({
      type: CartTypes.ADD_CART,
      item: {
        id: variation.id,
        ncm: produto.product.ncm,
        reference: produto.product.reference,
        stockKeepingUnit: variation.stockKeepingUnit,
        name: variation.name,
        price: variation.basePrice,
        image: variation.images[0]?.urlImage,
        size: variation.specifications
          .filter(itemSpec => itemSpec.typeId === 1)
          .map(itemSpec => itemSpec.description),
        color: variation.specifications
          .filter(itemSpec => itemSpec.typeId === 2)
          .map(itemSpec => itemSpec.value),
      },
    });
    setToastInfo({ isError: false, message: `Produto adicionado ao carrinho!` });
  };

  function handleBlack() {
    history.push(`/${match.params.store}/vitrine?sellerId=${sellerId}`);
  }

  const ordened = _.orderBy(sizes, ['value'], ['asc']);

  async function requestStock() {
    setLoadingButton(false);
    if (sku) {
      const response = await api.get(`/product/${sku}/stocks`);
      if (!response.data.payload) {
        setLoadingButton(true);
        setUnits(0);
      } else {
        setUnits(response.data.payload.units);
        setLoadingButton(true);
      }
    }
  }

  useMemo(() => {
    if (variation) {
      requestStock();
    }
  }, [variation]);

  function resetShowModalStateHandler() {
    setShowModalState(false);
  }

  function onShowModalVisible() {
    setShowModalState(!showModalState);
  }

  const showModal = () => (
    <ModalSendContact onCloseCallback={resetShowModalStateHandler} products={variation} />
  );

  function handleBackButtonClick() {
    history.goBack()
  }

  return (
    <>
      {loading ? (
        <Styled.Section>
          {variation && (
            <Styled.PruductDetails>
              <Styled.Images>
                <Styled.ListImages>
                  {variation.images.map((itemSku, indexSku) => (<img key={indexSku} src={itemSku?.urlImage} alt="asd" onClick={() => setImage(itemSku?.urlImage)} />))}
                </Styled.ListImages>
                <Styled.ImageSelected>
                  {windowDimensions.width >= 570 ?
                    (<Styled.Img src={image || Default} className="imageSelected" alt="asd" />) :
                    (<Styled.Img src={image || Default} style={{ maxHeight: '260px' }} className="imageSelected" alt="asd" />)
                  }
                </Styled.ImageSelected>
              </Styled.Images>
              <Styled.ProductSpec>
                <Text fontSize={theme.fontSize._14px} lineHeight="10px" color="black" className="link" cursor="pointer" onClick={handleBackButtonClick}>
                  <Icon icon={returIcon} width="20px" height="10px" />
                  Voltar / {variation.name}
                </Text>
                <br />
                <br />
                <Text marginTop="15px" fontSize={theme.fontSize._14px} fontWeight="300" color="black" >
                  Cód: {variation.stockKeepingUnit} {produto?.product.reference}
                </Text>
                <br />
                <br />
                <Text fontSize={theme.fontSize._50px} fontWeight="200" color="black" style={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: '22px', lineHeight: '16px', }} >
                  {produto.product.name}
                </Text> <br />
                <hr style={{ marginTop: '20px', marginBottom: '20px', }} />
                <span style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: '14px', lineHeight: '14px', color: '#000000', }} >
                  Cor
                </span>
                <Styled.ProductColor>
                  {colors.map((itemSpec, indexSpec) => (
                    <Styled.Colors
                      type="button"
                      onClick={() =>
                        handleFilter(itemSpec.typeId, itemSpec.value)}
                      active={variation?.specifications?.some(spec => spec.typeId === 2 && spec.value === itemSpec.value,)}
                      key={indexSpec}
                      style={{
                        backgroundColor: colorValues.find(x => {
                          return (
                            x.name.toLowerCase() ===
                            itemSpec.value
                              .replace('/', '-')
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                              .toLowerCase()
                          );
                        })?.value,
                      }}
                    />
                  ))}
                </Styled.ProductColor>
                <br />
                <span style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: '14px', lineHeight: '14px', color: '#000000', }}>
                  Tamanho
                </span>
                <Styled.ListSize style={{ marginBottom: '30px', }}>
                  {ordened.map((itemSpec, indexSpec) => (
                    <Styled.ProductSize
                      active={variation.specifications.some(
                        spec =>
                          spec.typeId === 1 && spec.value === itemSpec.value,
                      )}
                      type="button"
                      onClick={() =>
                        handleFilter(itemSpec.typeId, itemSpec.value)}
                      key={indexSpec}
                    >
                      {itemSpec.description}
                    </Styled.ProductSize>
                  ))}
                </Styled.ListSize>
                <hr style={{ marginTop: '20px', marginBottom: '20px', }} />
                <span style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: '14px', lineHeight: '14px', color: '#000000', }}>
                  Nome Complemento
                </span>
                <br />
                <Text color="#000000" style={{ fontSyle: 'normal', fontWight: '300', fontSize: '13px', lineHeight: '20px', }}>
                  {variation.name}
                </Text>

                <hr
                  style={{ marginTop: '20px', marginBottom: '20px', }} />
                {variation.listPrice !== variation.basePrice ? (
                  <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '10px', alignItems: 'center', }}>
                    <s style={{ fontSize: '20px', fontWeight: '300', color: '#828282', }}>
                      {Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(variation.listPrice)}
                    </s>
                    <Text fontSize={theme.fontSize._22px}>{' | '}</Text>
                    <Text fontSize={theme.fontSize._22px} color="#FF4040">
                      {Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(variation.basePrice)}
                    </Text>
                  </div>
                ) : (
                  <Text fontSize={theme.fontSize._22px} color="#000">
                    {Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(variation.basePrice)}
                  </Text>
                )}
                <br />
                <Text fontSize={theme.fontSize._15px} color="#000">
                  {variation &&
                    `Ou em até 6x de
                    ${Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(variation.basePrice / 6)} sem juros`}
                </Text>
                <div style={{ width: '100%' }}>
                  <br />
                  <br />
                  {loadingButton ? (
                    units > 0 ? (
                      <Button onClick={() => { handleAddToCart(variation); }} height="49px" backgroundColor={settings.buttonColor} >
                        ADICIONAR AO CARRINHO
                      </Button>
                    ) : (
                      <>
                        {showModalState && showModal()}
                        <Button height="49px" onClick={() => onShowModalVisible()} backgroundColor={theme.colors.gray}  >
                          AVISE-ME QUANDO CHEGAR
                        </Button>
                      </>
                    )
                  ) : (
                    <div style={{ width: '100%', paddingLeft: '80px', }}>
                      <Loader type="Oval" color={theme.colors.black55} height={40} width={40} timeout={loadingButton} />
                    </div>
                  )}
                  <br />
                  <br />
                  <u>
                    <Button height="49px" onClick={handleBlack} style={{ backgroundColor: settings.secondaryButtonColor, color: 'white' }}  >
                      VOLTAR PARA VITRINE
                    </Button>
                  </u>
                </div>
                <hr style={{ marginTop: '20px', marginBottom: '20px', }} />
                <span style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: '14px', lineHeight: '14px', color: '#000000', }}>
                  Descrição
                </span><br /><br />
                <div dangerouslySetInnerHTML={{ __html: produto.product.description }} />
                <br />
              </Styled.ProductSpec>
            </Styled.PruductDetails>
          )}
          {toastInfo?.message && (
            <Toast type={toastInfo.isError ? 'error' : 'success'} message={toastInfo.message} position="bottom-center" onClose={() => setToastInfo(null)} />
          )}
        </Styled.Section>
      ) :
        (
          <div style={{ height: '150px', paddingTop: '150px', width: '100%', textAlign: 'center', }}>
            <Loader type="Oval" color={theme.colors.black55} height={80} width={80} timeout={loading} />
          </div>
        )}
    </>
  );
}

export default memo(Details);
