import styled from 'styled-components';

import theme from '../../../styles/theme';

const StyledTextArea = styled.textarea`
  width: ${props => props.width};
  height: ${props => props.height};
  resize: none;
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px;
  margin: ${(props) => props.margin};
  
  &:focus {
    box-shadow: 0 0 0 1px ${theme.colors.principal};
  }
`;

export default StyledTextArea;