import axios from 'axios';
import Cookies from 'js-cookie';
import history from 'services/history-services';
import smartSalesSessionStorage, { storageKeys, } from 'storage/smart-sales-session-storage';

const getEnvironmentUrl = () => {
  const { host } = window.location;

  switch (host) {
    case 'qa-smartsales.linx.com.br':
      return 'https://qa-linxsmartsales.azurewebsites.net/api/v1/';
    case 'smartsales.linx.com.br':
      return 'https://prd-linxsmartsales.azurewebsites.net/api/v1/';
    default:
      return 'http://localhost:46648/api/v1/';
  }
};

export const getContentUrl = (portalStore, type) => {
  const { host } = window.location;

  switch (host) {
    case 'qa-smartsales.linx.com.br':
      return `https://smartsalesstage.blob.core.windows.net/store-content/tenant_${portalStore}/${type}.jpg`;
    case 'smartsales.linx.com.br':
      return `https://smartsalesproduction.blob.core.windows.net/store-content/tenant_${portalStore}/${type}.jpg`;
    default:
      return `https://smartsalesstage.blob.core.windows.net/store-content/tenant_${portalStore}/${type}.jpg`;
  }
}

const axiosApi = axios.create({
  baseURL: getEnvironmentUrl(),
  withCredentials: true,
});

function getStoreOnStorage() {
  return smartSalesSessionStorage.getItem(storageKeys.STORE);
}

const store = getStoreOnStorage();
if (store !== undefined && store !== null && store !== 'null') {
  axiosApi.defaults.headers.store = store?.portalUrl;
}

const params = new URLSearchParams(window.location.search);
const hash = params.get('token');
const cookieHash = Cookies.get('token');

if (hash) {
  Cookies.set('token', hash);
  axiosApi.defaults.headers.token = hash;
} else if (cookieHash) {
  axiosApi.defaults.headers.token = cookieHash;
}

axiosApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      history.push('/restrito');
    }
    return Promise.reject(error);
  },
);

export default axiosApi;
