import PropTypes from 'prop-types';
import React from 'react';
import theme from 'styles/theme';

import { StyledText } from './styles';

const FieldError = ({ children }) => {
  return (
    <>
      <StyledText>{children}</StyledText>
    </>
  );
};

FieldError.propTypes = {
  children: PropTypes.node.isRequired,
};

FieldError.defaultProps = {};

export default FieldError;
