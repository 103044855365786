import SuccessIcon from 'assets/icons/check-checkout.svg';
import ErrorIcon from 'assets/icons/error-checkout.svg';
import PropTypes from 'prop-types';
import React from 'react';
import { StyledFlexContainer } from 'styles/global';

import {
  StyledMainContent,
  StyledOperationIcon,
  StyledOrderReference,
  StyledErroButton,
  StyledSuccessButton,
  ModalContainer
} from './styles';

function Operation({ type, reference, handleShowError, handleShowSuccess, buttonColor, handleMsg }) {
  return (
    type === 'success' ? (
      <ModalContainer>
        <StyledMainContent className='success' color={buttonColor}>
          <StyledOperationIcon color={buttonColor} src={SuccessIcon} alt="icone de sucesso" />
          <StyledFlexContainer direction="column">
            <p>
              <span>Parabéns! Compra realizada com sucesso!<br /></span>
              Informações detalhadas da compra foram enviadas para o seu e-mail.
            </p>
            {/* {reference && (<StyledOrderReference>{`Número do pedido #${OrderId}`}</StyledOrderReference>)} */}
            {reference && (<StyledOrderReference>{`Código do pedido #${reference}`}</StyledOrderReference>)}
          </StyledFlexContainer>
        </StyledMainContent>
        <StyledMainContent>
          <StyledSuccessButton color={buttonColor} onClick={() => handleShowSuccess()}>
            CONTINUAR COMPRANDO
          </StyledSuccessButton>
        </StyledMainContent>
      </ModalContainer>
    ) :
      (
        <ModalContainer>
          <StyledMainContent className='error'>
            <StyledOperationIcon src={ErrorIcon} alt="icone de falha" />
            <StyledFlexContainer direction="column">
              <p>
                <span>Falha no processamento da sua compra:<br /></span>
                {handleMsg != '' ? `${handleMsg}` : ``} <br /><br />
                Verifique os dados do pedido e tente novamente, se a falha persistir entre em contato com o vendedor!
              </p>
            </StyledFlexContainer>
          </StyledMainContent>
          <StyledMainContent>
            <StyledErroButton onClick={() => handleShowError()}>
              ALTERAR PEDIDO
            </StyledErroButton>
          </StyledMainContent>
        </ModalContainer>
      )
  )
};

Operation.propTypes = {
  type: PropTypes.string,
  reference: PropTypes.string,
  handleShowError: PropTypes.func,
  handleShowSuccess: PropTypes.func,
  buttonColor: PropTypes.string,
  handleMsg: PropTypes.string
};

Operation.defaultProps = {
  type: 'fail',
  reference: '',
  handleShowCase: () => { },
  buttonColor: '#1AD391',
  handleMsg: ''
};

export default Operation;
