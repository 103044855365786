import styled from 'styled-components';

import theme from '../../styles/theme';

export const StyledContainer = styled.div`
  max-width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  a {
    color: unset;
  }
`;

export const StyledTHead = styled.thead`
  text-align: left;
  position: sticky;
  background-color: #FFF;
  top: 0;
  z-index: 2;
  margin: 0 15px 0 20px;
  
  p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  tr {
    display: flex;
    flex-direction: row;
    width: calc(100vw - 100px);
    min-width: 800px;
  }
`;

export const StyledTh = styled.th`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  min-width: 100px;
`;

export const StyledClickableTr = styled.tr`
  cursor: pointer;
  :hover {
    background-color: #F1F1F1;
  }
`;

export const StyledFooter = styled.div`
  width: 100%;
  margin-top: 50px;
  margin-bottom: 0;
`;

export const StyledRow = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center !important;
  justify-content: space-between !important;
  position: relative;

  p {
    text-align: center;
  }

  @media screen and (max-width: 930px) {
    flex-direction: column;
  }
`;

export const StyledSelectContainer = styled.div`
  width: 220px;
  font-size: 12px;

  @media screen and (max-width: 930px) {
    width: 400px;
  }

  @media screen and (max-width: 775px) {
    width: 100%;
  }
`;

export const StyledFilters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 12px;

  @media screen and (max-width: 1310px) {
    flex-wrap: wrap;
  }
`;

export const StyledLeftFilters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;

  @media screen and (max-width: 775px) {
    flex-wrap: wrap;
  }
`;

export const StyledField = styled.div`
  width: ${(props) => props.width};
  z-index: ${(props) => props.zIndex};
  margin-right: 20px;
  margin-bottom: 10px;

  @media screen and (max-width: 1310px) {
    width: 100%;
  }

  @media screen and (max-width: 775px) {
    margin-right: 0;
    margin-top: ${(props) => props.marginTop};
  }
`;

export const StyledJson = styled.pre`
  padding: 15px;
  /* white-space:pre; */
  word-break: break-all;
`;

export const StyledGhostContainer = styled.tr`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 50px auto;

  p {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: flex-end;
      text-align: center;
      color: #828282;
      width: 360px;
      margin: 15px;
    }
`;
