import styled from 'styled-components';

export const StyledMainContent = styled.div`
  border: 1px solid ${(props) => props.selected ?  props.backgroundColor : '#D6D6D6'};
  border-radius: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  font-size: 12px;
  margin-top: 10px;
`;

export const StyledInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 180px;
`;

export const StyledTitle = styled.h3`
  font-weight: 600;
  font-size: 12px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledDescr = styled.p`
  color: #575757;
  font-size: 10px;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledButton = styled.button`
  border: 1px solid ${props => props.backgroundColor};
  background-color: white;
  font-size: 9px;
  color: ${props => props.backgroundColor || '#37D0B5'};
  height: 30px;
  padding: 8px;
  border-radius: 3px;
  font-weight: 700;
  cursor: pointer;
`;