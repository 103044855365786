import { StyledTableContainer } from 'components/Datagrid/styles';
import PropTypes from 'prop-types';
import React from 'react';

import { StyledTable } from './styles';

function DataGrid({ children, width, height, minHeight, ...rest }) {
  return (
    <StyledTableContainer width={width} height={height} minHeight={minHeight} {...rest}>
      <StyledTable>{children}</StyledTable>
    </StyledTableContainer>
  );
}

DataGrid.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  minHeight: PropTypes.string,
};

DataGrid.defaultProps = {
  width: '100%',
  height: '462px',
  minHeight: '400px'
};

export default DataGrid;
