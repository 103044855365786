import styled from 'styled-components';

export const StyledContainer = styled.section`
  display: flex;
  flex-direction: column;

  width: 100%;
  background-color: ${props => props.backgroundColor};
`;

export const StyledBody = styled.div`
  transition: max-height 1s ease-in-out;

  width: ${props => props.childrenWidth};
  margin: 10px 0px;

  height: ${props => props.childrenHeight};
  max-height: ${props => props.childrenHeight};

  display: ${props => (props.visible ? 'block' : 'none')};
`;

export const StyledCollapseButton = styled.div`
  width: ${props => props.buttonWidth};
  height: ${props => props.buttonHeight};

  text-align: center;
  cursor: pointer;
  align-self: center;

  padding-top: 6px;
  margin-top: 5px;
  border-radius: 20px;

  border: 1px solid #f2f2f2;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
`;

export const StyledTopic = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: ${props => props.theme.fontSize._8px}rem;
  line-height: 10px;
  display: ${props => (props.visible ? 'block' : 'none')};
  color: #828282;
`;

export const StyledIcon = styled.img`
  position: relative;
  padding: 0px 5px;
`;
