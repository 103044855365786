import React from 'react';

import { StyledButton } from '../../pages/Catalog/styles';

const Button = ({
  height,
  width,
  text,
  display,
  margin,
  onClick,
  magLeft,
  icon,
  text2,
  cursor,
  backgroundColor,
  ...props
  // ...rest,
}) => {
  return (
    <StyledButton
      {...props}
      cursor={cursor}
      margin={margin}
      height={height}
      width={width}
      onClick={onClick}
      backgroundColor={backgroundColor}
    >
      {icon}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div>{text}</div>
        <div> {text2}</div>
      </div>
    </StyledButton>
  );
};

export default Button;
