import styled from 'styled-components';

export const StyledMainContainer = styled.div`
  height: max-content;
  max-width: 1350px;
  margin: auto;
  padding: 20px;
  font-family: Open Sans;
  font-style: normal;
  display: grid;
  box-sizing: border-box;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  grid-gap: 20px;

  @media (max-width: 1450px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 880px) {
    grid-template-columns: repeat(1, 1fr);
  }

  img {
    object-fit: cover;
    width: 100px;
  }
`;

export const StyledGenericBoard = styled.div`
  border: 2px solid #0000000F;
  padding: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 3px;
  width: max-content;
`;

export const StyledBoardFull = styled(StyledGenericBoard)`
  width: 100%;
  height: 180px;
  display: flex;
  justify-content: space-between;
`;

export const StyledValue = styled.p`
  font-weight: 800;
  font-size: 23px;
  color: #363636;

  color: ${(props) => props.color};
`;

export const StyledLabel = styled.h1`
  color: ${(props) => props.color ?? '#989898'};
  font-size: 13px;
  font-weight: 500;

  span {
    font-weight: 800;
  }
`;

export const StyledLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const StyledChartLabel = styled(StyledLabel)`
  margin-top: 12px;
  margin-bottom: 10px;
  font-size: 9px;
`;

export const StyledFilterDate = styled.div`
  border-top: 2px solid #0000000F;
  height: 25px;
  width: 100%;
  margin-top: 10px;
`;

export const StyledNoChartContainer = styled.div`
  width: 100%;
  max-width: 365px;
  border-radius: 3px;

  &.revenues{
    background-color: #672A93;
  }
`;

export const StyledFilters = styled.div`
  width: 100%;
  max-width: 1350px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 12px;
  margin: auto;
  padding: 20px 20px 0 20px;
  position: sticky;
  top: 70px;
  background-color: white;
  z-index: 2;
/* 
  @media screen and (max-width: 1310px) {
    flex-wrap: wrap;
  } */
`;

export const StyledLeftFilters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;

  /* @media screen and (max-width: 775px) {
    flex-wrap: wrap;
  } */
`;

export const StyledField = styled.div`
  width: ${(props) => props.width};
  z-index: ${(props) => props.zIndex};
  margin-right: 20px;
  margin-bottom: 10px;

  /* @media screen and (max-width: 1310px) {
    width: 100%;
  }

  @media screen and (max-width: 775px) {
    margin-right: 0;
    margin-top: ${(props) => props.marginTop};
  } */
`;