import { Col } from 'react-grid-system';
import styled from 'styled-components';
import { StyledFlexContainer } from 'styles/global';

export const StyldContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledTitle = styled.div`
  width: max-content;
  padding: 15px 20px 10px 20px;
  display: flex;
  flex-direction: column;
`;

export const StyledDetails = styled.div`
  width: 100%;
  height: 530px;
  margin-top: 5px;
  overflow: scroll;
`;

export const StyledDivCards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-right: 35px;
`;

export const StyledCards = styled(Col)`
  width: 100%;
  height: 56px;
  border: 1px solid #e4dfdf;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  margin: 0 0 15px 20px !important;
  flex-direction: ${(props) => props.direction};
  padding: 5px 10px !important;
`;

export const StyledButton = styled.button`
  background-color: #fff;
  cursor: pointer;
  border: none;
  margin-top: 17px;
  margin-left: 20px;
`;

export const StyledTable = styled.table`
  width: 95%;
  margin-top: 10px;
  margin-left: 20px;
  border-bottom: 1px dashed #000;
`;

export const StyledThead = styled.thead`
  padding: 10px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 17px;
  text-align: left;
  color: #b9b9b9;
  background-color: #fbfbfb;
`;

export const StyledTableBody = styled.tbody`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #929292;
  tr {
    padding: 10px;

    td img {
      width: 80px;
      height: 80px;
      object-fit: cover;
    }
  }

  .valores {
    height: 30px;
  }

  .total {
    height: 30px;
    color: #000;
    background-color: #fbfbfb;
  }

  .pegamento {
    top: 0;
  }

  .total {
    height: 30px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 17px;
    color: #6a6a6a;
    background-color: #fbfbfb;
  }
`;

export const StyledDetailsCli = styled.div`
  padding: 0 16px 25px 20px;
  display: flex;
  flex-direction: row;
`;

export const StyledPrintButton = styled.button`
  background-color: transparent;
  border: solid 1px #828282;
  border-radius: 4px;
  font-family: Open Sans;
  font-weight: 600;
  font-size: 10px;
  padding: 10px;
  width: 180px;
  color: #828282;
  cursor: pointer;
`;

export const StyledCancelButton = styled(StyledPrintButton)`
  background-color: #C94747;
  border: solid 1px #512A2A;
  width: 140px;
  color: #FFF;
  cursor: pointer;

  :disabled {
    opacity: 0.7;
    cursor: unset;
  }
`;

export const StyledFooter = styled(StyledFlexContainer)`
  width: 100%;
  padding: 0 20px 20px 20px;
  justify-content: space-between;
  flex-direction: row;
`;

export const StyledConfirmButton = styled.button`
  width: 240px;
  background-color: #3D7981;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  margin-top: 20px;

  :hover {
    opacity: 0.8;
  }
`;

export const StyledCancelModal = styled(StyledFlexContainer)`
  width: 100%;
  height: 100%;
  flex-direction: column;
  color: #828282;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  text-align: center;

  img {
    height: 50px;
    width: 44px;
    margin-bottom: 15px;
  }
`;

export const StyledAbortModal = styled.button`
  background-color: transparent;
  border: none;
  font-size: 12px;
  color: #828282;
  font-weight: 600;
  width: 100px;
  padding: 12px;
  cursor: pointer;
`;