import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { Types as CartTypes } from '../../store/ducks/Checkout';
import { StyledCounter, StyledInput, StyledCounterButton } from './styles';

function Counter({ productId, sumir }) {
  const dispatch = useDispatch();

  const amount = useSelector(state =>
    state.Checkout.reduce((sumAmount, item) => {
      sumAmount[item.id] = item.amount;
      return sumAmount;
    }, {}),
  );

  function handleIncrement() {
    dispatch({
      type: CartTypes.UPDATE_AMOUNT,
      id: productId,
      amount: amount[productId] + 1,
    });
  }
  function handleDecrement() {
    dispatch({
      type: CartTypes.UPDATE_AMOUNT,
      id: productId,
      amount: amount[productId] - 1,
    });
  }

  return (
    <StyledCounter sumir={sumir}>
      <StyledCounterButton onClick={handleDecrement}>
        <FaMinus size={12} />
      </StyledCounterButton>
      <StyledInput value={amount[productId] || 0} />
      <StyledCounterButton onClick={handleIncrement}>
        <FaPlus size={12} />
      </StyledCounterButton>
    </StyledCounter>
  );
}

export default Counter;
